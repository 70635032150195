export const validateNewResources = (newResources, projectResources) => {
  let isValid = true;

  let addedResources = projectResources.filter((resource) =>
    resource.id.includes("new")
  );
  if (addedResources.length !== 0 && newResources.length == 0) {
    isValid = false;
  }

  newResources.forEach((resource) => {
    if (resource.attributes.name == "") {
      isValid = false;
    }
  });

  return isValid;
};
