import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import "./NewProjectDetails.css";
import { useNavigate } from "react-router";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import {
  faPencil,
  faAlignCenter,
  faScrewdriverWrench,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { faMemo, faMoneyCheckPen } from "@fortawesome/pro-solid-svg-icons";
import IconProgressBar from "@components/IconProgressBar/IconProgressBar";
import AutoCompleteText from "@components/Forms/AutoCompleteText/AutoCompleteText";
import FormTextField from "@components/Forms/FormTextField";
import AddressForm from "@components/Forms/AddressForm";
import DropDownField from "@components/Forms/DropDownField";
import API from "@API";
import {
  getUpdatedProjectVariables,
  getUpdatedCrm,
  getUpdatedPaymentTerm,
  getUpdatedPresalesEngineer,
  getUpdatedRateTable,
  upsertClientId,
  upsertProjectLocation,
  upsertSalesExecutiveId,
  validateLocation,
  validateVariables,
  handleCharacterAlert,
  handleLocationAlert,
  handleUserExistsAlert,
  getUpdatedBusinessUnit,
} from "../../projectUtils/projectHelperFunctions";
import ProjectVariables from "../../../ProjectVariables/ProjectVariables";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import {
  formatUnprocessibleResponse,
  formatDate,
} from "@utils/helperFunctions";
import Summary from "../Summary/Summary";
import Services from "../Services/Services";
import Pricing from "../Pricing/Pricing";
import ReviewAndGenerate from "../ReviewAndGenerate/ReviewAndGenerate";
import ServiceDescriptions from "../ServiceDescriptions/ServiceDescriptions";
import NewProject from "./NewProject";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
const crmAlert = require("../../../../images/error-message-grey.png");
import Select from "react-select";
import useFlags from "@common/hooks/useFlags";
import FormDateField from "@components/Forms/FormDateField";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import DropdownApiJs from "@components/FormsV2/DropdownApiJs/DropdownApiJs";

function NewProjectDetails({
  account_id,
  account_slug,
  authorizationCode,
  source = "createProjectMspa",
  submitRef,
  handleApplySurveys,
  setCurrentSurveyPage,
  save_to_cloud_storage_flag,
  salesExecutiveCreatePermission,
}) {
  //Env / Navigate
  let navigate = useNavigate();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const today = new Date();

  // Alerts, Modals
  const [showModal, setShowModal] = useState(false);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  //Progress Bar
  const [currentPage, setCurrentPage] = useState(0);
  const [pageReturn, setPageReturn] = useState(false);
  const [projectServices, setProjectServices] = useState([]);
  const [projectServiceData, setProjectServiceData] = useState({});
  const [savedTerms, setSavedTerms] = useState(false);

  // Form States
  const [projectName, setProjectName] = useState(null);

  // Sales
  const [salesExecs, setSalesExecs] = useState([]);
  const [salesExecutives, setSalesExecutives] = useState([]);
  const [currentSalesExec, setCurrentSalesExec] = useState({
    value: "",
    label: "",
  });
  const [newSalesExecId, setNewSalesExecId] = useState(null);
  const [salesExecInvalid, setSalesExecInvalid] = useState(false);

  // Pre-sales
  const [presales, setPresales] = useState([]);
  const [currentPresalesEngineer, setCurrentPresalesEngineer] = useState("");
  const [newPresalesEngineerId, setNewPresalesEngineerId] = useState(null);

  // Payment Term
  const [paymentTerm, setPaymentTerm] = useState("");
  const [newPaymentTermId, setNewPaymentTermId] = useState(null);
  const [paymentTerms, setPaymentTerms] = useState([]);

  // Client
  var chosenClient = "";
  const [clients, setClients] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [clientName, setClientName] = useState("");
  const [newClientId, setNewClientId] = useState(null);
  const [userTypedClient, setUserTypedClient] = useState(false);
  const [msaDate, setMsaDate] = useState(null);

  // Rate Tables
  const [rateTables, setRateTables] = useState([]);
  const [currentRateTable, setCurrentRateTable] = useState("");
  const [newRateTableId, setNewRateTableId] = useState(null);

  // Business Unit States
  const [businessUnits, setBusinessUnits] = useState([]);
  const [currentBusinessUnit, setCurrentBusinessUnit] = useState("");
  const [newBusinessUnitId, setNewBusinessUnitId] = useState(null);

  // Crm States
  const {
    configCrmOpportunityRequired: crmFlag,
    configSalesExecutiveRequired,
    configPresalesEngineerRequired,
    configBusinessUnitRequired,
    configMsaDateHidden,
    configServiceLocationRequired,
  } = useFlags();
  const [isCrm, setIsCrm] = useState(false);
  const [crmState, setCrmState] = useState([]);
  const [crmId, setCrmId] = useState("");
  const [currentCrmOpporutnity, setCurrentCrmOpportunity] = useState("");
  const [newCrmId, setNewCrmId] = useState(null);
  const [crmOppInvalid, setCrmOppInvalid] = useState(false);

  // Misc
  const [fieldLabels, setFieldLabels] = useState({
    presales_engineer: "Pre-Sales Engineer",
    sales_executive: "Sales Executive",
    business_unit: "Business Unit",
    location: "Service Location",
    client: "Client",
  });
  const [isPsa, setIsPsa] = useState(false);
  const [newProjectId, setNewProjectId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [projectIsSaving, setProjectIsSaving] = useState(false);
  const [patchProjectUrl, setPatchProjectUrl] = useState("");
  const [currentUser, setCurrentUser] = useState(null);

  // Address/Service Location States
  const [projectLocations, setProjectLocations] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [addressChanged, setAddressChanged] = useState(false);
  const [customerSite, setCustomerSite] = useState("");
  const [remoteSite, setRemoteSite] = useState(false);
  const [address, setAddress] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [countryId, setCountryId] = useState("");

  // Project Variables
  const [requiredProjectVariables, setRequiredProjectVariables] = useState([]);
  const [unrequiredProjectVariables, setUnrequiredProjectVariables] = useState(
    []
  );
  const [requiredLocationVariables, setRequiredLocationVariables] = useState(
    []
  );
  const [unrequiredLocationVariables, setUnrequiredLocationVariables] =
    useState([]);

  // Alerts, errors, validation
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [submitFail, setSubmitFail] = useState(false);
  const [showLocationAlert, setShowLocationAlert] = useState(false);
  const [locationErrors, setLocationErrors] = useState([]);

  const [showMinCharAlert, setShowMinCharAlert] = useState(false);
  const [showMaxCharAlert, setShowMaxCharAlert] = useState(false);
  const [userExistsAlertIsOpen, setUserExistsAlertIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(false);

  const [guidedFlowEnabled, setGuidedFlowEnabled] = useState(false);
  const [resourceRatesEnabled, setResourceRatesEnabled] = useState(false);

  useEffect(() => {
    // Add grey background to body
    if (source !== "surveys") {
      document.body.setAttribute("style", "background: #eee");
    }

    // Get account and project details for project creation
    if (authorizationCode !== "") {
      API.Get(`${apiHost}/v1/accounts/${account_slug}`, authorizationCode).then(
        (response) => {
          const data = response.data.data;
          if (data.attributes.settings["guided_project_creation"]) {
            setGuidedFlowEnabled(
              data.attributes.settings["guided_project_creation"]
            );
          }
          if (data.attributes.settings["project_resource_rates"]) {
            setResourceRatesEnabled(
              data.attributes.settings["project_resource_rates"]
            );
          }
          setIsCrm(data.attributes["crm?"]);
          setIsPsa(data.attributes["psa?"]);

          // Field Labels
          setFieldLabels(data.attributes["field-labels"]);

          // Client list for typeahead field
          API.Get(
            data.links.clients + "?include=rate-table&page[size]=100",
            authorizationCode
          ).then((response) => {
            const data = response.data.data;
            const clients = data.map((client) => ({
              id: client.id,
              client: client.attributes.name,
              msa_date: client.attributes["msa-date"],
              relationships: client.relationships,
            }));
            if (clients.length !== 0) {
              setClients(clients);
              const clientNames = data.map((client) => client.attributes.name);
              setClientList(clientNames);
            }
          });

          // Sales executives
          API.Get(data.links["sales-executives"], authorizationCode).then(
            (response) => {
              const salesExecList = response.data.data;
              if (salesExecList.length !== 0) {
                const salesExecs = salesExecList.map((salesExec) => ({
                  id: salesExec.id,
                  salesExec: salesExec.attributes.name,
                }));
                setSalesExecs(salesExecs);

                const salesExecNames = salesExecList.map(
                  (obj) => obj.attributes.name
                );
                setSalesExecutives(salesExecNames);
              }
            }
          );

          // Payment Terms
          API.Get(data.links["payment-terms"], authorizationCode).then(
            (response) => {
              let paymentTerms = response.data.data;
              setPaymentTerms(paymentTerms);
              for (let i = 0; i < paymentTerms.length; i++) {
                if (paymentTerms[i].attributes.default === true) {
                  setPaymentTerm(paymentTerms[i].attributes.name);
                  setNewPaymentTermId(paymentTerms[i].id);
                }
              }
            }
          );

          // Rate Tables
          API.Get(
            data.links["rate-tables"] + "?page[size]=500",
            authorizationCode
          ).then((response) => {
            const rateTableData = response.data.data;
            setRateTables(
              rateTableData.filter(
                (rateTable) => rateTable.attributes.active === true
              )
            );

            // Current User
            API.Get(
              `${apiHost}/${account_slug}/v1/users/current?include=rate-table`,
              authorizationCode
            ).then((response) => {
              const data = response.data;
              setCurrentUser(data);

              const userRateTable =
                data.attributes["preferred-rate-table"].name;
              setCurrentRateTable(userRateTable);
              let [rateTable] = rateTableData.filter(
                (rateTable) => rateTable.attributes.name === userRateTable
              );
              setNewRateTableId(rateTable.id);
            });
          });

          // Presales Engineers
          API.Get(
            data.links["presales-engineers"] + "?page[size]=500",
            authorizationCode
          ).then((response) => setPresales(response.data.data));

          // Business Units
          API.Get(data.links["business-units"], authorizationCode).then(
            (response) => {
              let businessUnits = response.data.data;
              if (businessUnits !== null) setBusinessUnits(businessUnits);
              if (businessUnits.length === 1) {
                setCurrentBusinessUnit(businessUnits[0].attributes.name);
                setNewBusinessUnitId(businessUnits[0].id);
              }
            }
          );

          // Project Variables
          API.Get(
            data.links["project-variables"] +
              "?filter[variable-context]=project,service_location",
            authorizationCode
          ).then((response) => {
            let variables = response.data.data.map((pv) => {
              let attr = pv.attributes;
              let options = attr["select-options"];
              delete attr["select-options"];
              return { ...attr, select_options: options };
            });
            if (variables.length !== 0) {
              let reqProjectVariables = variables.filter((variable) => {
                if (variable.required === true) {
                  return variable.required;
                }
              });
              reqProjectVariables = reqProjectVariables.map((variable) => {
                if (variable["variable-type"] === "date") {
                  return { ...variable, value: formatDate(today) };
                } else {
                  return { ...variable, value: "" };
                }
              });
              setRequiredProjectVariables(
                reqProjectVariables.filter(
                  (variable) => variable["variable-context"] == "project"
                )
              );
              setRequiredLocationVariables(
                reqProjectVariables.filter(
                  (variable) =>
                    variable["variable-context"] == "service_location"
                )
              );
              let unreqProjectVariables = variables.filter((variable) => {
                if (variable.required === false) {
                  return variable;
                }
              });
              unreqProjectVariables = unreqProjectVariables.map((variable) => {
                return { ...variable, value: "" };
              });
              setUnrequiredProjectVariables(
                unreqProjectVariables.filter(
                  (variable) => variable["variable-context"] == "project"
                )
              );
              setUnrequiredLocationVariables(
                unreqProjectVariables.filter(
                  (variable) =>
                    variable["variable-context"] == "service_location"
                )
              );
            }
            setIsLoading(false);
          });
        }
      );
    }
  }, [authorizationCode]);
  useEffect(() => {
    if (guidedFlowEnabled && currentPage !== 5 && currentPage !== 0) {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = "Changes you made may not be saved";
      };

      const handleUnload = () => {
        alert("Changes you made may not be saved");
      };

      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("unload", handleUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("unload", handleUnload);
      };
    }
  }, [guidedFlowEnabled, currentPage]);

  const getCrmOptions = (event) => {
    API.Get(
      `${apiHost}/${account_slug}/v1/crm-opportunities?filter[display-name]=${event}&page[size]=500`,
      authorizationCode
    ).then((response) => {
      let options = response.data.data;
      setCrmState(options);
    });
  };

  const handleCrmChange = (e) => {
    let crmsId = e.value;
    setNewCrmId(crmsId);
    setCrmOppInvalid(false);

    if (crmsId === -1) {
      document.getElementById("clientName").readOnly = false;
      setNewCrmId(null);
    } else {
      for (let i = 0; i < crmState.length; i++) {
        if (crmState[i].id === crmsId) {
          let crmAttr = crmState[i].attributes;
          setProjectName(crmAttr.name);
          setClientName(crmAttr["account-name"]);
          filterClientList(crmAttr["account-name"]).then((res) => {
            const data = res.data.data;
            const clients = data.map((client) => ({
              id: client.id,
              client: client.attributes.name,
              msa_date: client.attributes["msa-date"],
              relationships: client.relationships,
            }));
            setClients(clients);
            const clientNames = data.map((client) => client.attributes.name);
            setClientList(clientNames);

            let clientExists = "";
            for (let i = 0; i < data.length; i++) {
              if (data[i].attributes.name === clientName)
                clientExists = data[i].id;
            }
            for (let j = 0; j < clients.length; j++) {
              if (crmAttr["account-name"] === clients[j].client) {
                setNewClientId(clients[j].id);
                setMsaDate(clients[j].msa_date);
              }
            }
          });
          let locationName = crmAttr["location-name"];
          if (locationName != null) {
            setCustomerSite(crmAttr["location-name"]);
          } else {
            setCustomerSite(crmAttr["account-name"]);
          }
          setAddress(crmAttr.street);
          if (crmAttr.street2) {
            setStreet2(crmAttr.street2);
          }
          setState(crmAttr.state);
          setCity(crmAttr.city);
          setZip(crmAttr["postal-code"]);
          if (crmAttr.country) {
            setCountry(crmAttr.country);
          }
          setAddressChanged(true);
          let customAttributes = crmAttr["custom-attributes"];
          if (customAttributes !== null && customAttributes.length !== 0) {
            handleCustomAttributes(
              customAttributes,
              unrequiredProjectVariables,
              setUnrequiredProjectVariables,
              crmAttr["owner-name"]
            );
            handleCustomAttributes(
              customAttributes,
              requiredProjectVariables,
              setRequiredProjectVariables,
              crmAttr["owner-name"]
            );
          }
          if (crmAttr["owner-name"]) {
            upsertSalesExecutiveId(
              crmAttr["owner-name"],
              account_slug,
              authorizationCode,
              account_id
            ).then((salesExec) => {
              setNewSalesExecId(salesExec.id);
              setCurrentSalesExec({
                label: crmAttr["owner-name"],
                id: salesExec.id,
              });
            });
          }
        }
      }
      document.getElementById("clientName").readOnly = true;
    }
  };

  const handleCustomAttributes = (
    customAttributes,
    variableArray,
    setVariableArray,
    hasOwnerName
  ) => {
    if (customAttributes !== null && customAttributes.length !== 0) {
      customAttributes.forEach((attr) => {
        if (attr.key === "sow_presales_engineer_id") {
          presales.forEach((eng) => {
            if (eng.attributes.name === attr.value) {
              setCurrentPresalesEngineer(attr.value);
              setNewPresalesEngineerId(eng.id);
            }
          });
        }
        if (attr.key === "client_manager_name" && !hasOwnerName) {
          upsertSalesExecutiveId(
            currentSalesExec.label,
            account_slug,
            authorizationCode,
            account_id
          ).then((salesExec) => {
            setNewSalesExecId(salesExec.id);
            setCurrentSalesExec({ label: attr.value, id: salesExec.id });
          });
        }
      });
      let newVariableArray = variableArray;

      for (let i = 0; i < newVariableArray.length; i++) {
        for (let j = 0; j < customAttributes.length; j++) {
          newVariableArray = newVariableArray.map((variable) =>
            variable.name === customAttributes[j].key
              ? { ...variable, value: customAttributes[j].value }
              : variable
          );
        }
      }
      setVariableArray(newVariableArray);
    }
  };

  const renderCrmOpportunity = () => {
    if (currentCrmOpporutnity !== "") {
      document.getElementById("clientName").readOnly = true;
      return (
        <DisabledTextField
          value={currentCrmOpporutnity}
          label={`${fieldLabels["crm_opportunity"]}`}
        />
      );
    } else {
      return (
        <>
          <DropdownApiJs
            placeholder={`Select ${fieldLabels["crm_opportunity"]}`}
            defaultValue={
              currentCrmOpporutnity === ""
                ? { value: -1, label: "-- None --" }
                : { value: crmId, label: currentCrmOpporutnity }
            }
            onChange={(e) => handleCrmChange(e)}
            required={crmFlag}
            label={`${fieldLabels["crm_opportunity"]} ${crmFlag ? "*" : ""}`}
            isInvalid={crmOppInvalid}
            url={`${apiHost}/${account_slug}/v1/crm-opportunities`}
            isDisabled={false}
            filter="display-name"
            blankOption={{ value: -1, label: "--None--" }}
            canCreate={false}
            setDataState={setCrmState}
          />
        </>
      );
    }
  };

  const modalBody = (
    <div>
      <p>You've added all the needed details to create a project.</p>
      <br></br>
      <p>
        Would you like to go immediately to the Project Editor (recommended for
        advanced users) or continue in the Project Creation Workflow
        (recommended for new users)?
      </p>
    </div>
  );

  const filterClientList = (clientName) => {
    return API.Get(
      `${apiHost}/${account_slug}/v1/clients?filter[name]=${encodeURIComponent(
        clientName
      )}&include=rate-table`,
      authorizationCode
    );
  };

  const getNewProjectData = (clientData, salesExecData) => {
    let newProjectData = {
      data: {
        type: "projects",
        attributes: {
          "project-name": projectName,
          "msa-date": msaDate === null ? "" : msaDate,
          "project-variables": getUpdatedProjectVariables(
            requiredProjectVariables.concat(unrequiredProjectVariables)
          ),
        },
        relationships: {
          account: { data: { type: "accounts", id: account_id } },
          "sales-executive": salesExecData,
          client: clientData,
          "crm-opportunity": getUpdatedCrm(newCrmId),
          "payment-term": getUpdatedPaymentTerm(newPaymentTermId),
          "presales-engineer": getUpdatedPresalesEngineer(
            newPresalesEngineerId
          ),
          "rate-table": getUpdatedRateTable(newRateTableId),
          "business-unit": getUpdatedBusinessUnit(newBusinessUnitId),
        },
      },
    };
    if (!newBusinessUnitId) {
      delete newProjectData.data.relationships["business-unit"];
    }
    return newProjectData;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //Force select of CRM opportunity
    if (!newCrmId && crmFlag) {
      window.scrollTo(0, 0);
      setCrmOppInvalid(true);
      return;
    }
    if (configSalesExecutiveRequired && currentSalesExec.label == "") {
      setSalesExecInvalid(true);
      setContinueButtonDisabled(true);
      return;
    }

    // Project Variable Validation
    const allProjectVariables = requiredProjectVariables.concat(
      unrequiredProjectVariables
        .concat(requiredLocationVariables)
        .concat(unrequiredLocationVariables)
    );
    const projectVariablesAreValid = validateVariables(
      allProjectVariables,
      setMinValue,
      setMaxValue,
      setShowMinCharAlert,
      setShowMaxCharAlert
    );
    if (projectVariablesAreValid === false) {
      setContinueButtonDisabled(false);
      return;
    }

    // Location Validation
    const locationIsValid = validateLocation(
      addressChanged,
      customerSite,
      address,
      city,
      state,
      zip,
      remoteSite,
      setLocationErrors
    );
    if (locationIsValid === false) {
      window.scrollTo(0, 0);
      setShowLocationAlert(true);
      setContinueButtonDisabled(false);
      return;
    }

    const postProject = (data) => {
      setErrorMessage(null);
      API.Post(
        `${apiHost}/${account_slug}/v1/projects`,
        data,
        authorizationCode
      )
        .then((response) => {
          const projectId = response.data.data.id;
          setNewProjectId(projectId);
          setPatchProjectUrl(response.data.data.links.self);
          if (response.status == 201) {
            setShowModal(true);
            setProjectIsSaving(false);
          }
          // Post a new location if the user typed one using the newProjectId after the project is created
          if (
            addressChanged === true &&
            customerSite !== "" &&
            (remoteSite || (address !== "" && city !== "" && zip !== ""))
          ) {
            upsertProjectLocation(
              response.data.data.id,
              unrequiredLocationVariables,
              requiredLocationVariables,
              locationId,
              account_slug,
              authorizationCode,
              customerSite,
              address,
              street2,
              city,
              state,
              zip,
              remoteSite,
              countryId
            ).catch((err) => {
              setShowFailAlert(true);
              if (source === "surveys") {
                setCurrentSurveyPage(1);
              }
              setSubmitFail(true);
              setContinueButtonDisabled(false);
            });
          }

          //If we come from surveys, create the project survey
          if (source === "surveys") {
            handleApplySurveys(projectId);
          }
        })
        .catch((err) => {
          setErrorMessage(formatUnprocessibleResponse(err, "Project"));
          setShowFailAlert(true);
          if (source === "surveys") {
            setCurrentSurveyPage(1);
          }
          setSubmitFail(true);
          setProjectIsSaving(false);
          setContinueButtonDisabled(false);
        });
    }; // End post project function

    const buildProjectPromises = () => {
      let projectPromises = [];

      if (clientName !== "") {
        projectPromises.push(
          upsertClientId(
            clientName,
            account_slug,
            authorizationCode,
            account_id
          )
        );
      }
      if (currentSalesExec !== "") {
        projectPromises.push(
          upsertSalesExecutiveId(
            currentSalesExec.label,
            account_slug,
            authorizationCode,
            account_id
          )
        );
      }

      return projectPromises;
    };

    let newProjectData = getNewProjectData();
    let projectPromises = buildProjectPromises();

    if (projectPromises.length !== 0) {
      Promise.all(projectPromises).then((values) => {
        values.forEach((res) => {
          if (res.type == "clients") {
            newProjectData.data.relationships["client"] = { data: res };
          }
          if (res.type == "sales-executives") {
            if (res.id) {
              newProjectData.data.relationships["sales-executive"] = {
                data: res,
              };
            } else {
              delete newProjectData.data.relationships["sales-executive"];
            }
          }
        });
        if (newProjectData.data.relationships["client"].data.id.error) {
          setErrorMessage(
            newProjectData.data.relationships["client"].data.id.error
          );
          setShowFailAlert(true);
          if (source === "surveys") {
            setCurrentSurveyPage(1);
          }
          setSubmitFail(true);
        } else {
          postProject(newProjectData);
        }
      });
    } else {
      postProject(newProjectData);
    }
  };

  const ProjectForm = (
    <Form className="projectForm" onSubmit={handleSubmit}>
      <Card className="whiteBg details">
        <Card.Body>
          {source !== "surveys" && (
            <Card.Header className="cardHeaderOverride">
              <div className="headerContainer">
                <div className="actionBtns">
                  <Button
                    className="btnSeawhite"
                    onClick={() => {
                      const confirmLeave = confirm(
                        "Changes you made may not be saved. Do you want to continue?"
                      );
                      if (confirmLeave) {
                        navigate("/projects");
                      }
                    }}
                  >
                    Back To Project List
                  </Button>
                </div>
                <div className={"actionBtns"}>
                  <Button
                    className="btnSeafoam"
                    type="submit"
                    disabled={continueButtonDisabled}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </Card.Header>
          )}
          <Row style={{ padding: "16px" }}>
            <Col sm={6}>
              <div style={{ marginBottom: "40px" }}>
                <div className="text24">Project Details</div>
                <div className="columnSubtitle">
                  Enter the details relevant to your organization in regards to
                  the project.
                </div>
              </div>
              <Row>
                <Col>{isCrm === true ? renderCrmOpportunity() : null}</Col>
              </Row>
              <Row>
                <Col>
                  <FormTextField
                    required={true}
                    id="projectName"
                    onChange={(e) => {
                      setProjectName(e.target.value);
                    }}
                    value={projectName}
                    label="Project Name *"
                  />
                </Col>
              </Row>
              <Row
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Col sm={6}>
                  <DropdownApiJs
                    label={
                      configSalesExecutiveRequired
                        ? fieldLabels["sales_executive"] + " *"
                        : fieldLabels["sales_executive"]
                    }
                    value={currentSalesExec}
                    required={configSalesExecutiveRequired}
                    isInvalid={salesExecInvalid && configSalesExecutiveRequired}
                    onChange={(e) => {
                      setSalesExecInvalid(false);
                      setContinueButtonDisabled(false);
                      setCurrentSalesExec(e);
                      setNewSalesExecId(e.value);
                    }}
                    url={`${apiHost}/${account_slug}/v1/sales-executives`}
                    isDisabled={false}
                    filter="name"
                    blankOption={null}
                    canCreate={salesExecutiveCreatePermission}
                  />
                </Col>
                <Col sm={6}>
                  <DropDownField
                    label={
                      configPresalesEngineerRequired
                        ? fieldLabels["presales_engineer"] + " *"
                        : fieldLabels["presales_engineer"]
                    }
                    value={currentPresalesEngineer}
                    required={configPresalesEngineerRequired}
                    onChange={(e) => {
                      let selected = e.target.options.selectedIndex;
                      setNewPresalesEngineerId(
                        e.target.options[selected].accessKey
                      );
                    }}
                    options={presales.map((presale) => {
                      return (
                        <option
                          className="presalesOptions"
                          key={presale.id}
                          accessKey={presale.id}
                        >
                          {presale.attributes.name}
                        </option>
                      );
                    })}
                  />
                </Col>
                {rateTables.length > 1 ? (
                  <Col sm={6}>
                    <DropDownField
                      label="Rate Table *"
                      value={currentRateTable}
                      onChange={(e) => {
                        let selected = e.target.options.selectedIndex;
                        setNewRateTableId(e.target.options[selected].accessKey);
                      }}
                      options={rateTables.map((rateTable) => (
                        <option key={rateTable.id} accessKey={rateTable.id}>
                          {rateTable.attributes.name}
                        </option>
                      ))}
                    />
                  </Col>
                ) : null}
                {businessUnits.length !== 0 ? (
                  <Col sm={6}>
                    <ControlledDropDown
                      label={
                        configBusinessUnitRequired
                          ? fieldLabels["business_unit"] + " *"
                          : fieldLabels["business_unit"]
                      }
                      value={currentBusinessUnit}
                      required={configBusinessUnitRequired}
                      onChange={(e) => {
                        setCurrentBusinessUnit(e.target.value);
                        let selected = e.target.options.selectedIndex;
                        setNewBusinessUnitId(
                          e.target.options[selected].accessKey
                        );
                      }}
                      options={[
                        <option
                          value={null}
                          key={null}
                          accessKey={null}
                        ></option>,
                        businessUnits.map((businessUnit) => {
                          return (
                            <option
                              className="businessUnitOptions"
                              key={businessUnit.id}
                              accessKey={businessUnit.id}
                            >
                              {businessUnit.attributes.name}
                            </option>
                          );
                        }),
                      ]}
                    />
                  </Col>
                ) : null}
                {!configMsaDateHidden && (
                  <Col sm={6}>
                    <FormDateField
                      label="MSA Date"
                      value={msaDate}
                      onChange={(e) => {
                        setMsaDate(e.target.value);
                      }}
                    />
                  </Col>
                )}
                {requiredProjectVariables.length > 0 ? (
                  <>
                    <ProjectVariables
                      arr={requiredProjectVariables}
                      account_slug={account_slug}
                      project_id={null}
                      setState={setRequiredProjectVariables}
                      setVariableChanged={null}
                    />
                    <ProjectVariables
                      arr={unrequiredProjectVariables}
                      account_slug={account_slug}
                      project_id={null}
                      setState={setUnrequiredProjectVariables}
                      setVariableChanged={null}
                    />
                  </>
                ) : null}
              </Row>
            </Col>
            <Col sm={6}>
              <div style={{ marginBottom: "40px" }}>
                <div className="text24">Client Details</div>
                <div className="columnSubtitle">
                  Enter the details relevant to the client for whom you are
                  rendering services.
                </div>
              </div>
              <Row>
                <Col>
                  <AutoCompleteText
                    required={true}
                    id="clientName"
                    label={fieldLabels.client + " *"}
                    suggestionList={clientList}
                    value={clientName}
                    monitorValue={(value) => {
                      chosenClient = value;
                      filterClientList(value).then((res) => {
                        const data = res.data.data;
                        const clients = data.map((client) => ({
                          id: client.id,
                          client: client.attributes.name,
                          msa_date: client.attributes["msa-date"],
                          relationships: client.relationships,
                        }));
                        setClients(clients);
                        const clientNames = data.map(
                          (client) => client.attributes.name
                        );
                        setClientList(clientNames);

                        let clientExists = "";
                        for (let i = 0; i < data.length; i++) {
                          if (data[i].attributes.name === clientName)
                            clientExists = data[i].id;
                        }
                      });
                      for (let i = 0; i < clients.length; i++) {
                        if (clients[i].client === chosenClient) {
                          setMsaDate(clients[i].msa_date);
                          setNewClientId(clients[i].id);
                          const rateTableData =
                            clients[i].relationships["rate-table"].data;
                          if (rateTableData !== null) {
                            for (let j = 0; j < rateTables.length; j++) {
                              if (rateTables[j].id === rateTableData.id) {
                                setCurrentRateTable(
                                  rateTables[j].attributes.name
                                );
                                setNewRateTableId(rateTables[j].id);
                              }
                            }
                          }
                        }
                      }
                      setClientName(chosenClient);
                    }}
                    setTextChanged={(bool) => {
                      setUserTypedClient(bool);
                    }}
                    crmId={newCrmId}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <AddressForm
                    label={
                      configServiceLocationRequired
                        ? fieldLabels.location + " *"
                        : fieldLabels.location
                    }
                    required={configServiceLocationRequired}
                    onSiteChange={(e) => {
                      setCustomerSite(e.target.value);
                      setAddressChanged(true);
                    }}
                    siteName={customerSite}
                    onAddressChange={(e) => {
                      setAddress(e.target.value);
                      setAddressChanged(true);
                    }}
                    remoteLocation={remoteSite}
                    onRemoteChanged={(e) => {
                      setRemoteSite(e.target.checked);
                      setAddressChanged(true);
                    }}
                    addressText={address}
                    onCityChange={(e) => {
                      setCity(e.target.value);
                      setAddressChanged(true);
                    }}
                    cityText={city}
                    onStateChange={(e) => {
                      setState(e.target.value);
                      setAddressChanged(true);
                    }}
                    stateText={state}
                    onZipChange={(e) => {
                      setZip(e.target.value);
                      setAddressChanged(true);
                    }}
                    zipText={zip}
                    placeholder="Customer Site"
                    street2Text={street2}
                    onStreet2Change={(e) => {
                      setStreet2(e.target.value);
                      setAddressChanged(true);
                    }}
                    country={country}
                    onCountryChange={(e) => {
                      setCountry(e);
                      setCountryId(e.id);
                      setAddressChanged(true);
                    }}
                  />
                  {requiredLocationVariables.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <ProjectVariables
                        overridePadding={true}
                        arr={
                          addressChanged
                            ? requiredLocationVariables.map((pv) => {
                                pv.required = true;
                                return pv;
                              })
                            : requiredLocationVariables.map((pv) => {
                                pv.required = false;
                                return pv;
                              })
                        }
                        account_slug={account_slug}
                        project_id={null}
                        setState={setRequiredLocationVariables}
                        setVariableChanged={null}
                      />
                      <ProjectVariables
                        overridePadding={true}
                        arr={unrequiredLocationVariables}
                        account_slug={account_slug}
                        project_id={null}
                        setState={setUnrequiredLocationVariables}
                        setVariableChanged={setAddressChanged}
                      />
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <button
        onClick={(e) => {
          handleSubmit(e);
        }}
        ref={submitRef}
        type="button"
        style={{ display: "none" }}
      />
    </Form>
  );

  const goToProjectEditor = () => {
    return window.location.replace(
      `${appHost}/projects/${newProjectId}/project_editor`
    );
  };

  const handleServicesData = (servicesData) => {
    setProjectServices(servicesData.data);
    setProjectServiceData(servicesData);
  };

  const handleSavedTermsChange = (data) => {
    setSavedTerms(data);
  };

  const renderPages = () => {
    if (currentPage === 0) {
      window.location.hash = "1";

      return ProjectForm;
    }
    if (currentPage === 1) {
      window.location.hash = "2";

      return (
        <Summary
          account_slug={account_slug}
          authorizationCode={authorizationCode}
          projectId={newProjectId}
          patchProjectUrl={patchProjectUrl}
          setCurrentPage={(page) => setCurrentPage(page)}
          setPageReturn={(bool) => setPageReturn(bool)}
          pageReturn={pageReturn}
          projectServices={projectServices}
        />
      );
    }
    if (currentPage === 2) {
      window.location.hash = "3";

      return (
        <Services
          account_slug={account_slug}
          authorizationCode={authorizationCode}
          projectId={newProjectId}
          patchProjectUrl={patchProjectUrl}
          setCurrentPage={(page) => setCurrentPage(page)}
          setPageReturn={(bool) => setPageReturn(bool)}
          pageReturn={pageReturn}
          projectServices={projectServices}
          onData={handleServicesData}
          resourceRatesEnabled={resourceRatesEnabled}
          rateTableId={newRateTableId}
        />
      );
    }
    if (currentPage === 3) {
      window.location.hash = "4";

      return (
        <ServiceDescriptions
          account_slug={account_slug}
          authorizationCode={authorizationCode}
          projectId={newProjectId}
          patchProjectUrl={patchProjectUrl}
          setCurrentPage={(page) => setCurrentPage(page)}
          setPageReturn={(bool) => setPageReturn(bool)}
          pageReturn={pageReturn}
          projectServices={projectServices}
          projectServiceData={projectServiceData}
          setProjectServiceData={handleServicesData}
        />
      );
    }
    if (currentPage === 4) {
      window.location.hash = "5";

      return (
        <Pricing
          account_slug={account_slug}
          account_id={account_id}
          authorizationCode={authorizationCode}
          projectId={newProjectId}
          patchProjectUrl={patchProjectUrl}
          setCurrentPage={(page) => setCurrentPage(page)}
          setPageReturn={(bool) => setPageReturn(bool)}
          pageReturn={pageReturn}
          projectServices={projectServices}
          handleSavedTermsChange={handleSavedTermsChange}
          savedTerms={savedTerms}
        />
      );
    }
    if (currentPage === 5) {
      window.location.hash = "6";

      return (
        <ReviewAndGenerate
          account_slug={account_slug}
          authorizationCode={authorizationCode}
          projectId={newProjectId}
          patchProjectUrl={patchProjectUrl}
          setCurrentPage={(page) => setCurrentPage(page)}
          setPageReturn={(bool) => setPageReturn(bool)}
          pageReturn={pageReturn}
          projectServices={projectServices}
          save_to_cloud_storage_flag={save_to_cloud_storage_flag}
        />
      );
    }
  };

  const continueProjectCreationWorkFlow = () => {
    setCurrentPage(1);
    setShowModal(false);
  };
  if (isLoading) {
    return <ScopeStackSpinner />;
  } else if (!guidedFlowEnabled && source == "createProjectMspa") {
    return (
      <NewProject
        account_slug={account_slug}
        project_id={null}
        account_id={account_id}
        authorizationCode={authorizationCode}
        salesExecutiveCreatePermission={salesExecutiveCreatePermission}
      />
    );
  } else {
    return (
      <>
        {source !== "surveys" ? (
          <ScopeStackModal
            modalTitle="Create Project"
            modalBody={modalBody}
            button1Text="Go To Project Editor"
            handleButton1Click={() => goToProjectEditor()}
            button2Text="Continue"
            handleButton2Click={() => continueProjectCreationWorkFlow()}
            show={showModal}
          />
        ) : null}
        <div
          className={
            source !== "surveys" ? "greyBg createProjectContainer" : ""
          }
        >
          {submitFail === true
            ? handleAlert(
                showFailAlert,
                errorMessage,
                setShowFailAlert,
                "warning",
                ToastAlert
              )
            : null}
          {handleCharacterAlert(
            showMinCharAlert,
            setShowMinCharAlert,
            showMaxCharAlert,
            setShowMaxCharAlert,
            ToastAlert,
            minValue,
            maxValue
          )}
          {handleLocationAlert(
            showLocationAlert,
            locationErrors,
            setShowLocationAlert
          )}
          {handleUserExistsAlert(
            userExistsAlertIsOpen,
            setUserExistsAlertIsOpen,
            ToastAlert
          )}
          {projectIsSaving ? (
            <SmallSpinner />
          ) : (
            <div>
              {source !== "surveys" ? (
                <div>
                  <h2 className="createNewProjectTitle">
                    Create a New Project
                  </h2>
                  <hr className="sectionDivider"></hr>
                  <div style={{ height: "110px" }}>
                    <IconProgressBar
                      icons={[
                        {
                          class: "pencilIcon",
                          name: faPencil,
                          pageTitle: "Project",
                          id: 0,
                        },
                        {
                          class: "pencilIcon",
                          name: faPencil,
                          pageTitle: "Project",
                          id: 0,
                        },
                        {
                          class: "alignIcon",
                          name: faAlignCenter,
                          pageTitle: "Summary",
                          id: 1,
                        },
                        {
                          class: "serviceIcon",
                          name: faScrewdriverWrench,
                          pageTitle: "Services",
                          id: 2,
                        },
                        {
                          class: "teamIcon",
                          name: faMoneyCheckPen,
                          pageTitle: "Service Descriptions",
                          id: 3,
                        },
                        {
                          class: "dollarIcon",
                          name: faDollarSign,
                          pageTitle: "Pricing",
                          id: 4,
                        },
                        {
                          class: "memoIcon",
                          name: faMemo,
                          pageTitle: "Review & Generate",
                          id: 5,
                        },
                      ]}
                      currentPage={currentPage}
                    />
                  </div>
                  <hr className="sectionDivider"></hr>
                </div>
              ) : null}
              {renderPages()}
            </div>
          )}
        </div>{" "}
        <ConfirmationModal
          show={showConfirmation}
          title="Return to Project Editor"
          message="Are you sure you want to return to the project editor?"
          onConfirm={() => {
            navigate("/projects");
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
        />
      </>
    );
  }
}

export default NewProjectDetails;
