import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import "./EditableDataTable.css";
import { Column } from "./types/column";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { faGear, faPlusSquare } from "@fortawesome/pro-light-svg-icons";
import ContextMenu from "@components/ContextMenu/ContextMenu";
import { toCamelCase } from "@utils/helpers";

interface EditableDataTableProps {
  records: any[];
  columns: Column[];
  config: {
    classForTable?: string;
    bordered?: boolean;
    striped?: boolean;
    stripeColor?: string;
    rowBorder?: boolean;
    hover?: boolean;
    multiselect?: boolean;
    dragAndDrop?: boolean;
    expandable?: boolean;
    startUncollapsed?: boolean;
    hasSettings?: boolean;
    onSettingsClick?: (resource: any, settingsOpen: boolean) => void;
    settingsListItems?: any[];
    settingsMenuStyles?: object;
  };
  selectedIds?: string[];
  setSelectedIds?: (ids: string[]) => void;
  setData?: (data) => void;
}

function EditableDataTable({
  records,
  columns,
  config,
  selectedIds,
  setSelectedIds,
  setData,
}: EditableDataTableProps) {
  const [dragItem, setDragItem] = useState<number>(0);
  const [dropPosition, setDropPosition] = useState<number>(0);

  const tableClass = `sansTable editableDataTable table ${
    config.bordered ? "table-bordered" : ""
  } ${config.hover ? "table-hover" : ""} ${
    config.classForTable ? config.classForTable : ""
  }`;
  const firstColumn = columns[0];

  const modifiedFirstColumn: Column = config.multiselect
    ? {
        ...firstColumn,
        cell: (context) => {
          const rowId = context.id.toString();
          const isChecked = selectedIds?.includes(rowId);

          return (
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <input
                type="checkbox"
                style={{ accentColor: "#418172" }}
                checked={isChecked}
                onChange={() => {
                  if (isChecked) {
                    setSelectedIds?.(
                      selectedIds?.filter((id) => id !== rowId) || []
                    );
                  } else {
                    setSelectedIds?.([...(selectedIds || []), rowId]);
                  }
                }}
              />
              <div style={{ marginLeft: "8px", width: "100%" }}>
                {firstColumn.cell(context)}
              </div>
            </div>
          );
        },
      }
    : firstColumn;

  let configuredColumns = [modifiedFirstColumn, ...columns.slice(1)];
  const newFirstColumn = configuredColumns[0];

  const collapsibleFirstColumn: Column = config.expandable
    ? {
        ...newFirstColumn,
        cell: (context) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                width: "100%",
              }}
            >
              <FontAwesomeIcon
                icon={faPlusSquare}
                style={{
                  marginRight: "1.5em",
                  fontSize: "15px",
                }}
              />
              <div
                style={{ width: "100%" }}
                onClick={(e) => e.stopPropagation()}
              >
                {newFirstColumn.cell(context)}
              </div>
            </div>
          );
        },
      }
    : newFirstColumn;

  const dndFirstColumn: Column = config.dragAndDrop
    ? {
        ...collapsibleFirstColumn,
        cell: (context) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                width: "100%",
              }}
              className="dndColumn"
            >
              <FontAwesomeIcon
                onClick={(e) => e.stopPropagation()}
                icon={faGripDotsVertical}
                style={{
                  marginRight: "1.5em",
                  fontSize: "15px",
                }}
              />
              <div style={{width: "100%"}}>{collapsibleFirstColumn.cell(context)}</div>
            </div>
          );
        },
      }
    : newFirstColumn;

  configuredColumns = config.dragAndDrop
    ? [dndFirstColumn, ...configuredColumns.slice(1)]
    : [collapsibleFirstColumn, ...configuredColumns.slice(1)];

  const handleDragStart = (index) => {
    setDragItem(index);
  };

  const handleDragEnter = (e, index, row) => {
    e.preventDefault();
    if (e.target.tagName === "TD") {
      e.target.parentElement.setAttribute(
        "style",
        "border-bottom: thick solid green"
      );
    }

    e.target.style.opactiy = "0.5";
    setDropPosition(index + 1);
  };

  const handleDragLeave = (e) => {
    e.target.style.opactiy = "0.5";
    e.target.parentElement.style.removeProperty("border-bottom");
  };

  const handleDrop = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.style.opactiy = "1";
    e.target.parentElement.style.removeProperty("border-bottom");

    const newPosition = dropPosition;
    const oldPosition = dragItem + 1;

    const droppedItem = records[dragItem];

    const updated = records
      .map((item) => {
        if (!item.position || !item.id) return item;
        if (item.id == droppedItem.id) {
          return (item = { ...item, position: newPosition, isChanged: true });
        } else if (
          oldPosition < newPosition &&
          item?.position > oldPosition &&
          item?.position <= newPosition
        ) {
          // Item moved down, shift others up
          return { ...item, position: item?.position - 1, isChanged: true };
        } else if (
          oldPosition > newPosition &&
          item?.position < oldPosition &&
          item?.position >= newPosition
        ) {
          // Item moved up, shift others down
          return { ...item, position: item?.position + 1, isChanged: true };
        } else {
          return item;
        }
      })
      .sort((a, b) => a.position! - b.position!);

    if (setData) {
      setData(updated);
    }
  };

  const configuredRows = config.dragAndDrop
    ? records.sort((a, b) => a.position! - b.position!)
    : records;

  useEffect(() => {
    if (config.startUncollapsed) {
      records.map((item) => {
        const classList = document.getElementById(
          `multiCollapse${item?.id}`
        )?.classList;
        if (!classList?.contains("show")) {
          classList?.add("show");
        }
      });
    }
  }, [records]);

  return (
    <Table
      className={tableClass}
      bordered={config.bordered}
      striped={false}
      hover={config.hover}
    >
      <thead>
        <tr>
          {configuredColumns.map((column, index) => {
            return (
              <th
                className={`${column.headerClass ? column.headerClass : ""} ${
                  column?.id ? `${toCamelCase(column?.id)}Col` : ""
                }`}
                key={`header-data-${index}`}
              >
                {column.header()}
              </th>
            );
          })}
        </tr>
      </thead>
      {configuredRows.map((record, index) => {
        return (
          <tbody
            draggable={config.dragAndDrop}
            onDragStart={() => handleDragStart(index)}
            onDragEnter={(e) => handleDragEnter(e, index, record)}
            onDragOverCapture={(e) => e.preventDefault()}
            onDragLeave={(e) => handleDragLeave(e)}
            onDrop={(e) => handleDrop(e, record)}
            onDragOver={(e) => e.preventDefault()}
            data-toggle="collapse"
            data-target={`.multi-collapse${record.id}`}
            aria-controls={`multiCollapseExample${record.id}`}
            key={record.id}
            style={
              config.striped && index % 2 == 0
                ? {
                    backgroundColor: config.stripeColor
                      ? config.stripeColor
                      : "#eee",
                    cursor: record.url ? "pointer" : "default",
                    borderTop: config.rowBorder ? "2px solid #dee2e6" : "none",
                  }
                : {
                    cursor: record.url ? "pointer" : "default",
                    borderTop: config.rowBorder ? "2px solid #dee2e6" : "none",
                  }
            }
            onClick={() => {
              if (record.url) {
                window.location.href = record.url;
              }
            }}
          >
            <tr>
              {configuredColumns.map((column, index) => {
                const isLastCell = index === configuredColumns.length - 1;
                return (
                  <td
                    onClick={
                      index !== 0 ? (e) => e.stopPropagation() : () => null
                    }
                    className={column.cellClass ? column.cellClass : ""}
                    key={`table-data-${index}`}
                    colSpan={column.colspan}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: isLastCell
                          ? "space-between"
                          : "flex-start",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {column.cell(record)}
                      {config.hasSettings && isLastCell && (
                        <>
                          <FontAwesomeIcon
                            icon={faGear}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (setData) {
                                setData(
                                  records.map((item) => {
                                    if (item.id == record.id) {
                                      return {
                                        ...item,
                                        settingsOpen: item?.settingsOpen!
                                          ? !item.settingsOpen
                                          : true,
                                      };
                                    } else if (item?.settingsOpen) {
                                      return { ...item, settingsOpen: false };
                                    }
                                    return item;
                                  })
                                );
                              }

                              if (column && record) {
                                config.onSettingsClick &&
                                  config.onSettingsClick(
                                    record,
                                    column.settingsOpen || false
                                  );
                              }
                            }}
                            style={{
                              marginLeft: "1em",
                              cursor: "pointer",
                              color: "#1c2655",
                              fontSize: "15px",
                            }}
                          />
                          {record.settingsOpen ? (
                            <ContextMenu
                              menuItems={config.settingsListItems}
                              style={config.settingsMenuStyles}
                            />
                          ) : null}
                        </>
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
            {config.expandable ? (
              <tr
                className={`collapse multi-collapse${record.id}`}
                id={`multiCollapse${record.id}`}
              >
                <td
                  onClick={(e) => e.stopPropagation()}
                  colSpan={columns.length}
                >
                  {record.collapsedData}
                </td>
              </tr>
            ) : null}
          </tbody>
        );
      })}
    </Table>
  );
}

export default EditableDataTable;
