import API from "../../../../../../utils/API/API";

const getService = async ({ account, serviceId }) => {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const url = new URL(`/${account.slug}/v1/services/${serviceId}`, apiHost);
  url.searchParams.append(
    "include",
    "subservices,resource,phase,subservices.resource,service-category,products"
  );

  try {
    const response = await API.Get(url.toString(), account.authorizationCode);

    return {
      data: response.data.data,
      included: response.data.included,
      error: null,
    };
  } catch (error) {
    return { data: null, error };
  }
};

export default getService;
