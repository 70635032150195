import { AccessLevels, Privilege, Privileges } from "../types";
interface Args {
  userPrivileges: Privilege[];
  privilege: Privileges;
  accessLevel: AccessLevels;
}

export const checkPrivilege = ({
  userPrivileges,
  privilege,
  accessLevel,
}: Args) => {
  return userPrivileges.some(
    (p) => p.privilege === privilege && p.accessLevel === accessLevel
  );
};

export const camelizeHyphenCaseKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeHyphenCaseKeys(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        ...(key.includes("-")
          ? {
              [key.replace(/-([a-z])/g, (g) => g[1].toUpperCase())]:
                camelizeHyphenCaseKeys(obj[key]),
            }
          : { [key]: camelizeHyphenCaseKeys(obj[key]) }),
      }),
      {}
    );
  }
  return obj;
};

export const formatDateAndTime = (date: Date): string => {
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();
  let hour = date.getHours();
  const minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
  const ampm = hour >= 12 ? "PM" : "AM";
  hour = hour % 12;
  hour = hour ? hour : 12; // Handle midnight
  const formattedDate =
    month +
    " " +
    day +
    ", " +
    year +
    " at " +
    hour +
    ":" +
    minutes +
    " " +
    ampm;
  return formattedDate;
};

export const toCamelCase = (input: string): string => {
  //takes in plain text and converts to camel case
  const words = input.split(" ");
  return words
    .map((word, index) => {
      return index === 0
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
};

export const isDevelopment = () => {
  return (
    process.env.NODE_ENV === "development" ||
    window.location.hostname === "scopestack.test"
  );
};

export interface Phase {
  id: number;
  name: string;
  position: number;
}
export interface Service {
  id: string;
  name?: string;
  phase?: Phase;
}

export const getOrganizedPhasesFromListOfServices = (services: Service[]) => {
  return Array.from(
    new Map(
      services.map((item) => item.phase).map((phase) => [phase?.id, phase])
    ).values()
  ).sort((a, b) => {
    if (a?.id === -1) return 1; // Move phase with id -1 to the end
    if (b?.id === -1) return -1; // Move phase with id -1 to the end
    return a?.position! - b?.position! || 0; // Maintain phase order by position
  });
};
