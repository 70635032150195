import { useGetQuestionnaireByIdQuery, V1QuestionResource } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { Questionnaire } from "../types";

const useGetQuestionnaire = (id) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  const { data, isLoading } = useGetQuestionnaireByIdQuery({
    slug: accountSlug,
    id,
    include: ["account", "questions"],
  });

  let questionnaire: Questionnaire | null = null;

  const questions =
    (data?.included?.filter(
      //@ts-ignore
      (item) => item.type === "questions" && item?.attributes?.active
    ) as V1QuestionResource[]) || [];

  if (data?.data) {
    questionnaire = {
      id: String(data.data.id),
      name: data.data?.attributes?.name || "",
      introduction: data.data?.attributes?.introduction || "",
      questions: questions
        .sort((a, b) => a?.attributes?.postion! - b?.attributes?.postion!)
        .map((q) => {
          return {
            ...q.attributes,
            "question-id": q?.id,
          };
        }),
    };
  }
  return { questionnaire, questionnaireLoading: isLoading };
};

export default useGetQuestionnaire;
