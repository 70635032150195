import { useEffect, useState, ChangeEvent, MouseEvent } from "react";
import {
  useListTeamsForAccountQuery,
  useShareSurveyMutation,
  V1QuestionnaireResource,
} from "../../../services/generated";
import { Button } from "react-bootstrap";
import { getSurveyTags } from "../SurveyHelpers/surveyHelpers";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import { Form } from "react-bootstrap";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import {
  formatUnprocessibleResponse,
  handleAlert,
  validateEmails,
} from "@utils/helperFunctions";
import { useNavigate } from "react-router";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-solid-svg-icons";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import newSurvey from "../SurveyHelpers/newSurvey";
import { useWhoAmIQuery } from "../../../services/generated";
import { SurveyFromAPI } from "../types/SurveyFromAPI";
import useListTakeSurveys from "./api/useListTakeSurveys";
import DataTable from "@components/DataTable";
import type { ColumnDef } from "@tanstack/react-table";
import { TakeSurveyType } from "./types";
import { faSortDown, faSortUp, faSort } from "@fortawesome/pro-solid-svg-icons";
interface TakeSurveyProps {
  filterParams?: { name?: string };
  surveysCount?: {
    completedSurveys?: number | null;
    takeSurveys?: number | null;
    manageSurveys?: number | null;
    archivedSurveys?: number | null;
  };
  setSurveysCount: (num) => void;
  setShowSlideOutMenu: (bool) => void;
  setSlideOutContent: (content) => void;
  accessToken: string;
  projectId?: number;
  currentPage?: number;
  setCurrentPage?: (num) => void;
}

function TakeSurvey({
  filterParams,
  surveysCount,
  setSurveysCount,
  setShowSlideOutMenu,
  setSlideOutContent,
  accessToken,
  projectId,
  currentPage = 1,
  setCurrentPage = (num: number) => {},
}: TakeSurveyProps): JSX.Element {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  const { data: allTeams, isLoading: teamsLoading } =
    useListTeamsForAccountQuery({
      slug: accountSlug,
      include: ["users"],
    });

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(""); // State to track the sorting column
  const [sortOrder, setSortOrder] = useState("asc");

  const { takeSurveys, isLoading, pageMeta, isFetching, included } =
    useListTakeSurveys({
      pageSize,
      pageNumber: currentPage || pageNumber,
      filterParams,
      sortBy,
      sortOrder,
    });

  const [shareSurvey] = useShareSurveyMutation();

  const [emailList, setEmailList] = useState("");
  const [showShareModal, setShowShareModal] = useState(false);
  const [selectedQuestionnaire, setSelectedQuestionnaire] =
    useState<any | null>(null);
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string[]>([]);

  const { data: account, isLoading: accountLoading } = useWhoAmIQuery();

  useEffect(() => {
    if (
      !isLoading &&
      !isFetching &&
      surveysCount &&
      pageMeta.recordCount !== surveysCount.takeSurveys
    )
      setSurveysCount({
        ...surveysCount,
        takeSurveys: pageMeta.recordCount,
      });
  }, [pageMeta]);

  const handleSort = (columnId: string) => {
    if (sortBy === columnId) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnId);
      setSortOrder("asc");
    }
  };

  const renderSortIcon = (columnId: string) => {
    if (sortBy === columnId) {
      return sortOrder === "asc" ? (
        <FontAwesomeIcon icon={faSortUp} />
      ) : (
        <FontAwesomeIcon icon={faSortDown} />
      );
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const columns: ColumnDef<TakeSurveyType>[] = [
    {
      id: "name",
      header: () => (
        <div className="surveysColumnHeader">
          Survey Name
          <div className="sortIcon" onClick={() => handleSort("name")}>
            {renderSortIcon("name")}
          </div>
        </div>
      ),
      cell(props) {
        return getSurveyName(props.row.original);
      },
    },
    {
      id: "introduction",
      header: () => (
        <div className="surveysColumnHeader">
          Description{" "}
          <div className="sortIcon" onClick={() => handleSort("introduction")}>
            {renderSortIcon("introduction")}
          </div>
        </div>
      ),
      cell(props) {
        return <>{props.row.original.attributes?.introduction || ""}</>;
      },
    },
    {
      id: "teams",
      header: "Teams",
      cell(props) {
        return getTeamsList(props.row.original.attributes?.teams || []);
      },
    },
    {
      id: "tag_list",
      header: "Tags",
      cell(props) {
        return getSurveyTags(props.row.original.attributes?.["tag-list"] || []);
      },
    },
  ];

  const handleSurveyCreation = async (questionnaire) => {
    let survey = await newSurvey({
      questionnaire: questionnaire,
      responses: included?.filter((item) => item.type == "survey-responses"),
      account,
      surveyId: null,
      accessToken,
      projectId: null,
    });
    return survey;
  };

  const getSurveyName = (questionnaire) => {
    const name = questionnaire.attributes.name;
    if (name === "No matches found") {
      return name;
    }

    let questionsForQuestionnaire: any[] = [];
    included.forEach((question) => {
      if (
        question["relationships"] &&
        questionnaire.id == question["relationships"]["questionnaire"].data?.id
      ) {
        questionsForQuestionnaire.push(question);
      }
    });
    if (questionsForQuestionnaire.length === 0) {
      return <div>{questionnaire?.attributes?.name}</div>;
    }
    return (
      <div className="flexCenter">
        <Button
          id={questionnaire.id}
          onClick={(e: MouseEvent) => {
            if (projectId) {
              navigate(
                `/projects/${projectId}/project_surveys/new?questionnaire=${questionnaire.id}`
              );
            } else {
              handleSurveyCreation(questionnaire).then((res) => {
                navigate(`/surveys/${questionnaire.id}/take`, {
                  state: { survey: res, isNewSurvey: true },
                });
              });
            }
          }}
          className="btnSeafoam inTableButton"
        >
          Take
        </Button>
        {!projectId && (
          <Button
            id={questionnaire.id}
            onClick={(e: MouseEvent) => {
              setSelectedQuestionnaire(questionnaire);
              setShowShareModal(true);
            }}
            className="btnSeawhite inTableButton"
          >
            Share
          </Button>
        )}
        <div>{questionnaire?.attributes?.name}</div>
      </div>
    );
  };

  const getTableData = (teams) => {
    if (teams.length === 0) {
      return [];
    }
    // Map over teams & return data for ScopeStackTable
    return teams.map((team, index) => {
      let currentTeam;
      let members: string[] = [];
      if (allTeams) {
        currentTeam = allTeams.data?.find((t) => t.id == team.id);
      }

      allTeams?.included?.forEach((user) => {
        currentTeam?.relationships.users.data.forEach((u) => {
          if (user.id == u.id) {
            if (user.attributes) {
              members.push(user.attributes.name);
            }
          }
        });
      });

      // Map returns a table row for each team
      return {
        rowClass: "teamsRow",
        onClick: null,
        columns: [
          {
            class: "col-3",
            name: currentTeam ? currentTeam.attributes.name : team.name,
          },
          {
            class: "col",
            name: currentTeam ? members.join(", ") : "",
          },
        ],
        actions: null,
      };
    });
  };

  const teamsHeaderData = {
    rowClass: "",
    columns: [
      {
        class: "col-3",
        name: "Team Name",
      },
      {
        class: "col",
        name: "Team Members",
      },
    ],
  };

  const getTeamsTable = (teams) => {
    const teamsTable = new ScopeStackTable(
      "teams",
      teamsHeaderData,
      getTableData(teams),
      null
    );

    return [
      {
        sectionTitle: "",
        sectionClass: "",
        sectionContent: (
          <aside style={{ margin: "20px" }}>
            <BaseTable
              className="sansTable teamsSlideOutTable"
              striped={true}
              hover={false}
              bordered={true}
              headerRows={teamsTable.buildHeaderRows()}
              dataRows={teamsTable.buildDataRows()}
              footerRows={teamsTable.buildFooterRows()}
              isLoading={false}
            />
          </aside>
        ),
      },
    ];
  };

  const getTeamsList = (teams): JSX.Element => {
    if (teams.length > 0) {
      return (
        <div>
          {teams.map((team) => team.name).join(", ")}&nbsp;&nbsp;
          <FontAwesomeIcon
            style={{ zIndex: "10", position: "relative" }}
            onClick={(e) => {
              e.stopPropagation();
              setSlideOutContent({
                title: "Teams Assigned To Survey",
                content: getTeamsTable(teams),
              });
              setShowSlideOutMenu(true);
            }}
            icon={faEye}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  const handleShare = () => {
    if (selectedQuestionnaire !== null) {
      handleSurveyCreation(selectedQuestionnaire).then((res) => {
        shareSurvey({
          slug: accountSlug,
          id: res?.data?.id || 0,
          body: {
            data: {
              emails: emailList.split(","),
            },
          },
        })
          .then((res) => {
            setShowShareModal(false);
            setSuccessMessage(["Surveys shared sucessfully."]);
            setShowSuccessAlert(true);
            setTimeout(() => {
              setShowSuccessAlert(false);
            }, 5000);
          })
          .catch((error) => {
            setShowShareModal(false);
            setErrorMessages([formatUnprocessibleResponse(error, "survey")]);
            setShowFailAlert(true);
          });
      });
    }
  };

  return isLoading || isFetching ? (
    <SmallSpinner />
  ) : (
    <>
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "danger",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <DataTable
        data={
          takeSurveys.length
            ? takeSurveys
            : [{ id: "0", attributes: { name: "No surveys found" } }]
        }
        columns={columns}
        bordered
        striped
        hover
        totalPages={pageMeta.pageCount}
        totalRows={pageMeta.recordCount}
        currentPage={currentPage || pageNumber}
        setCurrentPage={setCurrentPage || setPageNumber}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        paginationEnabled
        pageSizeEnabled
      />

      <ScopeStackModal
        modalTitle="Share Survey"
        modalBody={
          <div className="regularText">
            <p>
              To Share this survey externally for completion, please enter the
              email(s) to which you wish to share.
            </p>
            <Form.Group className="formGroup">
              <FormFieldLabel
                className={""}
                label="Email List (comma separated list of emails) *"
              />
              <Form.Control
                required={true}
                value={emailList}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEmailList(e.target.value)
                }
                isInvalid={!validateEmails(emailList)}
              />
            </Form.Group>
          </div>
        }
        button1Text="Cancel"
        handleButton1Click={() => setShowShareModal(false)}
        button2Text="Share"
        handleButton2Click={() => handleShare()}
        show={showShareModal}
        handleClose={() => setShowShareModal(false)}
      />
    </>
  );
}

export default TakeSurvey;
