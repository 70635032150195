import React, { useState, useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import API from "@API";
import useOauth from "@utils/customHooks/useOauth";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import CurrencyFormat from "react-currency-format";
import PricingCalculator from "../pricingUtils/PricingCalculator";
import OnHoverButtonIcon from "@components/Buttons/OnHoverButtons/OnHoverButtonIcon";
import {
  formatUnprocessibleResponse,
  handleAlert,
} from "@utils/helperFunctions";
import SeafoamButton from "@components/Buttons/SeafoamButton/SeafoamButton";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import FormNumberField from "@components/Forms/FormNumberField";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan } from "@fortawesome/pro-solid-svg-icons";

function ProjectPricing({
  account_slug,
  project_id,
  insight_pricing_adjustments,
}) {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [authorizationCode] = useOauth();

  const [currencyUnit, setCurrencyUnit] = useState("");
  const [allServices, setAllServices] = useState([]);
  const [professionalServices, setProfessionalServices] = useState([]);
  const [amortizedServices, setAmortizedServices] = useState([]);
  const [managedServices, setManagedServices] = useState([]);
  const [thirdPartyServices, setThirdPartyServices] = useState([]);
  const [oneTimeAdjustment, setOneTimeAdjustment] = useState("");
  const [recurringAdjustment, setRecurringAdjustment] = useState("");
  const [terms, setTerms] = useState([]);
  const [productPricing, setProductPricing] = useState([]);
  const [includeHardware, setIncludeHardware] = useState(false);
  const [projectCredits, setProjectCredits] = useState([]);
  const [professionalServiceList, setProfessionalServiceList] = useState([]);
  const [professionalSubserviceList, setProfessionalSubserviceList] = useState(
    []
  );
  const [amortizedServiceList, setAmortizedServiceList] = useState([]);
  const [amortizedSubserviceList, setAmortizedSubserviceList] = useState([]);
  const [managedServiceList, setManagedServiceList] = useState([]);
  const [managedSubserviceList, setManagedSubserviceList] = useState([]);
  const [marginPermission, setMarginPermission] = useState([]);
  const [rowsToOpen, setRowsToOpen] = useState({
    professionalRow: "closed",
    thirdPartyRow: "closed",
    managedRow: "closed",
    recurringThirdPartyRow: "closed",
    productRow: "closed",
  });

  const [changedMargins, setChangedMargins] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [arrayForRender, setArrayForRender] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(!forceUpdate);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [adjustModal, setAdjustModal] = useState({
    show: false,
    isRecurring: false,
    id: null,
  });
  const [price, setPrice] = useState(0);
  const [selectRepeats, setSelectRepeats] = useState("select");
  const [selectedServiceCategory, setSelectedServiceCategory] =
    useState("select");
  const [cost, setCost] = useState(0);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    "Revenue adjusted successfully!"
  );
  const [oneTimeAdjustmentList, setOneTimeAdjustmentList] = useState([]);
  const [recurringAdjustmentList, setRecurringAdjustmentList] = useState([]);
  const [allPricingAdjustments, setAllPricingAdjustments] = useState([]);

  const [pricingPrivilege, setPricingPrivilege] = useState(false);
  const [costPrivilege, setCostPrivilege] = useState(false);
  const [showEditProjectMarginModal, setShowEditProjectMarginModal] =
    useState(false);
  const [projectMargin, setProjectMargin] = useState("");
  const [project, setProject] = useState(null);

  useEffect(() => {
    if (authorizationCode !== "") {
      API.Get(`${apiHost}/v1/me`, authorizationCode).then((res) => {
        const costPrivilege = res.data.data.attributes.privileges.find(
          (p) => p.privilege === "projects.costing_adjustment"
        );
        const privilege = res.data.data.attributes.privileges.find(
          (p) => p.privilege === "projects.pricing_adjustment"
        );
        if (privilege && privilege["access-level"] === "manage") {
          setPricingPrivilege(true);
        }
        if (costPrivilege && costPrivilege["access-level"] === "manage") {
          setCostPrivilege(true);
        }
      });
      // Get Project Info with vendor quotes included
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=project-services.service-category`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        setProject(data);
        const attr = data.attributes;
        setProjectMargin((attr["contract-margin"] * 100).toFixed(2));
        setCurrencyUnit(attr["field-labels"].currency_unit);
        setOneTimeAdjustment(attr["one-time-adjustment"]);
        setRecurringAdjustment(attr["mrr-adjustment"]);
        setTerms(attr["mrr-terms"]);
        setIncludeHardware(attr["payment-info"]["include-product"]);
        if (response.data.included) {
          setServiceCategories(
            response.data.included.filter(
              (item) => item.type == "service-categories"
            )
          );
        }

        // Get project pricings
        API.Get(data.links["project-pricings"], authorizationCode).then(
          (res) => {
            let allServices = res.data.data;
            let marginPermission = res.data.meta.margin;
            setMarginPermission(marginPermission);
            setAllServices(allServices);
            let professionalServices = allServices.filter(
              (service) => service.attributes["service-type"] == "professional"
            );
            setProfessionalServices(
              professionalServices.filter(
                (service) =>
                  service.attributes["billing-frequency"] == "one_time"
              )
            );
            let amortizedServices = professionalServices.filter(
              (service) =>
                service.attributes["billing-frequency"] !== "one_time"
            );
            setAmortizedServices(amortizedServices);
            setManagedServices(
              allServices.filter(
                (service) => service.attributes["service-type"] == "managed"
              )
            );
            setThirdPartyServices(
              allServices.filter(
                (service) =>
                  service.attributes["source-type"] == "Quote" ||
                  service.attributes["source-type"] == "ThirdPartyRequest"
              )
            );
            setProductPricing(
              allServices.filter(
                (service) => service.attributes["service-type"] == "materials"
              )
            );
            setOneTimeAdjustmentList(
              allServices.filter(
                (service) =>
                  service.attributes["service-type"] === "adjustments" &&
                  service.attributes["billing-frequency"] === "one_time"
              )
            );
            setRecurringAdjustmentList(
              allServices.filter(
                (service) =>
                  service.attributes["service-type"] === "adjustments" &&
                  service.attributes["billing-frequency"] !== "one_time"
              )
            );
            setIsLoading(false);
          }
        );
      });

      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/pricing-adjustments`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        setAllPricingAdjustments(data);
      });

      // Get Project Credits
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-credits?include=payment-credit&filter[active]=true`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        setProjectCredits(data);
      });

      //Get professional services with included data & use to set state
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-services?filter[service-type]=professional_services&include=project-location,lob,project-phase,resource,project-subservices,project-subservices.resource`,
        authorizationCode
      ).then((response) => {
        let includedData = response.data.included;
        let subservices = [];
        if (includedData) {
          for (let i = 0; i < includedData.length; i++) {
            if (includedData[i].type === "project-subservices") {
              subservices.push(includedData[i]);
            }
          }
        }
        let services = response.data.data;
        setProfessionalServiceList(
          services.filter(
            (service) => service.attributes["payment-method"] !== "amortized"
          )
        );
        setProfessionalSubserviceList(
          subservices.filter(
            (subservice) =>
              subservice.attributes["payment-method"] !== "amortized"
          )
        );
        setAmortizedServiceList(
          services.filter(
            (subservice) =>
              subservice.attributes["payment-method"] == "amortized"
          )
        );
        setAmortizedSubserviceList(
          subservices.filter(
            (subservice) =>
              subservice.attributes["payment-method"] == "amortized"
          )
        );
      });

      //Get managed services with included data & use to set state
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-services?filter[service-type]=managed_services&include=project-location,lob,project-phase,resource,project-subservices,project-subservices.resource`,
        authorizationCode
      ).then((response) => {
        let includedData = response.data.included;
        let subservices = [];
        if (includedData) {
          for (let i = 0; i < includedData.length; i++) {
            if (includedData[i].type === "project-subservices") {
              subservices.push(includedData[i]);
            }
          }
        }
        setManagedSubserviceList(subservices);
        setManagedServiceList(response.data.data);
      });
    }
  }, [authorizationCode]);

  useEffect(() => {
    arrangeDataForTable();
  }, [
    professionalServices,
    managedServices,
    thirdPartyServices,
    productPricing,
  ]);

  const handleSave = (e) => {
    e.preventDefault();
    let promises = [];
    changedMargins.forEach((margin) => {
      let serviceData = {
        data: {
          id: margin.id,
          type: "project-services",
          attributes: {
            "target-margin": margin.value / 100,
          },
        },
      };
      promises.push(
        API.Patch(
          `${apiHost}/${account_slug}/v1/project-services/${margin.id}`,
          serviceData,
          authorizationCode
        )
      );
    });
    Promise.all(promises)
      .then((responses) => {
        let allRequestsSuccessful = true;
        for (let i = 0; i < responses.length; i++) {
          if (responses[i].status !== 200) {
            allRequestsSuccessful = false;
          }
        }
        if (allRequestsSuccessful) {
          window.location.reload();
        }
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "Service details"));
        setShowFailAlert(true);
      });
  };

  const getTableData = (array) => {
    if (array.length == 0) {
      return [];
    }
    // Map over array & return data for ScopeStackTable
    return array.map((item, index) => {
      if (
        item.type == "payment-credits-total" ||
        item.type == "customer-payments-total"
      ) {
        let columns = [
          { class: "text-right col-7", name: item.name, colSpan: 4 },
          {
            class: "text-right col-1",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={item.value !== "NaN" ? item.value : 0}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            ),
          },
        ];
        columns.push({ class: "col-3", colSpan: 3, name: <span>&nbsp;</span> });
        return {
          rowClass: "row pricingRow",
          onClick: null,
          columns: columns,
          actions: null,
        };
      } else {
        const getColumns = () => {
          if (item.name && item.name == "One Time Revenue") {
            return [
              {
                class: "pricingColumn col-11 bold",
                name: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item.name}{" "}
                    {insight_pricing_adjustments && pricingPrivilege ? (
                      <span
                        onClick={() => {
                          setAdjustModal({
                            show: true,
                            isRecurring: false,
                            id: null,
                          });
                          resetAllModalFields();
                        }}
                        className="greenUnderlinedCta"
                      >
                        Adjust
                      </span>
                    ) : null}
                  </div>
                ),
                colSpan: 8,
              },
            ];
          }
          if (
            (item.name && item.name == "Recurring Revenue") ||
            item.name == "Product"
          ) {
            return [
              {
                class: "pricingColumn col-11 bold",
                name:
                  item.name == "Product" ? (
                    item.name
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item.name}{" "}
                      {insight_pricing_adjustments && pricingPrivilege ? (
                        <span
                          onClick={() => {
                            setAdjustModal({
                              show: true,
                              isRecurring: true,
                              id: null,
                            });
                            resetAllModalFields();
                          }}
                          className="greenUnderlinedCta"
                        >
                          Adjust
                        </span>
                      ) : null}
                    </div>
                  ),
                colSpan: 8,
              },
            ];
          }
          if (item.type === "total") {
            return [
              {
                class: insight_pricing_adjustments
                  ? "pricingTotalColumn text-right col-5"
                  : "pricingTotalColumn text-right col-6",
                name: "",
                colSpan: 3,
              },
              {
                class: "pricingTotalColumn text-right col-1",
                name: item.totalCost,
              },
              {
                class: "pricingTotalColumn text-right col-1",
                name: (
                  <CurrencyFormat
                    displayType="text"
                    prefix={currencyUnit}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={item.tcv}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                ),
              },
              {
                class: "pricingTotalColumn text-right col-1",
                name: (
                  <CurrencyFormat
                    displayType="text"
                    prefix={currencyUnit}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={item.cost}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                ),
              },
              {
                class: "pricingTotalColumn text-right col-1",
                name: (
                  <CurrencyFormat
                    displayType="text"
                    prefix={currencyUnit}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={item.profit}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                ),
              },
              {
                class: "pricingTotalColumn text-right col-1",
                name: (
                  <span>{item.revenue == 0.0 ? "---" : item.margin + "%"}</span>
                ),
              },
              {
                class: "pricingTotalColumn text-right col-1",
                name: <span>&nbsp;&nbsp;&nbsp;</span>,
              },
            ];
          }
          const getColumnOne = () => {
            if (item.type == "professional") {
              return "Professional Services";
            }
            if (item.type == "managed") {
              return "Managed Services";
            }
            if (item.type.includes("third-party")) {
              return "Third Party Services";
            }
            if (item.type == "product") {
              return "Product";
            }
            if (item.type == "amortize") {
              return "Amortized Professional Services";
            }
            if (item.type == "detail") {
              return (
                <span style={{ marginLeft: "20px" }}>
                  {" "}
                  {item.serviceName
                    ? item.serviceName
                    : item.attributes["resource-name"]}
                </span>
              );
            }
            if (item.attributes) {
              return (
                <span style={{ marginLeft: "20px" }}>
                  {item.attributes["resource-name"]}
                </span>
              );
            }
            if (item.type.includes("Adjustment")) {
              return "Pricing Adjustments";
            }
          };

          let revenue = 0;
          if (item.revenue && item.revenue !== "NaN") {
            revenue = item.revenue;
          }
          if (item.attributes) {
            revenue = item.attributes["service-revenue"];
            if (item.id.includes("project-materials")) {
              revenue = item.attributes["hardware-price"];
            }
          }

          let adjustment = 0;
          if (item.adjustment && item.adjustment !== "NaN") {
            adjustment = item.adjustment;
          }
          if (
            item.attributes &&
            item.attributes["billing-frequency"] == "one_time"
          ) {
            adjustment = oneTimeAdjustment;
          }
          if (
            item.attributes &&
            item.attributes["billing-frequency"] !== "one_time"
          ) {
            adjustment = recurringAdjustment;
          }

          let net = 0;
          if (item.net && item.net !== "NaN") {
            net = item.net;
          }

          if (item.attributes) {
            totalCost = parseFloat(revenue) + parseFloat(adjustment);
            net = parseFloat(revenue) + parseFloat(adjustment);
          }

          let tcv = 0;
          if (item.tcv && item.tcv !== "NaN") {
            tcv = item.tcv;
          }
          if (item.attributes) {
            tcv =
              parseFloat(item.attributes["contract-revenue"]) +
              parseFloat(adjustment);
            if (item.id.includes("project-materials")) {
              tcv = parseFloat(revenue);
            }
          }

          let cost = 0;
          if (item.cost && item.cost !== "NaN") {
            cost = item.cost;
          }
          if (item.attributes) {
            cost = parseFloat(item.attributes["contract-cost"]);
            if (item.id.includes("project-materials")) {
              cost = item.attributes["hardware-cost"];
            }
          }

          let totalCost = 0;
          if (item.totalCost && item.totalCost !== "NaN") {
            totalCost = item.totalCost;
          }

          let profit = 0;
          if (item.profit && item.profit !== "NaN") {
            profit = item.profit;
          }
          if (item.attributes) {
            profit = parseFloat(tcv) - parseFloat(cost);
            if (item.id.includes("project-materials")) {
              profit =
                parseFloat(item.attributes["hardware-price"]) -
                item.attributes["hardware-cost"];
            }
          }

          let margin = 0;
          if (item.margin && item.margin !== "NaN") {
            margin = item.margin;
          }
          if (item.attributes) {
            if (parseFloat(tcv) !== 0) {
              margin = (parseFloat(profit) / parseFloat(tcv)).toFixed(2);
            } else {
              margin = "NaN";
            }
          }

          return [
            {
              class: "pricingColumn col-4",
              name: (
                <div
                  style={{ display: "flex", flexWrap: "nowrap" }}
                  className="indent"
                >
                  {getColumnOne()}
                </div>
              ),
            },
            {
              class: "pricingColumn text-right col-1",
              name: (
                <CurrencyFormat
                  displayType="text"
                  prefix={currencyUnit}
                  isNumericString={true}
                  thousandSeparator={true}
                  value={revenue && revenue !== "NaN" ? revenue : 0}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              ),
            },
            {
              class: insight_pricing_adjustments
                ? "displayNone"
                : "pricingColumn text-right col-1",
              name:
                item.type == "detail" || item.attributes ? (
                  <span>---</span>
                ) : (
                  <CurrencyFormat
                    displayType="text"
                    prefix={currencyUnit}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={adjustment && adjustment !== "NaN" ? adjustment : 0}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                ),
            },
            {
              class: insight_pricing_adjustments
                ? "displayNone"
                : "pricingColumn text-right col-1",
              name: (
                <CurrencyFormat
                  displayType="text"
                  prefix={currencyUnit}
                  isNumericString={true}
                  thousandSeparator={true}
                  value={net && net !== "NaN" ? net : 0}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              ),
            },
            {
              class: "pricingColumn text-right col-1",
              name: (
                <CurrencyFormat
                  displayType="text"
                  prefix={currencyUnit}
                  isNumericString={true}
                  thousandSeparator={true}
                  value={tcv && tcv !== "NaN" ? tcv : 0}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              ),
            },
            {
              class: "pricingColumn text-right col-1",
              name: (
                <CurrencyFormat
                  displayType="text"
                  prefix={currencyUnit}
                  isNumericString={true}
                  thousandSeparator={true}
                  value={cost && cost !== "NaN" ? cost : 0}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              ),
            },
            {
              class: insight_pricing_adjustments
                ? "pricingColumn text-right col-1"
                : "displayNone",
              name: (
                <CurrencyFormat
                  displayType="text"
                  prefix={currencyUnit}
                  isNumericString={true}
                  thousandSeparator={true}
                  value={totalCost && totalCost !== "NaN" ? totalCost : 0}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              ),
            },
            {
              class: "pricingColumn text-right col-1",
              name: (
                <CurrencyFormat
                  displayType="text"
                  prefix={currencyUnit}
                  isNumericString={true}
                  thousandSeparator={true}
                  value={profit && profit !== "NaN" ? profit : 0}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              ),
            },
            {
              class: "pricingColumn text-right col-1",
              name:
                item.id && item.id.includes("project-materials") ? (
                  "---"
                ) : (item.type == "detail" && (cost == 0.0 || tcv == 0.0)) ||
                  margin == "NaN" ? (
                  <span>---</span>
                ) : item.id && item.id.includes("project-expenses") ? (
                  <span>
                    {cost == 0.0 || margin == "NaN" || tcv == 0.0
                      ? "---"
                      : 100 * margin + "%"}
                  </span>
                ) : item.id && item.id.includes("project-governances") ? (
                  <span>
                    {cost == 0.0 || margin == "NaN" || tcv == 0.0
                      ? "---"
                      : margin + "%"}
                  </span>
                ) : item.type == "detail" &&
                  cost !== 0.0 &&
                  margin !== "NaN" &&
                  marginPermission == "manage" ? (
                  <Form.Control
                    style={{ width: "135%" }}
                    type="number"
                    min="0"
                    step="0.01"
                    defaultValue={cost == 0.0 || margin == "NaN" ? "" : margin}
                    onBlur={(e) => {
                      if (e.target.value !== margin) {
                        let margins = [];

                        // If user already changed the margin being edited, filter it out,then replace it
                        if (changedMargins.length !== 0) {
                          margins = changedMargins.filter(
                            (margin) => margin.id !== item.id
                          );
                        }
                        // Add the new margin with updated value;
                        setChangedMargins([
                          ...margins,
                          {
                            id: item.id,
                            value: e.target.value,
                          },
                        ]);
                      }
                    }}
                  />
                ) : (
                  <span>
                    {cost == 0.0 || margin == "NaN" || tcv == 0.0
                      ? "---"
                      : margin + "%"}
                  </span>
                ),
            },
          ];
        };

        // Map returns a table row for each item
        return {
          rowClass: "row pricingRow",
          onClick: null,
          columns: getColumns(),
          actions:
            item.type !== "total" &&
            !item.name &&
            item.type !== "detail" &&
            item.type !== "project-pricings" ? (
              <div className="actionsDiv">
                <OnHoverButtonIcon
                  className="actions"
                  iconClass={item.detailsAreOpen ? "fa fa-minus" : "fa fa-plus"}
                  buttonText={
                    item.detailsAreOpen ? "Hide Details" : "Show Details"
                  }
                  onClick={() => displayDetails(item, index)}
                />
              </div>
            ) : item.attributes &&
              item.attributes["service-type"] === "adjustments" &&
              insight_pricing_adjustments &&
              pricingPrivilege ? (
              <div className="actionsDiv">
                <FontAwesomeIcon
                  style={{ marginRight: "10px" }}
                  icon={faPencil}
                  onClick={() => {
                    setPrice(item.attributes["service-revenue"]);
                    setCost(item.attributes["service-cost"]);
                    const pricingAdjustment = allPricingAdjustments.find(
                      (adj) => adj.id == item.id.split("-")[2]
                    );
                    if (
                      pricingAdjustment.attributes["source-type"] === "Section"
                    ) {
                      setSelectedServiceCategory(
                        pricingAdjustment.attributes["source-id"]
                      );
                    }
                    if (item.attributes["billing-frequency"] !== "one_time") {
                      setSelectRepeats(item.attributes["billing-frequency"]);
                    }
                    setAdjustModal({
                      ...adjustModal,
                      show: true,
                      id: item.id.split("-")[2],
                      isRecurring:
                        item.attributes["billing-frequency"] !== "one_time",
                    });
                  }}
                />
                <FontAwesomeIcon
                  icon={faTrashCan}
                  onClick={() => {
                    deleteAdjustment(item.id.split("-")[2]);
                  }}
                />
              </div>
            ) : null,
        };
      }
    });
  };

  const displayDetails = (row, index) => {
    row.detailsAreOpen = !row.detailsAreOpen;
    let itemsToAdd;
    let services;
    let subservices;
    if (row.type === "professional") {
      itemsToAdd = professionalServices;
      services = professionalServiceList;
      subservices = professionalSubserviceList;
    }
    if (row.type === "amortize") {
      itemsToAdd = amortizedServices;
      services = amortizedServiceList;
      subservices = amortizedSubserviceList;
    }
    if (row.type === "managed") {
      itemsToAdd = managedServices;
      services = managedServiceList;
      subservices = managedSubserviceList;
    }
    if (row.type == "third-party") {
      itemsToAdd = thirdPartyServices.filter(
        (service) => service.attributes["billing-frequency"] == "one_time"
      );
    }
    if (row.type == "third-party-recurring") {
      itemsToAdd = thirdPartyServices.filter(
        (service) => service.attributes["billing-frequency"] !== "one_time"
      );
    }
    if (row.type == "product") {
      itemsToAdd = productPricing;
    }
    if (row.type === "oneTimeAdjustment") {
      itemsToAdd = oneTimeAdjustmentList;
    }
    if (row.type === "recurringAdjustment") {
      itemsToAdd = recurringAdjustmentList;
    }

    let orderedItemsToAdd = new Set();
    let orderedPricings = [];

    let serviceList = new Set();
    if (
      row.type == "professional" ||
      row.type == "managed" ||
      row.type == "amortize"
    ) {
      services.forEach((service) => {
        serviceList.add(service);
        subservices.forEach((subservice) => {
          let serviceRelData = subservice.relationships["project-service"].data;
          if (serviceRelData && serviceRelData.id == service.id) {
            serviceList.add(subservice);
          }
        });
        serviceList.add({ id: `total-${service.id}` });
      });

      serviceList = Array.from(serviceList);

      serviceList.forEach((service) => {
        itemsToAdd.forEach((item) => {
          if (
            item.id.split("-")[2] == service.id ||
            (service.id.includes("total") &&
              item.id.split("-")[2] == service.id.split("-")[1])
          ) {
            if (service.id.includes("total") == false) {
              item.serviceName = service.attributes.name;
            }
            orderedItemsToAdd.add(item);
          }
          if (service.id.includes("total")) {
            orderedItemsToAdd.add(service);
          }
        });
      });

      orderedItemsToAdd = Array.from(orderedItemsToAdd);

      let start = "";
      let end = "";
      orderedItemsToAdd.forEach((item, index) => {
        if (index == 0) {
          start = 0;
        }
        if (item.id.includes("total")) {
          end = index;

          let arrayToCalculate = orderedItemsToAdd
            .slice(start, end)
            .filter((item) => item.id.includes("total") == false);
          let pricingDetailRow = new PricingCalculator(
            "professional",
            0,
            arrayToCalculate,
            terms
          );
          pricingDetailRow.type = "detail";
          pricingDetailRow.id = item.id.split("-")[1];
          orderedPricings.push(pricingDetailRow);
          start = index;
        }
      });

      orderedPricings = orderedPricings.map((pricing) => {
        services.forEach((service) => {
          if (pricing.id == service.id) {
            pricing.serviceName = service.attributes.name;
            pricing.id = service.id;
            if (service.relationships.resource.data) {
              pricing.resourceId = service.relationships.resource.data.id;
            }
            if (service.relationships["project-location"].data) {
              pricing.projectLocationId =
                service.relationships["project-location"].data.id;
            }
            if (service.attributes["service-type"]) {
              pricing.serviceType = service.attributes["service-type"];
            }
          }
        });
        return pricing;
      });

      orderedPricings = orderedPricings.concat(
        itemsToAdd
          .filter(
            (item) =>
              item.id.toString().includes("project-services") == false &&
              item.id.toString().includes("project-subservices") == false
          )
          .map((item) => {
            if (item.id.startsWith("project-governances")) {
              let val = new PricingCalculator("detail", 0, [item], terms);
              val.serviceName = item.attributes.name;
              val.id = item.id;
              return val;
            } else {
              return item;
            }
          })
      );
    } else {
      orderedPricings = itemsToAdd;
    }

    if (row.detailsAreOpen) {
      arrayForRender.splice(index + 1, 0, ...orderedPricings);
      if (row.type === "professional") {
        setRowsToOpen({ ...rowsToOpen, professionalRow: "open" });
      }
      if (row.type === "managed") {
        setRowsToOpen({ ...rowsToOpen, managedRow: "open" });
      }
      if (row.type == "third-party") {
        setRowsToOpen({ ...rowsToOpen, thirdPartyRow: "open" });
      }
      if (row.type == "third-party-recurring") {
        setRowsToOpen({ ...rowsToOpen, recurringThirdPartyRow: "open" });
      }
      if (row.type == "product") {
        setRowsToOpen({ ...rowsToOpen, productRow: "open" });
      }
    }
    if (!row.detailsAreOpen) {
      arrayForRender.splice(index + 1, orderedPricings.length);
      if (row.type === "professional") {
        setRowsToOpen({ ...rowsToOpen, professionalRow: "closed" });
      }
      if (row.type === "managed") {
        setRowsToOpen({ ...rowsToOpen, managedRow: "closed" });
      }
      if (row.type == "third-party") {
        setRowsToOpen({ ...rowsToOpen, thirdPartyRow: "closed" });
      }
      if (row.type == "third-party-recurring") {
        setRowsToOpen({ ...rowsToOpen, recurringThirdPartyRow: "closed" });
      }
      if (row.type == "product") {
        setRowsToOpen({ ...rowsToOpen, productRow: "closed" });
      }
    }
    setForceUpdate(!forceUpdate);
    return arrayForRender;
  };

  const getFooterData = () => {
    let content = (
      <SeafoamButton type="submit" text="Save" onClick={(e) => handleSave(e)} />
    );
    return {
      rowClass: "col-12",
      columns: [{ class: "resourcesFooter", content: content }],
    };
  };

  const headerData = {
    rowClass: "row ",
    columns: [
      {
        class: "col-4",
        name: "Source",
      },
      {
        class: "text-right col-1",
        name: "Revenue",
      },
      {
        class: insight_pricing_adjustments ? "displayNone" : "text-right col-1",
        name: "Adjustment",
      },
      {
        class: insight_pricing_adjustments ? "displayNone" : "text-right col-1",
        name: "Net",
      },
      {
        class: "text-right col-1",
        name: "TCV",
      },
      {
        class: "text-right col-1",
        name: "Cost",
      },
      {
        class: insight_pricing_adjustments ? "text-right col-1" : "displayNone",
        name: "Total Cost",
      },
      {
        class: "text-right col-1",
        name: "Profit",
      },
      {
        class: "text-right col-1",
        name: "Margin",
      },
      {
        class: "text-right col-1",
        name: <span>&nbsp;&nbsp;&nbsp;</span>,
      },
    ],
  };

  const getTotalColumn = (a, b, c, d, e, f, g, h) => {
    a == "NaN" ? (a = 0) : a;
    b == "NaN" ? (b = 0) : b;
    c == "NaN" ? (c = 0) : c;
    d == "NaN" ? (d = 0) : d;
    e == "NaN" ? (e = 0) : e;
    f == "NaN" ? (f = 0) : f;
    g == "NaN" ? (g = 0) : g;
    h == "NaN" ? (h = 0) : h;
    return (
      parseFloat(a) +
      parseFloat(b) +
      parseFloat(c) +
      parseFloat(d) +
      parseFloat(e) +
      parseFloat(f) +
      parseFloat(g) +
      parseFloat(h)
    ).toFixed(2);
  };

  const arrangeDataForTable = () => {
    let array = [];

    let recurringThirdPartyServices = thirdPartyServices.filter(
      (service) => service.attributes["billing-frequency"] !== "one_time"
    );

    let oneTimeThirdPartyServices = thirdPartyServices.filter(
      (service) => service.attributes["billing-frequency"] == "one_time"
    );

    const professionalRow = new PricingCalculator(
      "professional",
      0,
      professionalServices,
      terms
    );

    const amortizeRow = new PricingCalculator(
      "amortize",
      0,
      amortizedServices,
      terms
    );

    const oneTimeThirdPartyRow = new PricingCalculator(
      "third-party",
      0,
      oneTimeThirdPartyServices,
      terms
    );

    const oneTimeAdjustmentRow = new PricingCalculator(
      "oneTimeAdjustment",
      0,
      oneTimeAdjustmentList,
      terms
    );

    const managedRow = new PricingCalculator(
      "managed",
      0,
      managedServices,
      terms
    );

    const recurringThirdPartyRow = new PricingCalculator(
      "third-party",
      0,
      recurringThirdPartyServices,
      terms
    );

    const recurringAdjustmentRow = new PricingCalculator(
      "recurringAdjustment",
      0,
      recurringAdjustmentList,
      terms
    );

    let productRevenue = productPricing
      .map((product) => parseFloat(product.attributes["hardware-price"]))
      .reduce((prev, curr) => prev + curr, 0);

    let productCost = productPricing
      .map((product) => parseFloat(product.attributes["hardware-cost"]))
      .reduce((prev, curr) => prev + curr, 0);

    let productProfit = productRevenue - productCost;

    const productRow = {
      type: "product",
      revenue: productRevenue,
      net: productRevenue,
      adjustment: 0,
      totalCost: productCost,
      tcv: productRevenue,
      cost: productCost,
      profit: productProfit,
      margin: parseFloat((productProfit / productRevenue) * 100).toFixed(2),
    };

    const totalRow = {
      type: "total",
      totalCost: "TOTAL",
      tcv: getTotalColumn(
        professionalRow.tcv,
        oneTimeThirdPartyRow.tcv,
        managedRow.tcv,
        recurringThirdPartyRow.tcv,
        productRow.tcv,
        amortizeRow.tcv,
        oneTimeAdjustmentRow.tcv,
        recurringAdjustmentRow.tcv
      ),
      cost: getTotalColumn(
        professionalRow.cost,
        oneTimeThirdPartyRow.cost,
        managedRow.cost,
        recurringThirdPartyRow.cost,
        productRow.cost,
        amortizeRow.cost,
        oneTimeAdjustmentRow.cost,
        recurringAdjustmentRow.cost
      ),
      profit: getTotalColumn(
        professionalRow.profit,
        oneTimeThirdPartyRow.profit,
        managedRow.profit,
        recurringThirdPartyRow.profit,
        productRow.profit,
        amortizeRow.profit,
        oneTimeAdjustmentRow.profit,
        recurringAdjustmentRow.profit
      ),
      margin: "",
    };

    totalRow.margin = parseFloat(
      (totalRow.profit / totalRow.tcv) * 100
    ).toFixed(2);

    const paymentCreditsRow = {
      type: "payment-credits-total",
      name: "Payment Credits",
      value: projectCredits
        .map((credit) => parseFloat(credit.attributes.amount))
        .reduce((prev, curr) => prev + curr, 0),
    };

    const customerPaymentsRow = {
      type: "customer-payments-total",
      name: "Customer Payments",
      value: totalRow.tcv - paymentCreditsRow.value,
    };

    recurringThirdPartyRow.type = "third-party-recurring";

    array.push({ name: "One Time Revenue" });
    array.push(professionalRow);
    array.push(oneTimeThirdPartyRow);
    array.push(oneTimeAdjustmentRow);
    array.push({ name: "Recurring Revenue" });
    if (amortizedServices.length !== 0) {
      array.push(amortizeRow);
    }
    array.push(managedRow);
    array.push(recurringThirdPartyRow);
    array.push(recurringAdjustmentRow);
    if (includeHardware) {
      array.push({ name: "Product" });
      array.push(productRow);
    }

    array.push(totalRow);

    if (projectCredits.length !== 0) {
      array.push(paymentCreditsRow);
      array.push(customerPaymentsRow);
    }

    setArrayForRender(array);
  };

  const tableData = getTableData(arrayForRender);
  const footerData = changedMargins.length !== 0 ? getFooterData() : null;

  let pricingTable = new ScopeStackTable(
    "services",
    headerData,
    tableData,
    footerData
  );

  const resetAllModalFields = () => {
    setPrice(0);
    setSelectRepeats("select");
    setCost(0);
    setSelectedServiceCategory("select");
  };

  const getServiceCategoryOptions = () => {
    const options = serviceCategories.map((category) => {
      return (
        <option key={category.id} value={category.id}>
          {category.attributes["nested-name"]
            ? category.attributes["nested-name"]
            : category.attributes.name}
        </option>
      );
    });
    options.unshift(
      <option key="select" value="select">
        Select An Option
      </option>
    );
    return options;
  };

  const modalBody = (
    <div>
      <FormNumberField
        label={"* Price"}
        value={price}
        onChange={(e) => {
          setPrice(e.target.value);
        }}
        step="0.01"
      />
      <div>
        {costPrivilege ? (
          <FormNumberField
            label="* Cost"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            step="0.01"
          />
        ) : null}
        {adjustModal.isRecurring ? (
          <ControlledDropDown
            label={"* Repeats"}
            value={selectRepeats}
            options={[
              <option key="select" value="select">
                Select An Option
              </option>,
              <option key="monthly" value="monthly">
                Monthly
              </option>,
              <option key="quarterly" value="quarterly">
                Quarterly
              </option>,
              <option key="yearly" value="yearly">
                Yearly
              </option>,
            ]}
            onChange={(e) => {
              setSelectRepeats(e.target.value);
            }}
          />
        ) : null}
        <ControlledDropDown
          label={"Service Category"}
          value={selectedServiceCategory}
          options={getServiceCategoryOptions()}
          onChange={(e) => {
            setSelectedServiceCategory(e.target.value);
          }}
        />
      </div>
    </div>
  );

  const deleteAdjustment = (deleteAdjustmentId) => {
    let userConfirms = confirm(
      "Are you sure you want to delete this adjustment?"
    );
    if (userConfirms) {
      let adjustment = {
        data: {
          type: "pricing-adjustments",
          id: deleteAdjustmentId,
        },
      };
      API.Delete(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/pricing-adjustments/${deleteAdjustmentId}`,
        adjustment,
        authorizationCode
      )
        .then((response) => {
          if (response.status == 204) {
            window.location.reload();
          }
        })
        .catch((err) => {
          setErrorMessages(
            formatUnprocessibleResponse(err, "pricing-adjustments")
          );
          setShowFailAlert(true);
        });
    }
  };

  const applyAdjustment = () => {
    let data = {
      data: {
        type: "pricing_adjustments",
        attributes: {
          price: price,
          cost: cost,
          "billing-frequency": adjustModal.isRecurring
            ? selectRepeats
            : "one_time",
          "source-type":
            selectedServiceCategory !== "select"
              ? "service-category"
              : "project",
          "source-id":
            selectedServiceCategory !== "select"
              ? selectedServiceCategory
              : project_id,
        },
        relationships: {
          project: {
            data: { type: "projects", id: project_id },
          },
        },
      },
    };
    if (adjustModal.id !== null) {
      data.data.id = adjustModal.id;
      API.Patch(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/pricing-adjustments/${adjustModal.id}`,
        data,
        authorizationCode
      )
        .then((res) => {
          if (res.status == 200) {
            setAdjustModal({ ...adjustModal, show: false, id: null });
            setSuccessMessage("Pricing adjustment successfully edited!");
            setShowSuccessAlert(true);
            window.location.reload();
          }
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "Revenue details"));
          setShowFailAlert(true);
        });
    } else {
      API.Post(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/pricing-adjustments`,
        data,
        authorizationCode
      )
        .then((res) => {
          if (res.status == 201) {
            setShowSuccessAlert(true);
            setAdjustModal({ ...adjustModal, show: false });
            window.location.reload();
          }
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "Revenue details"));
          setShowFailAlert(true);
        });
    }
  };

  return (
    <div>
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <ScopeStackModal
        modalTitle={
          adjustModal.isRecurring
            ? "Adjust Recurring Cost / Revenue"
            : "Adjust One Time Cost / Revenue"
        }
        modalBody={modalBody}
        button1Text="Cancel"
        handleButton1Click={() =>
          setAdjustModal({ show: false, isRecurring: false })
        }
        button2Text="Apply Adjustment"
        handleButton2Click={() => applyAdjustment()}
        show={adjustModal.show}
        button2Disabled={false}
      />
      <Card>
        <CardHeader title="Overall Pricing" />
        <Card.Body>
          <div className="flexCenter">
            <h2 style={{ width: "88%" }}>Revenue</h2>
            <Button
              onClick={() => setShowEditProjectMarginModal(true)}
              className="btnSeawhite"
            >
              Edit Project Margin
            </Button>
          </div>
          <BaseTable
            className="scopestack pricingTable"
            striped={true}
            hover={true}
            bordered={false}
            headerRows={pricingTable.buildHeaderRows()}
            dataRows={pricingTable.buildDataRows()}
            footerRows={pricingTable.buildFooterRows()}
            isLoading={isLoading}
          />
        </Card.Body>
        <ScopeStackModal
          modalTitle={"Edit Project Margin"}
          modalBody={
            <div>
              <FormNumberField
                label="New Project Margin (%)"
                placeholder={
                  "Enter the percent you want the new project margin to be"
                }
                step="0.01"
                value={projectMargin}
                onChange={(e) => setProjectMargin(e.target.value)}
              />
            </div>
          }
          button1Text="Cancel"
          handleButton1Click={() => {
            setShowEditProjectMarginModal(false);
            setProjectMargin(
              (project.attributes["contract-margin"] * 100).toFixed(2)
            );
          }}
          button2Text="Apply Margin"
          handleButton2Click={() => {
            const data = {
              data: {
                attributes: {
                  "contract-margin": Number(
                    (Number(projectMargin) / 100).toFixed(2)
                  ),
                },
              },
            };
            API.Put(
              `${apiHost}/${account_slug}/v1/projects/${project_id}/set_margin`,
              data,
              authorizationCode
            )
              .then((res) => {
                setProject(res.data.data);
                setShowEditProjectMarginModal(false);
                setSuccessMessage("Project margin updated!");
                setShowSuccessAlert(true);
                window.location.reload();
              })
              .catch((err) => {
                setErrorMessages(
                  formatUnprocessibleResponse(err, "Project margin")
                );
                setShowFailAlert(true);
              });
          }}
          show={showEditProjectMarginModal}
          button2Disabled={false}
        />
      </Card>
    </div>
  );
}

export default ProjectPricing;
