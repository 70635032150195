import {
  useListBlueprintsForAccountQuery,
  V1BlueprintItemResource,
  V1PhaseResource,
  V1ResourceResource,
  V1ServiceResource,
  V1SubserviceResource,
  V1ServiceCategoryResource,
  V1ProductResource,
} from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { Blueprint, Subservice } from "../../types";
import { Account } from "app/javascript/src/types";
import { camelizeHyphenCaseKeys } from "@utils/helpers";

const useListBlueprints = ({
  pageSize,
  currentPage,
  filterParams,
  sortBy,
  sortOrder,
}) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };
  let account: Account | null = null;

  const { data, error, isLoading, refetch, isFetching } =
    useListBlueprintsForAccountQuery({
      slug: accountSlug,
      include: [
        "blueprint-items",
        //@ts-ignore
        "blueprint-items.service",
        //@ts-ignore
        "blueprint-items.service.phase",
        //@ts-ignore
        "blueprint-items.service.resource",
        //@ts-ignore
        "blueprint-items.service.service-category",
        //@ts-ignore
        "blueprint-items.service.products",
        //@ts-ignore
        "blueprint-items.service.subservices",
        //@ts-ignore
        "blueprint-items.service.subservices.resource",
        "account",
      ],
      page: { number: currentPage, size: pageSize },
      filter: { ...filterParams },
      sort: sortBy ? [sortOrder == "asc" ? sortBy : `-${sortBy}`] : undefined,
    });

  let blueprints: Blueprint[] = [];

  if (data?.data) {
    try {
      blueprints = data.data.map((blueprint) => {
        if (!blueprint.id) {
          throw new Error("Missing blueprint.id");
        }
        if (!blueprint.attributes?.name) {
          throw new Error("Missing blueprint.attributes.name");
        }

        const services: Blueprint["services"] =
          (blueprint?.relationships?.["blueprint-items"]?.data || [])?.map(
            (blueprintItem) => {
              const item = data?.included?.find(
                (i) => i.type == "blueprint-items" && i.id === blueprintItem.id
              ) as V1BlueprintItemResource;

              const service = data?.included?.find(
                (i) =>
                  i.type === "services" &&
                  i.id === item?.relationships?.service?.data?.id
              ) as V1ServiceResource | undefined;

              if (!service?.id) return { id: "0" };

              const serviceType =
                service?.attributes?.["service-type"] ||
                "professional_services";

              const resource = data?.included?.find(
                (i) =>
                  i.type === "resources" &&
                  i.id === service?.relationships?.resource?.data?.id
              ) as V1ResourceResource;

              const phase = data?.included?.find(
                (i) =>
                  i.type === "phases" &&
                  i.id === service?.relationships?.phase?.data?.id
              ) as V1PhaseResource;

              //@ts-ignore
              const category = data?.included?.find(
                (i) =>
                  i.type === "service-categories" &&
                  i.id ===
                    service?.relationships?.["service-category"]?.data?.id
              ) as V1ServiceCategoryResource;

              const subservices = (
                service?.relationships?.subservices?.data || []
              )
                .map((sub) => {
                  const s = data?.included?.find(
                    (i) => i.type === "subservices" && i.id === sub.id
                  ) as V1SubserviceResource | undefined;

                  if (!s?.attributes?.active) {
                    return;
                  }

                  const subResourceId = s?.relationships?.resource?.data?.id;
                  const subResource = subResourceId
                    ? (data?.included?.find(
                        (i) => i.type === "resources" && i.id === subResourceId
                      ) as V1ResourceResource)
                    : undefined;
                  return {
                    id: String(s?.id),
                    type: "subservices" as "subservices",
                    serviceType,
                    name: s?.attributes?.name || "",
                    qty: s?.attributes?.quantity
                      ? String(s.attributes.quantity)
                      : "",
                    hours: s?.attributes?.["suggested-hours"]
                      ? //@ts-ignore
                        parseFloat(s.attributes["suggested-hours"]).toFixed(1)
                      : "",
                    resource: subResource?.id
                      ? {
                          id: `resources-${subResource.id}`,
                          name: subResource?.attributes?.name || "",
                        }
                      : resource?.id
                      ? {
                          id: `resources-${resource.id}`,
                          name: resource?.attributes?.name || "",
                        }
                      : undefined,
                    serviceId: String(service.id),
                  };
                })
                .filter((s) => s) as Subservice[];

              const products = (
                service?.relationships?.products?.data || []
              ).map((product) => {
                //@ts-ignore
                const p = data?.included?.find(
                  (i) => i.type === "products" && i.id === product.id
                ) as V1ProductResource;
                return {
                  id: Number(p?.id) || 0,
                  name: p?.attributes?.name || "",
                  mpn: p?.attributes?.["manufacturer-part-number"] || "",
                  price: p?.attributes?.["unit-price"]
                    ? //@ts-ignore
                      parseFloat(p.attributes["unit-price"]).toFixed(1)
                    : "",
                };
              });

              return {
                id: String(service.id),
                type: "services",
                serviceType,
                name: service?.attributes?.name || "",
                description: service?.attributes?.["service-description"] || "",
                category:
                  category?.attributes?.["nested-name"] ||
                  category?.attributes?.name,
                qty: service?.attributes?.["minimum-quantity"]
                  ? String(service?.attributes?.["minimum-quantity"])
                  : "",
                hours: service?.attributes?.["total-hours"]
                  ? //@ts-ignore
                    parseFloat(service.attributes["total-hours"]).toFixed(1)
                  : "",
                resource: resource?.id
                  ? {
                      id: `resources-${resource.id}`,
                      name: resource?.attributes?.name || "",
                    }
                  : undefined,
                phase:
                  serviceType === "professional_services"
                    ? {
                        id: phase?.id || -1,
                        name: phase?.attributes?.name || "",
                        position: phase?.attributes?.position || 999999,
                      }
                    : { id: -1, name: "Managed Services", position: 999999 },
                subservices,
                products,
              };
            }
          ) || [];

        //Organize services by phase position
        const phases = Array.from(
          new Map(
            services
              ?.map((item) => item.phase)
              .map((phase) => [phase?.id, phase])
          ).values()
        ).sort((a, b) => {
          if (a?.id === -1) return 1; // Move phase with id -1 to the end
          if (b?.id === -1) return -1; // Move phase with id -1 to the end
          return a?.position! - b?.position! || 0; // Maintain phase order by position
        });

        const organizedServices = phases
          .map((p) => services?.filter((s) => s?.phase?.id == p?.id) || [])
          .flat();

        return {
          id: String(blueprint.id),
          name: blueprint.attributes?.name || "",
          services: organizedServices,
          tags: blueprint?.attributes?.["tag-list"] || [],
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };

      const acc = data?.included?.find((item) => item.type === "accounts");

      account = acc ? camelizeHyphenCaseKeys(acc) : acc;
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    blueprints,
    account,
    isLoading,
    error: error ?? customError,
    pageMeta,
    refetch,
    isFetching,
  };
};
export default useListBlueprints;
