import SlideOutMenuV2 from "@components/SlideOutMenuV2";
import {
  Service,
  Subservice,
  Product,
} from "../../../pages/Projects/ServicesV2/types";
import DataTable from "@components/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import "./style.css";

interface ServicePreviewProps {
  service: Service;
  slideoutOpen: boolean;
  setSlideoutOpen: (bool) => void;
  isManagedService?: boolean;
  title?: string;
}

const ServicePreview = ({
  service,
  slideoutOpen,
  setSlideoutOpen,
  isManagedService = false,
  title = "Preview Service",
}: ServicePreviewProps) => {
  const subserviceColumns: ColumnDef<Subservice>[] = [
    {
      header: "Subservice Name",
      cell(props) {
        return props.row.original.name || "";
      },
    },
    {
      header: "Qty",
      cell(props) {
        return (
          <div className="alignCenter">{props.row.original.qty || ""}</div>
        );
      },
    },
    {
      header: "Hours",
      cell(props) {
        return (
          <div className="alignRight">{props.row.original.hours || ""}</div>
        );
      },
    },
  ];

  const productColumns: ColumnDef<Product>[] = [
    {
      header: "Product Name",
      cell(props) {
        return props.row.original.name || "";
      },
    },
    {
      header: "MFR PART",
      cell(props) {
        return props.row.original.mpn || "";
      },
    },
    {
      header: "Unit Price",
      cell(props) {
        return (
          <div className="alignRight">{props.row.original.price || ""}</div>
        );
      },
    },
  ];
  return (
    <SlideOutMenuV2
      title={title}
      isOpen={slideoutOpen}
      onClose={() => setSlideoutOpen(false)}
    >
      <div className="servicePreviewSlideout">
        <>
          <div className="previewSubheader">About {service.name}</div>
          <p className="serviceDescription">
            {service.description || <i>No service description</i>}
          </p>
        </>
        <>
          <div className="previewSubheader">Service Overview</div>
          <div className="overview">
            <div>
              <div>Service Name:</div>
              <div>Service Category:</div>
              {!isManagedService && <div>Phase:</div>}
              <div>Hours:</div>
            </div>
            <div className="overviewValues">
              <div>{service.name || "\u00A0"}</div>
              <div>{service.category || "\u00A0"}</div>
              {!isManagedService && (
                <div>{service?.phase?.name || "\u00A0"}</div>
              )}
              <div>{service.hours || "\u00A0"}</div>
            </div>
          </div>
        </>
        <>
          <div className="previewSubheader">Subservices Included</div>
          <DataTable
            data={
              service?.subservices?.length
                ? service.subservices
                : [{ id: "0", name: "No subservices" }]
            }
            columns={subserviceColumns}
            striped
            bordered
          />
        </>
        <>
          <div className="previewSubheader">Products Included</div>
          <DataTable
            data={
              service?.products?.length
                ? service.products
                : [{ id: 0, name: "No products" }]
            }
            columns={productColumns}
            striped
            bordered
          />
        </>
      </div>
    </SlideOutMenuV2>
  );
};

export default ServicePreview;
