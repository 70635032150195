import EditableDataTable from "@components/EditableDataTable";
import React, { useEffect, useState } from "react";
import { ProjectResource } from "./types/projectResource";
import { Column } from "@components/EditableDataTable/types/column";
import { Card, Button, Form } from "react-bootstrap";
import { handleAlert, renderResourceName } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { renderLobOptions } from "../GovernanceV2/Show/api/governanceHelpers";
import "./styles/style.css";
import Select from "react-select";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import {
  useCreateProjectResourceMutation,
  useDeleteProjectResourceMutation,
  useUpdateProjectResourceMutation,
} from "@generated";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import { faCheck, faX } from "@fortawesome/pro-solid-svg-icons";

function ResourcesV2({
  account_slug,
  project,
  apiProjectResources,
  lobs,
  user,
  resources,
  resourceRates,
  useResourceRates,
}) {
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedIds, setSelectedIds] = useState<string[] | []>([]);
  const [projectResourcePermission, setProjectResourcePermission] =
    useState("none");
  const [projectResourceCostPermission, setProjectResourceCostPermission] =
    useState("none");
  const [projectResources, setProjectResources] = useState(apiProjectResources);
  const [originalProjectResources, setOriginalProjectResources] =
    useState(apiProjectResources);

  // Bulk Delete States
  const [deleteProjectResource] = useDeleteProjectResourceMutation();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteQueue, setDeleteQueue] = useState<string[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const [createProjectResource] = useCreateProjectResourceMutation();
  const [updateProjectResource] = useUpdateProjectResourceMutation();

  useEffect(() => {
    const privileges = user?.attributes?.privileges || [];
    const projectResourcePermission = privileges?.find(
      (privilege) => privilege.privilege === "projects.resource_rates"
    )?.["accessLevel"];
    const projectResourceCostPermission = privileges?.find(
      (privilege) => privilege.privilege === "projects.resource_costs"
    )?.["accessLevel"];
    setProjectResourcePermission(projectResourcePermission || "none");
    setProjectResourceCostPermission(projectResourceCostPermission || "none");
  }, [user]);

  const renderResourceOptions = () => {
    const resourcesInDropdown = useResourceRates ? resourceRates : resources;
    let options = resourcesInDropdown
      .map((resource) => {
        let id = resource.id;
        let resourceOption = renderResourceName(resource, lobs);
        return { value: id, label: resourceOption };
      })
      .sort((a, b) => {
        return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
      });
    options.unshift({ value: "0", label: "Pick Resource" });
    return options;
  };

  const addResource = () => {
    let resource = {
      type: "project-resources",
      id: `new-${Math.floor(Math.random() * 100)}`,
      name: "",
      externalName: "",
      description: "",
      hourlyRate: 0,
      hourlyCost: 0,
      lob: {
        label: "",
        value: 0,
      },
      travelAndExpenseRate: 0,
      editing: true,
      resourceId: "0",
    };

    setProjectResources([...projectResources, resource]);
    setOriginalProjectResources([...originalProjectResources, resource]);
  };

  const confirmResourceEdit = (resource) => {
    let data = {
      type: "project-resources",
      attributes: {
        "external-name": resource.externalName,
        description: resource.description,
        "hourly-rate": Number(resource.hourlyRate),
        "hourly-cost": Number(resource.hourlyCost),
        "expense-rate": Number(resource.travelAndExpenseRate),
      },
      relationships: {
        project: { data: { id: Number(project.id), type: "projects" } },
        "line-of-business": {
          data: {
            id: Number(resource?.lob?.value) || null,
            type: "line-of-businesses",
          },
        },
        resource: {
          data: {
            type: "resources",
            id: resource.resourceId,
          },
        },
      },
    };
    if (resource.id.includes("new")) {
      if (resource.resourceId === "0") {
        setErrorMessage(
          "You must select a resource from the dropdown to save this project resource!"
        );
        setShowFailAlert(true);
        return;
      }
      createProjectResource({
        slug: account_slug,
        // @ts-ignore
        body: { data: data },
      })
        .unwrap()
        .then((res) => {
          setSuccessMessage("Project resource created!");
          setShowSuccessAlert(true);
          setProjectResources(
            projectResources.map((projResource) => {
              if (projResource.id === resource.id) {
                projResource = {
                  ...projResource,
                  id: res?.data?.id,
                  editing: false,
                };
              }
              return projResource;
            })
          );
          setOriginalProjectResources(
            originalProjectResources
              .map((projResource) => {
                if (projResource.id === resource.id) {
                  projResource = {
                    ...projectResources.find(
                      (item) => item.id == projResource.id
                    ),
                    editing: false,
                    id: res?.data?.id,
                  };
                }
                return projResource;
              })
              .concat(
                projectResources.filter((item) => item.id.includes("new"))
              )
          );
        })
        .catch((error) => {
          setErrorMessage(
            "Project resource could not be created at this time. Please try again later."
          );
          setShowFailAlert(true);
        });
    } else {
      updateProjectResource({
        slug: account_slug,
        // @ts-ignore
        body: { data: { ...data, id: resource.id } },
        id: Number(resource.id),
      })
        .unwrap()
        .then((res) => {
          setSuccessMessage("Project resource updated!");
          setShowSuccessAlert(true);
        })
        .catch((error) => {
          setErrorMessage(
            "Project resource could not be updated at this time. Please try again later."
          );
          setShowFailAlert(true);
        });
    }
  };

  const cancelResourceEdit = (resource) => {
    if (resource.id.includes("new")) {
      setProjectResources(
        projectResources.filter(
          (projResource) => projResource.id !== resource.id
        )
      );
    } else {
      setProjectResources(originalProjectResources);
    }
  };

  const getProjectResourceColumns = () => {
    let projectResourceColumns: Column[] = [
      {
        header() {
          return "Resource name";
        },
        cell(resource: ProjectResource) {
          return resource.id === "noRow" ? (
            resource.name
          ) : !resource.id.includes("new") ? (
            <Form.Control
              type="text"
              disabled={true}
              defaultValue={resource.name || ""}
            />
          ) : (
            <Select
              className="basic-single resourceDropdown"
              options={renderResourceOptions()}
              placeholder="Select Resource"
              value={{ label: resource.name, value: resource.id }}
              onChange={(e) => {
                if (useResourceRates) {
                  if (e) {
                    const resourceRate = resourceRates.find(
                      (resRate) => resRate.id == e?.value
                    );
                    const foundLob = lobs?.find(
                      (lob) =>
                        lob.id ==
                        resourceRate?.relationships?.["line-of-business"]?.data
                          ?.id
                    );
                    setProjectResources(
                      projectResources.map((projResource) => {
                        if (resource.id === projResource.id) {
                          projResource = {
                            ...projResource,
                            resourceId:
                              resourceRate?.relationships?.resource?.data?.id,
                            name: e?.label,
                            externalName:
                              resourceRate?.attributes?.["external-name"] || "",
                            hourlyRate:
                              resourceRate?.attributes?.["hourly-rate"] || "",
                            hourlyCost:
                              resourceRate?.attributes?.["hourly-cost"] || "",
                            travelAndExpenseRate:
                              resourceRate?.attributes?.["expense-rate"] ||
                              "0.0",
                            description:
                              resourceRate?.attributes?.description || "",
                            lob: {
                              label: foundLob?.attributes?.["name"] || "",
                              value: foundLob?.id || 0,
                            },
                          };
                        }
                        return projResource;
                      })
                    );
                  }
                } else {
                  setProjectResources(
                    projectResources.map((projResource) => {
                      if (resource.id === projResource.id) {
                        projResource = {
                          ...projResource,
                          resourceId: e?.value,
                          name: e?.label,
                        };
                      }
                      return projResource;
                    })
                  );
                }
              }}
            />
          );
        },
      },
      {
        header() {
          return "External name";
        },
        cell(resource: ProjectResource) {
          return resource.id !== "noRow" ? (
            <Form.Control
              type="text"
              value={resource.externalName || ""}
              disabled={
                project?.attributes?.["status"] !== "building" ||
                projectResourcePermission !== "manage"
              }
              onChange={(e) => {
                setProjectResources(
                  projectResources.map((projResource) => {
                    if (resource.id === projResource.id) {
                      projResource = {
                        ...projResource,
                        editing: true,
                        externalName: e.target.value,
                      };
                    }
                    return projResource;
                  })
                );
              }}
              onBlur={(e) => {
                const originalProjRes = originalProjectResources.find(
                  (originalProjectResource) =>
                    originalProjectResource.id == resource.id
                );
                if (
                  !resource.id.includes("new") &&
                  e?.target?.value !== originalProjRes?.externalName
                ) {
                  confirmResourceEdit(resource);
                }
              }}
            />
          ) : (
            ""
          );
        },
      },
      {
        header() {
          return "Description";
        },
        cell(resource: ProjectResource) {
          return resource.id !== "noRow" ? (
            <Form.Control
              type="text"
              value={resource.description || ""}
              disabled={
                project?.attributes?.["status"] !== "building" ||
                projectResourcePermission !== "manage"
              }
              onChange={(e) => {
                setProjectResources(
                  projectResources.map((projResource) => {
                    if (resource.id === projResource.id) {
                      projResource = {
                        ...projResource,
                        editing: true,
                        description: e.target.value,
                      };
                    }
                    return projResource;
                  })
                );
              }}
              onBlur={(e) => {
                const originalProjRes = originalProjectResources.find(
                  (originalProjectResource) =>
                    originalProjectResource.id == resource.id
                );
                if (
                  !resource.id.includes("new") &&
                  e?.target?.value !== originalProjRes?.description
                ) {
                  confirmResourceEdit(resource);
                }
              }}
            />
          ) : (
            ""
          );
        },
      },
      {
        header() {
          return "LOB/Category";
        },
        cell(resource: ProjectResource) {
          return resource.id !== "noRow" ? (
            <Select
              className="basic-single lobDropdown"
              options={renderLobOptions(lobs)}
              isDisabled={
                project?.attributes?.["status"] !== "building" ||
                projectResourcePermission !== "manage"
              }
              placeholder="Select Lob/Category"
              value={resource.lob || { label: "", value: "0" }}
              onChange={(e) => {
                if (
                  !resource.id.includes("new") &&
                  e?.value !== resource?.lob?.value
                ) {
                  confirmResourceEdit({ ...resource, lob: e });
                }
                setProjectResources(
                  projectResources.map((projResource) => {
                    if (resource.id === projResource.id) {
                      projResource = {
                        ...projResource,
                        editing: true,
                        lob: e,
                      };
                    }
                    return projResource;
                  })
                );
              }}
            />
          ) : (
            ""
          );
        },
      },
      {
        header() {
          return "Hourly Rate";
        },
        cell(resource: ProjectResource) {
          return resource.id !== "noRow" ? (
            <Form.Control
              type="number"
              value={resource.hourlyRate?.toString() || ""}
              disabled={
                project?.attributes?.["status"] !== "building" ||
                projectResourcePermission !== "manage"
              }
              onChange={(e) => {
                setProjectResources(
                  projectResources.map((projResource) => {
                    if (resource.id === projResource.id) {
                      projResource = {
                        ...projResource,
                        editing: true,
                        hourlyRate: e.target.value,
                      };
                    }
                    return projResource;
                  })
                );
              }}
              onBlur={(e) => {
                const originalProjRes = originalProjectResources.find(
                  (originalProjectResource) =>
                    originalProjectResource.id == resource.id
                );
                if (
                  !resource.id.includes("new") &&
                  e?.target?.value !== originalProjRes?.hourlyRate
                ) {
                  confirmResourceEdit(resource);
                }
              }}
            />
          ) : (
            ""
          );
        },
        cellClass: "text-right",
      },
      {
        header() {
          return "Travel & Expense Rate";
        },
        cell(resource: ProjectResource) {
          return resource.id === "noRow" ? (
            ""
          ) : resource.editing && resource.id.includes("new") ? (
            <>
              <Form.Control
                type="number"
                value={resource.travelAndExpenseRate}
                disabled={
                  project?.attributes?.["status"] !== "building" ||
                  projectResourceCostPermission !== "manage"
                }
                onChange={(e) => {
                  setProjectResources(
                    projectResources.map((projResource) => {
                      if (resource.id === projResource.id) {
                        projResource = {
                          ...projResource,
                          editing: true,
                          travelAndExpenseRate: e.target.value,
                        };
                      }
                      return projResource;
                    })
                  );
                }}
              />
              <div className="saveCancel">
                <FontAwesomeIcon
                  icon={faCheck}
                  onClick={() => confirmResourceEdit(resource)}
                />
                <FontAwesomeIcon
                  onClick={() => cancelResourceEdit(resource)}
                  icon={faX}
                />
              </div>
            </>
          ) : (
            <Form.Control
              type="number"
              value={resource.travelAndExpenseRate?.toString() || ""}
              disabled={
                project?.attributes?.["status"] !== "building" ||
                projectResourceCostPermission !== "manage"
              }
              onChange={(e) => {
                setProjectResources(
                  projectResources.map((projResource) => {
                    if (resource.id === projResource.id) {
                      projResource = {
                        ...projResource,
                        editing: true,
                        travelAndExpenseRate: e.target.value,
                      };
                    }
                    return projResource;
                  })
                );
              }}
              onBlur={(e) => {
                const originalProjRes = originalProjectResources.find(
                  (originalProjectResource) =>
                    originalProjectResource.id == resource.id
                );
                if (
                  !resource.id.includes("new") &&
                  e?.target?.value !== originalProjRes?.travelAndExpenseRate
                ) {
                  confirmResourceEdit(resource);
                }
              }}
            />
          );
        },
        cellClass: "text-right",
      },
    ];
    if (projectResourceCostPermission !== "none") {
      projectResourceColumns.splice(projectResourceColumns.length - 1, 0, {
        header() {
          return "Hourly Cost";
        },
        cell(resource: ProjectResource) {
          return resource.id !== "noRow" ? (
            <Form.Control
              type="number"
              value={resource.hourlyCost?.toString() || ""}
              disabled={
                project?.attributes?.["status"] !== "building" ||
                projectResourceCostPermission !== "manage"
              }
              onChange={(e) => {
                setProjectResources(
                  projectResources.map((projResource) => {
                    if (resource.id === projResource.id) {
                      projResource = {
                        ...projResource,
                        editing: true,
                        hourlyCost: e.target.value,
                      };
                    }
                    return projResource;
                  })
                );
              }}
              onBlur={(e) => {
                const originalProjRes = originalProjectResources.find(
                  (originalProjectResource) =>
                    originalProjectResource.id == resource.id
                );
                if (
                  !resource.id.includes("new") &&
                  e?.target?.value !== originalProjRes?.hourlyCost
                ) {
                  confirmResourceEdit(resource);
                }
              }}
            />
          ) : (
            ""
          );
        },
        cellClass: "text-right",
      });
    }
    return projectResourceColumns;
  };

  if (projectResourcePermission === "none") {
    return (
      <div>
        You do not have permmission to view this page. Please contact your
        administrator for assistance.
      </div>
    );
  }

  const handleDelete = () => {
    setDeleteQueue([...selectedIds]);
    setShowConfirmationModal(true);
  };

  const confirmDelete = () => {
    setIsDeleting(true);
    if (deleteQueue.length === 0) return;

    const promises: any = [];
    deleteQueue.forEach((resourceIdToDelete) => {
      promises.push(
        deleteProjectResource({
          slug: account_slug,
          id: parseInt(resourceIdToDelete),
        })
      );
    });

    Promise.all(promises)
      .then((res) => {
        setSuccessMessage(
          `Project Resource${
            deleteQueue.length > 1 ? "s" : ""
          } deleted successfully!`
        );
        setShowSuccessAlert(true);
        setIsDeleting(false);
        setShowConfirmationModal(false);
        setSelectedIds([]);
        setDeleteQueue([]);
        setProjectResources(
          projectResources.filter(
            (projResource) => !deleteQueue.includes(projResource.id)
          )
        );
        setOriginalProjectResources(
          originalProjectResources.filter(
            (projResource) => !deleteQueue.includes(projResource.id)
          )
        );
      })
      .catch((error) => {
        setErrorMessage(
          "One or more resources could not be deleted at this time. Please try again later."
        );
        setShowFailAlert(true);
      });
  };

  const handleConfirm = () => {
    confirmDelete();
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
    setDeleteQueue([]);
  };

  const getConfirmationMessage = () => {
    return (
      <>
        <div
          style={{
            color: "#1C2655",
            fontSize: "14px",
          }}
        >
          {isDeleting ? (
            <SmallSpinner />
          ) : (
            <span style={{ fontWeight: "700" }}>{`You are about to delete ${
              selectedIds.length
            } resource${selectedIds.length > 1 ? "s" : ""}.`}</span>
          )}
          <ul style={{ marginTop: "10px" }}>
            {selectedIds.map((resourceIdToDelete) => {
              let resourceName = projectResources.find(
                (resource) => resource.id == resourceIdToDelete
              )?.name;
              return <li>{resourceName}</li>;
            })}
          </ul>
          This action cannot be undone. Do you wish to proceed?
        </div>
      </>
    );
  };

  return (
    <div className="resourcesV2Page">
      <Card className="whiteBg details">
        {handleAlert(
          showSuccessAlert,
          successMessage,
          setShowSuccessAlert,
          "success",
          ToastAlert
        )}
        {handleAlert(
          showFailAlert,
          errorMessage,
          setShowFailAlert,
          "warning",
          ToastAlert
        )}
        <Card.Body>
          <Card.Header className="cardHeaderOverride">
            <div className="headerContainer">
              <div className="actionBtns">
                <h2>
                  <span className="text24">Resources</span>
                </h2>
              </div>
              <div className="actionBtns">
                {projectResourcePermission &&
                  projectResourcePermission !== "view" &&
                  project?.attributes?.["status"] === "building" && (
                    <Button
                      className="btnSeafoam"
                      type="button"
                      onClick={() => {
                        addResource();
                      }}
                      disabled={false}
                    >
                      Add Resource
                    </Button>
                  )}
              </div>
            </div>
          </Card.Header>

          {selectedIds.length > 0 ? (
            <Button className="deleteBox" onClick={handleDelete}>
              {selectedIds.length} Selected{" "}
              <span className="selectedSpan"></span>
              <FontAwesomeIcon style={{ color: "black" }} icon={faTrashCan} />
            </Button>
          ) : (
            <div className="multiDeletePlaceholder"></div>
          )}
          <EditableDataTable
            config={{
              striped: true,
              stripeColor: "#F2F2F2",
              hover: true,
              bordered: false,
              multiselect:
                projectResources?.length > 0 &&
                projectResourcePermission === "manage" &&
                project?.attributes?.status === "building",
              classForTable: "vendorQuotesV2Table",
            }}
            records={
              projectResources?.length > 0
                ? projectResources
                : [
                    {
                      id: "noRow",
                      name: "No project resources found",
                    },
                  ]
            }
            columns={getProjectResourceColumns()}
            selectedIds={selectedIds}
            setSelectedIds={(ids) => setSelectedIds(ids)}
          />
        </Card.Body>
        <ScopeStackModal
          modalTitle="Delete Expense"
          modalBody={getConfirmationMessage()}
          handleButton2Click={isDeleting ? () => null : handleConfirm}
          handleClose={handleCancel}
          show={showConfirmationModal}
          handleButton1Click={handleCancel}
          deleteModal={true}
          button1Text={"Cancel"}
          button2Text={"Delete"}
        />
      </Card>
    </div>
  );
}

export default ResourcesV2;
