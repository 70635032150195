import { useState } from "react";
import "../style/style";
import useListBlueprints from "./api/useListBlueprints";
import DataTable from "@components/DataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faSortDown,
  faSortUp,
  faSort,
} from "@fortawesome/pro-solid-svg-icons";
import { Button, Form } from "react-bootstrap";
import SearchField from "@components/Forms/SearchField/SearchField";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { useLocation } from "react-router";
import { Blueprint, Service } from "../types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BlueprintPreview } from "@components/PreviewSlideouts";
import { ColumnDef } from "@tanstack/react-table";
import {
  useListResourceRates,
  useListProjectResources,
  useListResources,
} from "../api";
import { renderResourceOptions } from "@utils/helperFunctions";
import { useGetProjectByIdQuery } from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import Select from "react-select";
import { ServicesTable } from "../../../Services/ServicesTable/ServicesTable";
import { ServicePreview } from "@components/PreviewSlideouts";
import useOauth from "@utils/customHooks/useOauth";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { handleCreateProjectServices, formatResource } from "../common/helpers";
import { getOrganizedPhasesFromListOfServices } from "@utils/helpers";

const Blueprints = ({ project_id }) => {
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [authorizationCode] = useOauth();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serviceType =
    queryParams.get("service_type") === "managed_services"
      ? "managed_services"
      : "professional_services";

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterParams, setFilterParams] = useState<{
    name?: string;
  }>({ name: undefined });
  const [searchInput, setSearchInput] = useState<string>("");
  const [blueprintToPreview, setBlueprintToPreview] = useState<Blueprint>({
    id: "0",
    name: "",
  });
  const [serviceToPreview, setServiceToPreview] = useState<Service>({
    id: "0",
    name: "",
  });

  const [selectedBlueprint, setSelectedBlueprint] =
    useState<Blueprint | undefined>(undefined);
  const [serviceSlideoutOpen, setServiceSlideoutOpen] =
    useState<boolean>(false);
  const [blueprintSlideoutOpen, setBlueprintSlideoutOpen] =
    useState<boolean>(false);

  //step 1 = select standards, step 2 = review & apply
  const [step, setStep] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState(
    "Something went wrong saving project services"
  );
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [updating, setUpdating] = useState(false);

  const { data: project, isLoading: projectLoading } = useGetProjectByIdQuery({
    slug: accountSlug,
    id: Number(project_id),
    include: ["rate-table"],
  });

  const { blueprints, isLoading, isFetching, pageMeta, account } =
    useListBlueprints({
      filterParams,
      pageSize,
      currentPage,
      sortBy,
      sortOrder,
    });

  const rateTableId = project?.data?.relationships?.["rate-table"]?.data?.id;
  const resourceRatesEnabled =
    account?.attributes?.settings?.project_resource_rates;

  const { resourceRates, resourceRatesLoading } =
    useListResourceRates(rateTableId);

  const { resources, resourcesLoading } = useListResources();

  const { projectResources, projectResourcesLoading } =
    useListProjectResources(project_id);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchName();
  };

  const searchName = () => {
    setFilterParams({ name: searchInput });
  };

  const handleSort = (columnId: string) => {
    if (sortBy === columnId) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnId);
      setSortOrder("asc");
    }
  };

  const renderSortIcon = (columnId: string) => {
    if (sortBy === columnId) {
      return sortOrder === "asc" ? (
        <FontAwesomeIcon icon={faSortUp} />
      ) : (
        <FontAwesomeIcon icon={faSortDown} />
      );
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const selectColumns: ColumnDef<Blueprint>[] = [
    {
      id: "name",
      header: () => (
        <div className="displayFlex">
          Blueprint Name
          <div className="sortIcon" onClick={() => handleSort("name")}>
            {renderSortIcon("name")}
          </div>
        </div>
      ),
      cell(props) {
        const item = props?.row?.original;
        return item?.id == "0" ? (
          "No blueprints found"
        ) : (
          <div
            className={item?.id == "0" ? "" : "projectBlueprintName"}
            onClick={(e) => {
              setStep(2);
              setSelectedBlueprint(item);
            }}
          >
            {item?.name}
          </div>
        );
      },
    },

    {
      id: "services in blueprint",
      header: () => "Services In Blueprint",
      cell(props) {
        const item = props?.row?.original;
        return item?.id === "0" ? (
          ""
        ) : (
          <div className="servicesInBlueprint">
            {(item.services || [])
              .map((service) => service.name)
              .filter((service, index) => index < 2)
              .join(", ")}
            <div className="previewBlueprint">
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => (
                  <Tooltip id="button-tooltip" {...props}>
                    View all services
                  </Tooltip>
                )}
              >
                <FontAwesomeIcon
                  icon={faEye}
                  onClick={() => {
                    setBlueprintToPreview(item);
                    setBlueprintSlideoutOpen(true);
                  }}
                />
              </OverlayTrigger>
            </div>
          </div>
        );
      },
    },
    {
      id: "tags",
      header: () => <div>Tags</div>,
      cell(props) {
        const item = props?.row?.original;
        return (
          <div className="display flex">
            {item?.tags?.map((t, i) => (
              <div key={i} className="blueprintTag">
                {t}
              </div>
            ))}
          </div>
        );
      },
    },
  ];

  const reviewColumns = [
    {
      id: "service name",
      header: () => "Service Name",
      cell(item) {
        return item.id !== "noRow" ? (
          <div className="serviceName">
            <Form.Control type="text" value={item.name} disabled={true} />
            {item.type === "services" && (
              <div className="previewService review">
                <FontAwesomeIcon
                  icon={faEye}
                  onClick={() => {
                    setServiceToPreview(item);
                    setServiceSlideoutOpen(true);
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          item.name
        );
      },
    },
    {
      id: "total hours",
      header: () => "Total Hours",
      cell(item) {
        return (
          item.id !== "noRow" && (
            <Form.Control type="text" value={item.hours} disabled={true} />
          )
        );
      },
    },
    {
      id: "qty",
      header: () => "QTY",
      cell(item) {
        return (
          item.id !== "noRow" && (
            <Form.Control
              type="number"
              min="0"
              step="1"
              defaultValue={item.qty || 1}
              onBlur={(e) => {
                item = {
                  ...item,
                  qty: e.target.value,
                };
                const updated = [...(selectedBlueprint?.services || [])].map(
                  (s) => {
                    if (item.type === "subservices") {
                      return {
                        ...s,
                        subservices:
                          s.subservices?.map((sub) =>
                            sub.id === item.id
                              ? { ...item, isChanged: true }
                              : sub
                          ) || [],
                      };
                    }

                    return s.id === item.id ? item : s;
                  }
                );

                setSelectedBlueprint({
                  ...selectedBlueprint,
                  services: updated,
                } as Blueprint);
              }}
              onChange={(e) => {
                e.stopPropagation();
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          )
        );
      },
    },
    {
      id: "resource",
      header: () => "Resource",
      cell(item) {
        const service =
          item?.type === "subservices"
            ? selectedBlueprint?.services?.find((s) => s.id === item?.serviceId)
            : undefined;

        //Use the defined resource if it exists, otherwise, if it is a subservice, default to service resource
        const resource = item?.resource
          ? {
              label: item.resource.name,
              value: item.resource.id,
            }
          : item.type === "subservices" && service?.resource
          ? {
              label: service.resource.name,
              value: service.resource.id,
            }
          : undefined;

        return (
          item?.id !== "noRow" &&
          item?.serviceType == "professional_services" && (
            <Select
              className="resourceDropdown"
              value={resource}
              options={renderResourceOptions(
                projectResources,
                resourceRatesEnabled ? resourceRates : resources,
                []
              )}
              onChange={(e) => {
                if (e) {
                  item = {
                    ...item,
                    resource: { id: e.value, name: e.label },
                  };
                  const updated = [...(selectedBlueprint?.services || [])].map(
                    (s) => {
                      if (item.type === "subservices") {
                        return {
                          ...s,
                          subservices:
                            s.subservices?.map((sub) =>
                              sub.id === item.id
                                ? { ...item, isChanged: true }
                                : sub
                            ) || [],
                        };
                      }

                      return s.id === item.id ? item : s;
                    }
                  );

                  setSelectedBlueprint({
                    ...selectedBlueprint,
                    services: updated,
                  } as Blueprint);
                }
              }}
            />
          )
        );
      },
    },
  ];

  const handleFailAlert = (msg) => {
    setErrorMessage(msg || "Something went wrong");
    setShowFailAlert(true);
  };

  const loading =
    isLoading ||
    isFetching ||
    resourcesLoading ||
    resourceRatesLoading ||
    projectResourcesLoading ||
    projectLoading ||
    updating;

  const phases =
    step == 1
      ? []
      : getOrganizedPhasesFromListOfServices(selectedBlueprint?.services || []);

  return (
    <div className="servicesV2 applyBlueprint">
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <div className="actionBtns">
        <div className="leftHeader">
          {" "}
          <Button
            className="button cancelButton"
            onClick={(e) => {
              if (step === 1) {
                window.location.replace(
                  `${appHost}/projects/${project_id}/project_editor`
                );
              } else {
                setStep(1);
              }
            }}
          >
            {step === 1 ? "Cancel" : "Back"}
          </Button>
          <div className="servicesH1">Add Services from Blueprint</div>
        </div>
        {step === 2 && (
          <Button
            className={`button`}
            onClick={async (e) => {
              if (
                selectedBlueprint?.services
                  ?.filter((s) => s?.serviceType === "professional_services")
                  ?.some((s) => !formatResource(s))
              ) {
                handleFailAlert(
                  "Please select a resource for each service to continue"
                );
                return;
              }
              setUpdating(true);
              await handleCreateProjectServices(
                (selectedBlueprint?.services as Service[]) || [],
                serviceType,
                project_id,
                accountSlug,
                authorizationCode,
                handleFailAlert
              );

              window.location.replace(
                `${appHost}/projects/${project_id}/project_editor`
              );
            }}
          >
            Apply
          </Button>
        )}
      </div>
      <div className="servicesH2">
        {step === 1
          ? "1. Select desired Blueprint to continue."
          : "2. Review & apply selected Blueprint."}
      </div>

      {step === 2 && (
        <div className="blueprintHeader">{selectedBlueprint?.name || ""}</div>
      )}
      {step === 1 && (
        <div className="searchField">
          <FormFieldLabel label="Search blueprints" className={undefined} />
          <Form
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setCurrentPage(1);
                handleSearch(e);
              }
            }}
            onSubmit={(e) => {
              setCurrentPage(1);
              handleSearch(e);
            }}
          >
            <SearchField
              placeholder="Search existing blueprints"
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              value={searchInput}
              onClick={(e) => {
                setSearchInput("");
                setFilterParams({ name: undefined });
              }}
              fullWidth
            />
          </Form>
        </div>
      )}

      {loading ? (
        <SmallSpinner />
      ) : step === 1 ? (
        <DataTable
          data={
            blueprints?.length
              ? blueprints
              : [{ id: "0", name: "No blueprints found" }]
          }
          columns={
            serviceType === "professional_services"
              ? selectColumns
              : selectColumns.filter((column) => column.id !== "phase")
          }
          totalRows={pageMeta?.recordCount}
          totalPages={pageMeta?.pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={(num) => setPageSize(num)}
          paginationEnabled
          pageSizeEnabled
          bordered
          striped
          hover
        />
      ) : (
        phases.map((p, i) => (
          <div key={i} className="phaseContainer">
            <div className={`phaseHeader ${!p?.name ? "noName" : ""}`}>
              {p?.name || ""}
            </div>
            <Form>
              <ServicesTable
                servicesPassed={
                  (selectedBlueprint?.services || []).filter(
                    (s) => s?.phase?.id === p?.id
                  ) || [{ id: "0", name: "No services found" }]
                }
                onUpdateServices={(services) => null}
                serviceColumns={
                  serviceType === "professional_services"
                    ? reviewColumns
                    : reviewColumns.filter((column) => column.id !== "resource")
                }
                subserviceColumns={
                  serviceType === "professional_services"
                    ? reviewColumns
                    : reviewColumns.filter((column) => column.id !== "resource")
                }
              />
            </Form>
          </div>
        ))
      )}

      <BlueprintPreview
        blueprint={blueprintToPreview}
        slideoutOpen={blueprintSlideoutOpen}
        setSlideoutOpen={setBlueprintSlideoutOpen}
      />
      <ServicePreview
        service={serviceToPreview}
        slideoutOpen={serviceSlideoutOpen}
        setSlideoutOpen={setServiceSlideoutOpen}
        isManagedService={serviceType === "managed_services"}
      />
    </div>
  );
};

export default Blueprints;
