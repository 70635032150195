import React from "react";
import _ from "lodash";
import API from "./API/API";

const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const capitalizeWords = (string) => {
  return string.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

export const replaceUnderscoresWithSpaces = (str) => str.replace(/_/g, " ");

export const snakeToPascalCase = (input) => {
  if (!input || input === input.toUpperCase()) {
    return input;
  }

  return `${input}`
    .replace(new RegExp(/[-_]+/, "g"), " ")
    .replace(new RegExp(/[^\w\s]/, "g"), "")
    .replace(
      new RegExp(/\s+(.)(\w+)/, "g"),
      ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
    )
    .replace(new RegExp(/\s/, "g"), "")
    .replace(new RegExp(/\w/), (s) => s.toUpperCase());
};

export const toSnakeCase = (str = "") => {
  const strArr = str.split(" ");
  const snakeArr = strArr.reduce((acc, val) => {
    return acc.concat(val.toLowerCase());
  }, []);
  return snakeArr.join("_");
};

export const splitPascalCase = (word) => {
  if (!word) {
    return word;
  }
  var wordRe = /($[a-z])|[A-Z][^A-Z]+/g;
  const matches = word.match(wordRe);
  return matches ? matches.join(" ") : word;
};

export const snakeToText = (str) => splitPascalCase(snakeToPascalCase(str));

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const formatErrorResponse = (errata) => {
  if (errata.error) {
    return [{ attribute: "login", messages: errata.error }];
  }
  if (errata.errors) {
    return errata.errors.map((error) => {
      if (error.title && error.title !== "must exist") {
        let err = error.title;
        return {
          attribute: "",
          message: err.slice(-1) === "." ? err.slice(0, -1) : err,
        };
      } else if (error.detail) {
        var parts = error.detail.split(" - ");
        return { attribute: parts[0], message: parts[1] };
      }
    });
  }
};

export const formatUnprocessibleResponse = (err, item) => {
  let message =
    "Something went wrong! Some of your changes could not be saved at this time.";
  if (err.response.status == 403) {
    message = err.response.data.errors[0].detail;
  }
  if (err.response.status == 422) {
    let serverErrors = formatErrorResponse(err.response.data);
    message =
      `Could not save ${item}. ` +
      serverErrors.map((error) => {
        var attributeName =
          error.attribute.charAt(0).toUpperCase() +
          error.attribute.slice(1).replaceAll("-", " ");
        return attributeName + " " + error.message;
      });
  }
  return message;
};

export const removeDuplicates = (data, key) => {
  return Array.from(new Map(data.map((item) => [key(item), item])).values());
};

export const getDate = (dateString, options) => {
  if (dateString) {
    let dateArray = dateString.split("-");
    let year = dateArray[0];
    let month = parseInt(dateArray[1], 10) - 1;
    let date = dateArray[2];
    let dueDate = new Date(year, month, date).toLocaleDateString(
      "en-US",
      options
    );
    return dueDate;
  }
};
export const wordCount = (str) => {
  var wordCount = str.match(/(\w+)/g).length;
  return wordCount;
};

export const stringToArray = (string) => [].concat(string);

export const formatDateForField = (date) => {
  if (date == "") {
    return date;
  }

  let [month, day, year] = date.split("/");
  if (month.length == 1) {
    month = "0".concat(month);
  }
  if (day.length == 1) {
    day = "0".concat(day);
  }

  let result = [year, month, day].join("-");
  return result;
};

// If possible, filter the projcet resources and standard resources to present
// the user a list of resources that can be applied based on the LOB or service category
export const renderCategoryResourceOptions = (
  projectResources,
  resources,
  lobs
) => {
  let filteredProjectResources = projectResources;
  let filteredStandardResources = resources;

  return renderResourceOptions(
    filteredProjectResources,
    filteredStandardResources,
    lobs
  );
};

// Renders project-resources & resources options in groups for react-select (select2) dropdown
export const renderResourceOptions = (projectResources, resources, lobs) => {
  // Project Resources (project-level)
  let projectResourceOptions = projectResources
    .map((resource) => {
      let id = resource.id;
      let resourceOption = renderResourceName(resource, lobs);
      return {
        value: `project-resources-${id}`,
        label: resourceOption,
      };
    })
    .sort((a, b) => {
      return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
    });

  // Standard Resources (from settings/account level)
  let standardResourcOptions = resources
    .map((resource) => {
      let id = resource.id;
      let type = resource.type;
      let resourceOption = renderResourceName(resource, lobs);
      return { value: `${type}-${id}`, label: resourceOption };
    })
    .sort((a, b) => {
      return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
    });
  const options = [
    {
      label: "Select Resource",
      value: -1,
    },
    {
      label: "Project Resources",
      options: projectResourceOptions,
      style: { color: "#418172" },
    },
    {
      label: "Standard Resources",
      options: standardResourcOptions,
    },
  ];
  return options || [];
};

export const renderResourceName = (resource, lobs) => {
  let resourceName = "";
  let lobName = "";
  let attr = resource.attributes ? resource.attributes : resource;
  let lobDataOnResource = "";

  if (attr["extended-name"])
    return attr["extended-name"];

  if (
    resource.relationships &&
    resource.relationships["line-of-business"] &&
    lobs.length !== 0
  ) {
    lobDataOnResource = resource.relationships["line-of-business"].data;
    lobs.forEach((lob) => {
      if (lobDataOnResource && lobDataOnResource.id == lob.id) {
        lobName = `(${lob.attributes.name})`;
      }
    });
  }

  resourceName =
    attr["external-name"] &&
    attr["external-name"] !== null &&
    attr["external-name"] !== "" &&
    attr["external-name"] !== attr.name
      ? `${attr["external-name"]}/${attr.name}`
      : attr.name;

  return lobName ? `${resourceName} ${lobName}` : resourceName;
};

export const includesAny = (arr, values) => values.some((v) => arr.includes(v));

export const handleAlert = (
  showAlert,
  alertMessage,
  setShowAlert,
  variant,
  Component
) => {
  if (showAlert && variant === "success") {
    let message = "Success!";
    if (alertMessage !== null) message = alertMessage;
    if (Component) {
      return (
        <Component
          variant={variant}
          onClose={() => setShowAlert(false)}
          text={message}
        />
      );
    }
  } else if (showAlert && (variant === "warning" || variant === "danger")) {
    let message =
      "Something went wrong! Some of your changes could not be saved at this time.";
    if (alertMessage !== null) message = alertMessage;
    if (Component) {
      return (
        <Component
          variant={variant}
          onClose={() => setShowAlert(false)}
          text={message}
        />
      );
    }
  } else {
    return null;
  }
};

export const containsUpperCase = (str) => {
  return /[A-Z]/.test(str);
};

export const containsLowerCase = (str) => {
  return /[a-z]/.test(str);
};

export const containsSpecialCharacter = (str) => {
  let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return format.test(str);
};

export const toHyphenCase = (obj) => {
  if (_.isArray(obj)) {
    return obj.map(toHyphenCase);
  } else if (_.isObject(obj)) {
    return _.mapValues(
      _.mapKeys(obj, (value, key) =>
        key.includes("_") ? key : _.kebabCase(key)
      ),
      toHyphenCase
    );
  } else {
    return obj;
  }
};

// Used with react-beautiful-dnd npm
export const validateDrop = (result) => {
  let isValid = true;
  const { source, destination } = result;
  if (!destination) return false;
  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  )
    isValid = false;
  return isValid;
};

export const getNextPageWithRecursion = (
  nextLink,
  authorizationCode,
  list,
  setList
) => {
  API.Get(nextLink, authorizationCode).then((res) => {
    list = list.concat(res.data.data);
    if (res.data.links.next) {
      getNextPageWithRecursion(
        res.data.links.next,
        authorizationCode,
        list,
        setList
      );
    } else if (
      !res.data.links.next &&
      res.data.meta["record-count"] < list.length
    ) {
      getNextPageWithRecursion(
        res.data.links.last,
        authorizationCode,
        list,
        setList
      );
    } else {
      setList(list);
    }
  });
};

export const findResourceByNameAndReturnPromise = (
  resourceType,
  name,
  account_slug,
  authorizationCode,
  includeString
) => {
  return API.Get(
    `${apiHost}/${account_slug}/v1/${resourceType}?include=${includeString}&filter[name]=${encodeURIComponent(
      name
    )}&page[size]=1000`,
    authorizationCode
  );
};

export const validateEmails = (emails) => {
  if (!emails || !emails.trim()) {
    return false;
  }

  const emailRegex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  const emailList = emails.split(",");

  for (let i = 0; i < emailList.length; i++) {
    const email = emailList[i].trim();
    if (!emailRegex.test(email)) {
      return false;
    }
  }

  return true;
};

export const getFormattedDate = (date) => {
  if (date === "" || !date) return "";

  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
};

export const truncateString = (str, length, ending) => {
  if (length == null) {
    length = 100;
  }
  // If the ending parameter is not provided, set it to '...'
  if (ending == null) {
    ending = "...";
  }
  // Check if the length of the input string exceeds the specified length
  if (str.length > length) {
    // If yes, truncate the string to length - ending.length characters and append the ending
    return str.substring(0, length - ending.length) + ending;
  } else {
    // If no, return the original string
    return str;
  }
};

export const getPathname = (url) => {
  let reg = /.+?\:\/\/.+?(\/.+?)(?:#|\?|$)/;
  var pathname = reg.exec(url)[1];
  return pathname;
};
