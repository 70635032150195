import { V1QuestionResource } from "@generated";
import { Form } from "react-bootstrap";
import RequiredReactSelect from "@components/FormsV2/RequiredReactSelect/RequiredReactSelect";
import { Question } from "../types/Question";

const buildInputElement = (
  question: Question,
  questions: Question[],
  setQuestions: (questions: Question[]) => void
): JSX.Element => {
  const id = String(question?.["question-id"]);
  const type = question?.["value-type"];
  const required = question?.required || false;
  const name = question?.question || "";
  const min = question?.settings?.min;
  const max = question?.settings?.max;
  const step = question?.settings?.step;

  if (!id || !type) return <></>;

  const options = question?.["select-options"];

  const updateAnswer = (answer) => {
    setQuestions(
      questions.map((q) => {
        //@ts-ignore
        return String(q?.["question-id"]) === id ? { ...q, answer } : q;
      })
    );
  };

  if (options && options?.length) {
    const defaultValue = options.find((opt) => opt.default);
    const defined = options.find((opt) => opt.value === question?.answer);
    return (
      <Form.Group controlId={id}>
        <RequiredReactSelect
          label={
            <span>
              {required ? <span className="required">* </span> : null}
              <span>{name}</span>
            </span>
          }
          required={required}
          // isInvalid={false}
          id={id}
          key={id}
          value={
            defined || defaultValue
              ? {
                  label: defined?.key || defaultValue?.key || "",
                  value: defined?.value || defaultValue?.value || "",
                }
              : undefined
          }
          //@ts-ignore
          onChange={(e) => updateAnswer(e?.value || "")}
          options={options.map((option) => {
            return {
              label: option?.key || "",
              value: option?.value || "",
            };
          })}
        />
      </Form.Group>
    );
  } else if (type === "text" || type === "email") {
    return (
      <Form.Group controlId={id}>
        <Form.Label>
          {required && <span className="required">* </span>}
          {name}
        </Form.Label>
        <Form.Control
          required={required}
          type={question?.["value-type"]}
          defaultValue={question?.answer}
          onChange={(e) => updateAnswer(e?.target?.value || "")}
        />
      </Form.Group>
    );
  } else if (type === "number") {
    return (
      <Form.Group controlId={id}>
        <Form.Label>
          {required && <span className="required">* </span>}
          {name}
        </Form.Label>
        <Form.Control
          required={required}
          type="number"
          min={parseInt(min || "0")}
          defaultValue={question?.answer}
          max={max ? parseInt(max) : undefined}
          step={parseInt(String(step) || "1")}
          onChange={(e) => updateAnswer(e?.target?.value || "")}
        />
      </Form.Group>
    );
  } else if (type === "checkbox") {
    return (
      <Form.Group controlId={id}>
        <Form.Check
          type="checkbox"
          label={
            <span>
              {required && <span className="required">* </span>}
              {name}
            </span>
          }
          checked={question?.answer === "on"}
          required={required}
          onChange={(e) => updateAnswer(e?.target?.checked ? "on" : "off")}
        />
      </Form.Group>
    );
  } else if (type === "date") {
    return (
      <Form.Group controlId={id}>
        <Form.Label>
          {required && <span className="required">* </span>}
          {name}
        </Form.Label>
        <Form.Control
          required={required}
          defaultValue={question?.answer}
          onChange={(e) => updateAnswer(e?.target?.value)}
          min={min ? String(new Date(min)) : undefined}
          max={max ? String(new Date(max)) : undefined}
          type="date"
        />
      </Form.Group>
    );
  } else if (type === "phone") {
    return (
      <Form.Group controlId={id}>
        <Form.Label>
          {required && <span className="required">* </span>}
          {name}
        </Form.Label>
        <input
          className="form-control"
          required={required}
          value={question?.answer || ""}
          placeholder="2125553456"
          pattern="^\+?(?:[0-9]●?){6,14}[0-9]$"
          type="tel"
          onChange={(e) => updateAnswer(e?.target?.value || "")}
        />
      </Form.Group>
    );
  }
  return <></>;
};

export default buildInputElement;
