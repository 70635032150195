import { Blueprint } from "../../../pages/Projects/ServicesV2/types";
import SlideOutMenuV2 from "@components/SlideOutMenuV2";
import DataTable from "@components/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import "./style.css";
import { getOrganizedPhasesFromListOfServices } from "@utils/helpers";
import { Phase } from "@utils/helpers";
interface BlueprintItem {
  id: string;
  name?: string;
  qty?: string;
  resource?: { id: string; name: string };
  phase?: Phase;
}

interface BlueprintPreviewProps {
  blueprint: Blueprint;
  slideoutOpen: boolean;
  setSlideoutOpen: (bool) => void;
  title?: string;
}

const BlueprintPreview = ({
  blueprint,
  slideoutOpen,
  setSlideoutOpen,
  title = "View Services In Blueprint",
}: BlueprintPreviewProps) => {
  const phases = getOrganizedPhasesFromListOfServices(
    blueprint?.services || []
  );

  const columns: ColumnDef<BlueprintItem>[] = [
    {
      header: "Service Name",
      cell(props) {
        return props.row.original.name || "";
      },
    },
    {
      header: "Qty",
      cell(props) {
        return (
          <div className="alignCenter">{props.row.original.qty || ""}</div>
        );
      },
    },
    {
      header: "Resource",
      cell(props) {
        return <div>{props.row.original?.resource?.name || ""}</div>;
      },
    },
  ];

  return (
    <SlideOutMenuV2
      title={title}
      isOpen={slideoutOpen}
      onClose={() => setSlideoutOpen(false)}
    >
      <div className="blueprintPreviewSlideout">
        {phases?.length && blueprint?.services?.length
          ? phases.map((p, i) => (
              <div key={i}>
                <div className="previewSubheader">{p?.name}</div>
                <DataTable
                  data={
                    blueprint?.services?.filter(
                      (s) => s?.phase?.id === p?.id
                    ) || [{ id: "0", name: "No services found" }]
                  }
                  columns={columns}
                  bordered
                  striped
                />
              </div>
            ))
          : "No services found"}
      </div>
    </SlideOutMenuV2>
  );
};

export default BlueprintPreview;
