import {
  useListSurveysForAccountQuery,
  V1ProjectResource,
  V1QuestionnaireResource,
} from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { CompletedSurvey } from "../types";
import { getFormattedDate } from "@utils/helperFunctions";
const useListCompletedSurveys = ({
  pageSize,
  pageNumber,
  filterParams,
  sortBy,
  sortOrder,
  projectId,
}) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  const { data, error, isLoading, refetch, isFetching } =
    useListSurveysForAccountQuery({
      slug: accountSlug,
      filter: {
        ...filterParams,
        status: ["completed", "applied", "revised"],
        "project-id": projectId || undefined,
      },
      page: {
        number: pageNumber,
        size: pageSize,
      },
      //@ts-ignore
      include: ["project", "questionnaire", "project-survey"],
      sort: sortBy
        ? [sortOrder == "asc" ? sortBy : `-${sortBy}`]
        : ["-updated_at"],
    });

  let completedSurveys: CompletedSurvey[] = [];

  if (data?.data) {
    try {
      completedSurveys = data.data.map((survey) => {
        if (!survey.id) {
          throw new Error("Missing survey.id");
        }
        if (!survey.attributes.name) {
          throw new Error("Missing survey.attributes.name");
        }

        const projectId = String(survey.relationships.project?.data?.id);
        const relatedProject = data?.included?.find(
          (p) => p.type == "projects" && p.id === projectId
        ) as V1ProjectResource | undefined;
        const questionnaireId = survey.relationships.questionnaire.data
          ?.id as number;
        const relatedQuestionnaire = data?.included?.find(
          (q) => q.type === "questionnaires" && q.id === questionnaireId
        ) as V1QuestionnaireResource | undefined;

        return {
          id: String(survey.id),
          checked: false,
          attributes: {
            name: survey.attributes.name,
            project: relatedProject?.["attributes"]?.["project-name"] || "",
            completedBy: survey.attributes?.["completed-by"]
              ? survey.attributes?.["completed-by"]
              : survey.attributes?.["contact-name"]
              ? survey.attributes?.["contact-name"]
              : "",
            completedOn: survey.attributes?.["updated-at"]
              ? getFormattedDate(new Date(survey.attributes["updated-at"]))
              : "",
            tags: relatedQuestionnaire?.["attributes"]?.["tag-list"] || [],
            questionnaireId,
            projectId,
            projectSurveyId:
              survey?.relationships?.["project-survey"]?.data?.id || undefined,
          },
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    completedSurveys,
    isLoading,
    error: error ?? customError,
    pageMeta,
    refetch,
    isFetching,
  };
};
export default useListCompletedSurveys;
