import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import AutoCompleteText from "@components/Forms/AutoCompleteText/AutoCompleteText";
import FormTextField from "@components/Forms/FormTextField";
import AddressForm from "@components/Forms/AddressForm";
import DropDownField from "@components/Forms/DropDownField";
import "../../ProjectOverview/ProjectOverview.css";
import IconButton from "@components/Buttons/IconButton/Iconbutton";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import {
  formatDate,
  formatUnprocessibleResponse,
} from "@utils/helperFunctions";
import API from "@API";
import BackButton from "@components/Buttons/BackButton/BackButton";
import DisabledTextField from "@components/Forms/DisabledTextField";
import NewProjectHeader from "../../ProjectOverview/NewProjectHeader/NewProjectHeader";
import SubmitAndScopeBlueprintBtns from "../../ProjectOverview/ProjectButtons/SubmitAndScopeBlueprintBtns/SubmitAndScopeBlueprintBtns";
import ProjectVariables from "../../../ProjectVariables/ProjectVariables";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import FormDateField from "@components/Forms/FormDateField";
import {
  getUpdatedProjectVariables,
  getUpdatedCrm,
  getUpdatedPaymentTerm,
  getUpdatedPresalesEngineer,
  getUpdatedRateTable,
  getUpdatedBusinessUnit,
  upsertClientId,
  upsertProjectLocation,
  upsertSalesExecutiveId,
  validateLocation,
  validateVariables,
  handleCharacterAlert,
  handleFailAlert,
  handleLocationAlert,
  handleUserExistsAlert,
} from "../../projectUtils/projectHelperFunctions";
import useFlags from "@common/hooks/useFlags";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import DropdownApiJs from "@components/FormsV2/DropdownApiJs/DropdownApiJs";

function NewProject({
  account_id,
  account_slug,
  authorizationCode,
  project_id,
  salesExecutiveCreatePermission,
}) {
  // Set the states for our data
  const [projectData, setProjectData] = useState({});
  const [project, setProject] = useState([]);
  const [projectStatus, setProjectStatus] = useState("");
  const [projectName, setProjectName] = useState(null);
  const [unrequiredProjectVariables, setUnrequiredProjectVariables] = useState(
    []
  );
  const [requiredProjectVariables, setRequiredProjectVariables] = useState([]);
  const [requiredLocationVariables, setRequiredLocationVariables] = useState(
    []
  );
  const [unrequiredLocationVariables, setUnrequiredLocationVariables] =
    useState([]);
  const [userExistsAlertIsOpen, setUserExistsAlertIsOpen] = useState(false);

  var newProjectId = null;

  // Client States
  const [clientList, setClientList] = useState([]);
  var chosenClient = "";
  const [newClientId, setNewClientId] = useState(null);
  const [clients, setClients] = useState([]);
  const [clientName, setClientName] = useState("");
  const [userTypedClient, setUserTypedClient] = useState(false);
  const [msaDate, setMsaDate] = useState(null);

  // Payment Term states
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [currentPaymentTerm, setCurrentPaymentTerm] = useState("");
  const [newPaymentTermId, setNewPaymentTermId] = useState(null);

  // Rate table states
  const [rateTables, setRateTables] = useState([]);
  const [currentRateTable, setCurrentRateTable] = useState("");
  const [newRateTableId, setNewRateTableId] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  // Location States
  const [projectLocations, setProjectLocations] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [addressChanged, setAddressChanged] = useState(false);
  const [customerSite, setCustomerSite] = useState("");
  const [remoteSite, setRemoteSite] = useState("");
  const [address, setAddress] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [countryId, setCountryId] = useState("");

  //Crm States
  const [crmState, setCrmState] = useState([]);
  const [crmId, setCrmId] = useState("");
  const [currentCrmOpporutnity, setCurrentCrmOpportunity] = useState("");
  const [newCrmId, setNewCrmId] = useState(null);
  const [crmOppInvalid, setCrmOppInvalid] = useState(false);

  //Sales exec states
  const [salesExecutives, setSalesExecutives] = useState([]);
  const [salesExecs, setSalesExecs] = useState([]);
  const [currentSalesExec, setCurrentSalesExec] = useState({
    value: "",
    label: "",
  });
  const [newSalesExecId, setNewSalesExecId] = useState(null);
  const [salesExecInvalid, setSalesExecInvalid] = useState(false);

  // Presales states
  const [presales, setPresales] = useState([]);
  const [currentPresalesEngineer, setCurrentPresalesEngineer] = useState("");
  const [newPresalesEngineerId, setNewPresalesEngineerId] = useState(null);

  // Business Unit States
  const [businessUnits, setBusinessUnits] = useState([]);
  const [currentBusinessUnit, setCurrentBusinessUnit] = useState("");
  const [newBusinessUnitId, setNewBusinessUnitId] = useState(null);

  // Project Tags States
  const [tags, setTags] = useState("");

  // Alerts, labels, api, env
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successText, setSuccessText] = useState("Project changes saved");
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [submitFail, setSubmitFail] = useState(false);
  const [fieldLabels, setFieldLabels] = useState({
    presales_engineer: "Pre-Sales Enginner",
    sales_executive: "Sales Executive",
    business_unit: "Business Unit",
    location: "Service Location",
    client: "Client",
  });
  const [scopeBlueprintButtonClicked, setScopeBlueprintButtonClicked] =
    useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  const [showLocationAlert, setShowLocationAlert] = useState(false);
  const [locationErrors, setLocationErrors] = useState([]);

  const [showMinCharAlert, setShowMinCharAlert] = useState(false);
  const [showMaxCharAlert, setShowMaxCharAlert] = useState(false);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  const [isCrm, setIsCrm] = useState(null);
  const [isPsa, setIsPsa] = useState(null);

  const today = new Date();
  const [errorMessage, setErrorMessage] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  // LD flags
  const {
    configCrmOpportunityRequired: crmFlag,
    configSalesExecutiveRequired,
    configPresalesEngineerRequired,
    configMsaDateHidden,
    configBusinessUnitRequired,
    configServiceLocationRequired,
  } = useFlags();

  // When the page loads
  useEffect(() => {
    // Get all the data from the API that we need and put it into the appropriate state
    if (authorizationCode != "") {
      API.Get(
        `${apiHost}/${account_slug}/v1/payment-terms`,
        authorizationCode
      ).then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          const defaultPaymentTerm = res.data.data.filter(
            (term) => term.attributes.default
          )[0];
          setCurrentPaymentTerm(defaultPaymentTerm.attributes.name);
          setNewPaymentTermId(defaultPaymentTerm.id);
        }
      });
      // Account information
      API.Get(`${apiHost}/v1/accounts/${account_slug}`, authorizationCode).then(
        (response) => {
          const data = response.data.data;
          setIsCrm(data.attributes["crm?"]);
          setIsPsa(data.attributes["psa?"]);

          // Field Labels
          setFieldLabels(data.attributes["field-labels"]);

          // Client list for typeahead field
          API.Get(
            data.links.clients + "?include=rate-table&page[size]=100",
            authorizationCode
          ).then((response) => {
            const data = response.data.data;
            const clients = data.map((client) => ({
              id: client.id,
              client: client.attributes.name,
              msa_date: client.attributes["msa-date"],
              relationships: client.relationships,
            }));
            if (clients.length !== 0) {
              setClients(clients);
              const clientNames = data.map((client) => client.attributes.name);
              setClientList(clientNames);
            }
          });

          // Sales executives
          API.Get(data.links["sales-executives"], authorizationCode).then(
            (response) => {
              const salesExecList = response.data.data;
              if (salesExecList.length !== 0) {
                const salesExecs = salesExecList.map((salesExec) => ({
                  id: salesExec.id,
                  salesExec: salesExec.attributes.name,
                }));
                setSalesExecs(salesExecs);

                const salesExecNames = salesExecList.map(
                  (obj) => obj.attributes.name
                );
                setSalesExecutives(salesExecNames);
              }
            }
          );

          // Payment Terms
          API.Get(data.links["payment-terms"], authorizationCode).then(
            (response) => {
              let paymentTerms = response.data.data;
              setPaymentTerms(paymentTerms);
            }
          );

          // Presales Engineers
          API.Get(
            data.links["presales-engineers"] + "?page[size]=500",
            authorizationCode
          ).then((response) => setPresales(response.data.data));

          // Business Units
          API.Get(data.links["business-units"], authorizationCode).then(
            (response) => {
              let businessUnits = response.data.data;
              if (businessUnits !== null) setBusinessUnits(businessUnits);
              if (businessUnits.length === 1) {
                setCurrentBusinessUnit(businessUnits[0].attributes.name);
                setNewBusinessUnitId(businessUnits[0].id);
              }
            }
          );

          // Rate-table
          API.Get(
            data.links["rate-tables"] + "?page[size]=500",
            authorizationCode
          ).then((response) => {
            const rateTableData = response.data.data;
            setRateTables(
              rateTableData.filter(
                (rateTable) => rateTable.attributes.active === true
              )
            );

            // Current User
            API.Get(
              `${apiHost}/${account_slug}/v1/users/current?include=rate-table`,
              authorizationCode
            ).then((response) => {
              const data = response.data;
              setCurrentUser(data);

              const userRateTable =
                data.attributes["preferred-rate-table"].name;
              setCurrentRateTable(userRateTable);
              let [rateTable] = rateTableData.filter(
                (rateTable) => rateTable.attributes.name === userRateTable
              );
              setNewRateTableId(rateTable.id);
            });
          });

          // Project Variables
          API.Get(
            data.links["project-variables"] +
              "?filter[variable-context]=project,service_location",
            authorizationCode
          ).then((response) => {
            let variables = response.data.data.map((pv) => {
              let attr = pv.attributes;
              let options = attr["select-options"];
              delete attr["select-options"];
              return { ...attr, select_options: options };
            });
            if (variables.length !== 0) {
              let reqProjectVariables = variables.filter((variable) => {
                if (variable.required === true) {
                  return variable.required;
                }
              });
              reqProjectVariables = reqProjectVariables.map((variable) => {
                if (variable["variable-type"] === "date") {
                  return { ...variable, value: formatDate(today) };
                } else {
                  return { ...variable, value: "" };
                }
              });
              setRequiredProjectVariables(
                reqProjectVariables.filter(
                  (variable) => variable["variable-context"] == "project"
                )
              );
              setRequiredLocationVariables(
                reqProjectVariables.filter(
                  (variable) =>
                    variable["variable-context"] == "service_location"
                )
              );
              let unreqProjectVariables = variables.filter((variable) => {
                if (variable.required === false) {
                  return variable;
                }
              });
              unreqProjectVariables = unreqProjectVariables.map((variable) => {
                return { ...variable, value: "" };
              });
              setUnrequiredProjectVariables(
                unreqProjectVariables.filter(
                  (variable) => variable["variable-context"] == "project"
                )
              );
              setUnrequiredLocationVariables(
                unreqProjectVariables.filter(
                  (variable) =>
                    variable["variable-context"] == "service_location"
                )
              );
            }
            setIsLoading(false);
          });
        }
      ); // End Account API call
    } // End authorizationCode if statement
  }, [authorizationCode]); // END USE EFFECT

  // Render Page Header based on location (url)
  const renderHeader = () => {
    return <NewProjectHeader />;
  };

  // Conditionals for rendering blue buttons at the bottom of the card
  const renderButtons = () => {
    return (
      <SubmitAndScopeBlueprintBtns
        submitDisabled={buttonsDisabled}
        scopeDisabled={buttonsDisabled}
        onClick={() => setScopeBlueprintButtonClicked(true)}
      />
    );
  };

  const getNewLocation = (projectId) => {
    let allLocationVariables = requiredLocationVariables.concat(
      unrequiredLocationVariables
    );
    let newLocationVariables = allLocationVariables.map((variable) => {
      return { name: variable.name, value: variable.value };
    });
    let newLocation = {
      data: {
        type: "project-locations",
        attributes: {
          name: customerSite,
          street: address,
          street2: street2,
          city: city,
          state: state,
          "postal-code": zip,
          country: countryId,
          "project-variables": newLocationVariables,
        },
        relationships: {
          project: {
            data: {
              type: "projects",
              id: projectId,
            },
          },
        },
      },
    };
    return newLocation;
  };

  const filterClientList = (clientName) => {
    return API.Get(
      `${apiHost}/${account_slug}/v1/clients?filter[active]=true&filter[name]=${encodeURIComponent(
        clientName
      )}&include=rate-table`,
      authorizationCode
    );
  };

  const handleCrmChange = (e) => {
    let crmsId = e.value;
    setNewCrmId(crmsId);
    setCrmOppInvalid(false);

    if (crmsId === -1) {
      document.getElementById("clientName").readOnly = false;
      setNewCrmId(null);
    } else {
      for (let i = 0; i < crmState.length; i++) {
        if (crmState[i].id == crmsId) {
          let crmAttr = crmState[i].attributes;
          setProjectName(crmAttr.name);
          setClientName(crmAttr["account-name"]);
          filterClientList(crmAttr["account-name"]).then((res) => {
            const data = res.data.data;
            const clients = data.map((client) => ({
              id: client.id,
              client: client.attributes.name,
              msa_date: client.attributes["msa-date"],
              relationships: client.relationships,
            }));
            setClients(clients);
            const clientNames = data.map((client) => client.attributes.name);
            setClientList(clientNames);

            let clientExists = "";
            for (let i = 0; i < data.length; i++) {
              if (data[i].attributes.name === clientName)
                clientExists = data[i].id;
            }
            for (let j = 0; j < clients.length; j++) {
              if (crmAttr["account-name"] === clients[j].client) {
                setNewClientId(clients[j].id);
                setMsaDate(clients[j].msa_date);
              }
            }
          });
          let locationName = crmAttr["location-name"];
          if (locationName != null) {
            setCustomerSite(crmAttr["location-name"]);
          } else {
            setCustomerSite(crmAttr["account-name"]);
          }
          setAddress(crmAttr.street);
          if (crmAttr.street2) {
            setStreet2(crmAttr.street2);
          }
          setState(crmAttr.state);
          setCity(crmAttr.city);
          setZip(crmAttr["postal-code"]);
          if (crmAttr.country) {
            setCountry(crmAttr.country);
          }
          setAddressChanged(true);
          let customAttributes = crmAttr["custom-attributes"];
          if (customAttributes !== null && customAttributes.length !== 0) {
            handleCustomAttributes(
              customAttributes,
              unrequiredProjectVariables,
              setUnrequiredProjectVariables,
              crmAttr["owner-name"]
            );
            handleCustomAttributes(
              customAttributes,
              requiredProjectVariables,
              setRequiredProjectVariables,
              crmAttr["owner-name"]
            );
          }
          if (crmAttr["owner-name"]) {
            upsertSalesExecutiveId(
              crmAttr["owner-name"],
              account_slug,
              authorizationCode,
              account_id
            ).then((salesExec) => {
              setNewSalesExecId(salesExec.id);
              setCurrentSalesExec({
                label: crmAttr["owner-name"],
                id: salesExec.id,
              });
            });
          }
        }
      }
      document.getElementById("clientName").readOnly = true;
    }
  };

  const handleCustomAttributes = (
    customAttributes,
    variableArray,
    setVariableArray,
    hasOwnerName
  ) => {
    if (customAttributes !== null && customAttributes.length !== 0) {
      customAttributes.forEach((attr) => {
        if (attr.key === "sow_presales_engineer_id") {
          presales.forEach((eng) => {
            if (eng.attributes.name === attr.value) {
              setCurrentPresalesEngineer(attr.value);
              setNewPresalesEngineerId(eng.id);
            }
          });
        }
        if (attr.key === "client_manager_name" && !hasOwnerName) {
          upsertSalesExecutiveId(
            currentSalesExec.label,
            account_slug,
            authorizationCode,
            account_id
          ).then((salesExec) => {
            setNewSalesExecId(salesExec.id);
            setCurrentSalesExec({ label: attr.value, id: salesExec.id });
          });
        }
      });
      let newVariableArray = variableArray;

      for (let i = 0; i < newVariableArray.length; i++) {
        for (let j = 0; j < customAttributes.length; j++) {
          newVariableArray = newVariableArray.map((variable) =>
            variable.name === customAttributes[j].key
              ? { ...variable, value: customAttributes[j].value }
              : variable
          );
        }
      }
      setVariableArray(newVariableArray);
    }
  };

  const renderCrmOpportunity = () => {
    if (currentCrmOpporutnity !== "") {
      // document.getElementById("projectName").readOnly = true;
      document.getElementById("clientName").readOnly = true;
      return (
        <DisabledTextField
          value={currentCrmOpporutnity}
          label={`${fieldLabels["crm_opportunity"]}`}
        />
      );
    } else {
      return (
        <>
          <DropdownApiJs
            placeholder={`Select ${fieldLabels["crm_opportunity"]}`}
            defaultValue={
              currentCrmOpporutnity === ""
                ? { value: -1, label: "-- None --" }
                : { value: crmId, label: currentCrmOpporutnity }
            }
            onChange={(e) => handleCrmChange(e)}
            required={crmFlag}
            label={`${fieldLabels["crm_opportunity"]} ${crmFlag ? "*" : ""}`}
            isInvalid={crmOppInvalid}
            url={`${apiHost}/${account_slug}/v1/crm-opportunities`}
            isDisabled={false}
            filter="display-name"
            blankOption={{ value: -1, label: "--None--" }}
            canCreate={false}
            setDataState={setCrmState}
          />
        </>
      );
    }
  };

  const renderLocation = () => {
    if (projectLocations.length >= 2) {
      return null;
    } else {
      return (
        <>
          <AddressForm
            label={
              configServiceLocationRequired
                ? fieldLabels.location + " *"
                : fieldLabels.location
            }
            required={configServiceLocationRequired}
            onSiteChange={(e) => {
              setCustomerSite(e.target.value);
              setAddressChanged(true);
            }}
            siteName={customerSite}
            onAddressChange={(e) => {
              setAddress(e.target.value);
              setAddressChanged(true);
            }}
            remoteLocation={remoteSite}
            onRemoteChanged={(e) => {
              setRemoteSite(e.target.checked);
              setAddressChanged(true);
            }}
            addressText={address}
            onCityChange={(e) => {
              setCity(e.target.value);
              setAddressChanged(true);
            }}
            cityText={city}
            onStateChange={(e) => {
              setState(e.target.value);
              setAddressChanged(true);
            }}
            stateText={state}
            onZipChange={(e) => {
              setZip(e.target.value);
              setAddressChanged(true);
            }}
            zipText={zip}
            placeholder="Customer Site"
            street2Text={street2}
            onStreet2Change={(e) => {
              setStreet2(e.target.value);
              setAddressChanged(true);
            }}
            country={country}
            onCountryChange={(e) => {
              setCountry(e);
              setCountryId(e.id);
              setAddressChanged(true);
            }}
          />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <ProjectVariables
              overridePadding={true}
              arr={requiredLocationVariables}
              account_slug={account_slug}
              project_id={project_id}
              setState={setRequiredLocationVariables}
              setVariableChanged={setAddressChanged}
            />
            <ProjectVariables
              overridePadding={true}
              arr={unrequiredLocationVariables}
              account_slug={account_slug}
              project_id={project_id}
              setState={setUnrequiredLocationVariables}
              setVariableChanged={setAddressChanged}
            />
          </div>
        </>
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.persist();
    //Force select of CRM opportunity
    if (!newCrmId && crmFlag) {
      window.scrollTo(0, 0);
      setCrmOppInvalid(true);
      return;
    }

    if (configSalesExecutiveRequired && currentSalesExec.label == "") {
      setSalesExecInvalid(true);
      return;
    }

    setButtonsDisabled(true);

    // Project Variable Validation
    const allProjectVariables = requiredProjectVariables.concat(
      unrequiredProjectVariables
        .concat(requiredLocationVariables)
        .concat(unrequiredLocationVariables)
    );
    const projectVariablesAreValid = validateVariables(
      allProjectVariables,
      setMinValue,
      setMaxValue,
      setShowMinCharAlert,
      setShowMaxCharAlert
    );
    if (projectVariablesAreValid === false) {
      setButtonsDisabled(false);
      return;
    }

    // Location Validation
    const locationIsValid = validateLocation(
      addressChanged,
      customerSite,
      address,
      city,
      state,
      zip,
      remoteSite,
      setLocationErrors
    );
    if (locationIsValid === false) {
      window.scrollTo(0, 0);
      setShowLocationAlert(true);
      setButtonsDisabled(false);
      return;
    }

    const getNewProjectData = (clientData, salesExecData) => {
      let newProjectData = {
        data: {
          type: "projects",
          attributes: {
            "project-name": projectName,
            "msa-date": msaDate,
            "project-variables":
              getUpdatedProjectVariables(allProjectVariables),
          },
          relationships: {
            account: { data: { type: "accounts", id: account_id } },
            "sales-executive": salesExecData,
            client: clientData,
            "crm-opportunity": getUpdatedCrm(newCrmId),
            "payment-term": getUpdatedPaymentTerm(newPaymentTermId),
            "presales-engineer": getUpdatedPresalesEngineer(
              newPresalesEngineerId
            ),
            "business-unit": getUpdatedBusinessUnit(newBusinessUnitId),
            "rate-table": getUpdatedRateTable(newRateTableId),
          },
        },
      };
      if (!newBusinessUnitId) {
        delete newProjectData.data.relationships["business-unit"];
      }
      return newProjectData;
    };

    // Function to post the new project passing the data as the parameter
    const postProject = (data) => {
      setErrorMessage(null);
      API.Post(
        `${apiHost}/${account_slug}/v1/projects`,
        data,
        authorizationCode
      )
        .then((response) => {
          newProjectId = response.data.data.id;
          // Post a new location if the user typed one using the newProjectId after the project is created
          let newLocation = getNewLocation(newProjectId);
          if (
            addressChanged === true &&
            customerSite !== "" &&
            (remoteSite || (address !== "" && city !== "" && zip !== ""))
          ) {
            upsertProjectLocation(
              response.data.data.id,
              unrequiredLocationVariables,
              requiredLocationVariables,
              locationId,
              account_slug,
              authorizationCode,
              customerSite,
              address,
              street2,
              city,
              state,
              zip,
              remoteSite,
              countryId
            ).catch((err) => {
              setShowFailAlert(true);
              setSubmitFail(true);
              setButtonsDisabled(false);
            });
          }

          // Redirect according to which button what clicked
          if (scopeBlueprintButtonClicked) {
            window.location.replace(
              `${appHost}/projects/${newProjectId}/task_blueprints/new`
            );
          } else {
            window.location.replace(`${appHost}/projects/${newProjectId}/edit`);
          }
        })
        .catch((err) => {
          setErrorMessage(formatUnprocessibleResponse(err, "Project"));
          setShowFailAlert(true);
          setSubmitFail(true);
          setButtonsDisabled(false);
        });
    }; // End post project function

    const buildProjectPromises = () => {
      let projectPromises = [];

      if (clientName !== "") {
        projectPromises.push(
          upsertClientId(
            clientName,
            account_slug,
            authorizationCode,
            account_id
          )
        );
      }
      if (currentSalesExec.label !== "") {
        projectPromises.push(
          upsertSalesExecutiveId(
            currentSalesExec.label,
            account_slug,
            authorizationCode,
            account_id
          )
        );
      }

      return projectPromises;
    };

    // ------------Handle Submit for Creating a New Project----------------------
    let newProjectData = getNewProjectData();
    let projectPromises = buildProjectPromises();

    if (projectPromises.length !== 0) {
      Promise.all(projectPromises).then((values) => {
        values.forEach((res) => {
          if (res.type == "clients") {
            newProjectData.data.relationships["client"] = { data: res };
          }
          if (res.type == "sales-executives") {
            if (res.id) {
              newProjectData.data.relationships["sales-executive"] = {
                data: res,
              };
            } else {
              delete newProjectData.data.relationships["sales-executive"];
            }
          }
        });
        if (newProjectData.data.relationships["client"].data.id.error) {
          setErrorMessage(
            newProjectData.data.relationships["client"].data.id.error
          );
          setShowFailAlert(true);
          setSubmitFail(true);
          setButtonsDisabled(false);
        } else {
          postProject(newProjectData);
        }
      });
    } else {
      postProject(newProjectData);
    }
  }; // End Submit Function

  // If the project changes were saved successfully, close the other alerts if they exist
  if (
    showSuccessAlert === true &&
    (showMinCharAlert === true || showMaxCharAlert === true)
  ) {
    setShowMaxCharAlert(false);
    setShowMinCharAlert(false);
  }

  if (userExistsAlertIsOpen === true && showFailAlert === true) {
    setShowFailAlert(false);
  }

  // Return our component with appropriate data from the state
  return (
    <div className="newProjectDetailsPage">
      {submitFail === true
        ? handleFailAlert(showFailAlert, errorMessage, setShowFailAlert)
        : null}
      {handleCharacterAlert(
        showMinCharAlert,
        setShowMinCharAlert,
        showMaxCharAlert,
        setShowMaxCharAlert,
        ToastAlert,
        minValue,
        maxValue
      )}
      {handleLocationAlert(
        showLocationAlert,
        locationErrors,
        setShowLocationAlert
      )}
      {handleUserExistsAlert(userExistsAlertIsOpen, setUserExistsAlertIsOpen)}
      <Card>
        {renderHeader()}
        <Card.Body>
          {isLoading ? (
            <div style={{ marginTop: "20px" }}>
              <ScopeStackSpinner />
            </div>
          ) : (
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Row>
                <Col xs={crmId !== "" ? 10 : 12}>
                  {isCrm === true ? renderCrmOpportunity() : null}
                </Col>
                {crmId !== "" ? (
                  <Col>
                    <IconButton
                      className="removeButton pull-right"
                      dataMethod="delete"
                      url={`/crm_opportunities/${crmId}?account_slug=${account_slug}&amp;project_id=${project_id}`}
                      iconClass="fa fa-chain-broken"
                      buttonText="Remove"
                    />
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs={6}>
                  <FormTextField
                    required={true}
                    id="projectName"
                    onChange={(e) => {
                      setButtonsDisabled(false);
                      setProjectName(e.target.value);
                    }}
                    value={projectName}
                    label="Project Name *"
                  />
                  <Row
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col sm={6}>
                      <DropdownApiJs
                        label={
                          configSalesExecutiveRequired
                            ? fieldLabels["sales_executive"] + " *"
                            : fieldLabels["sales_executive"]
                        }
                        value={currentSalesExec}
                        required={configSalesExecutiveRequired}
                        isInvalid={
                          salesExecInvalid && configSalesExecutiveRequired
                        }
                        onChange={(e) => {
                          setSalesExecInvalid(false);
                          setCurrentSalesExec(e);
                          setNewSalesExecId(e.value);
                        }}
                        url={`${apiHost}/${account_slug}/v1/sales-executives`}
                        isDisabled={false}
                        filter="name"
                        blankOption={null}
                        canCreate={salesExecutiveCreatePermission}
                      />
                    </Col>
                    <Col sm={6}>
                      <DropDownField
                        label={
                          configPresalesEngineerRequired
                            ? fieldLabels["presales_engineer"] + " *"
                            : fieldLabels["presales_engineer"]
                        }
                        required={configPresalesEngineerRequired}
                        value={currentPresalesEngineer}
                        onChange={(e) => {
                          setButtonsDisabled(false);
                          let selected = e.target.options.selectedIndex;
                          setNewPresalesEngineerId(
                            e.target.options[selected].accessKey
                          );
                        }}
                        options={presales.map((presale) => {
                          return (
                            <option
                              className="presalesOptions"
                              key={presale.id}
                              accessKey={presale.id}
                            >
                              {presale.attributes.name}
                            </option>
                          );
                        })}
                      />
                    </Col>
                    <Col sm={6}>
                      <DropDownField
                        required={true}
                        value={currentPaymentTerm}
                        onChange={(e) => {
                          setButtonsDisabled(false);
                          let selected = e.target.options.selectedIndex;
                          setNewPaymentTermId(
                            e.target.options[selected].accessKey
                          );
                        }}
                        options={paymentTerms.map((term) => {
                          return (
                            <option
                              className="paymentTermOptions"
                              key={term.id}
                              accessKey={term.id}
                            >
                              {term.attributes.name}
                            </option>
                          );
                        })}
                        label="Payment Term *"
                      />
                    </Col>
                    {rateTables.length > 1 ? (
                      <Col sm={6}>
                        <DropDownField
                          label="Rate Table *"
                          value={currentRateTable}
                          onChange={(e) => {
                            setButtonsDisabled(false);
                            let selected = e.target.options.selectedIndex;
                            setNewRateTableId(
                              e.target.options[selected].accessKey
                            );
                          }}
                          options={rateTables.map((rateTable) => (
                            <option key={rateTable.id} accessKey={rateTable.id}>
                              {rateTable.attributes.name}
                            </option>
                          ))}
                        />
                      </Col>
                    ) : null}
                    {businessUnits.length !== 0 ? (
                      <Col sm={6}>
                        <ControlledDropDown
                          label={
                            configBusinessUnitRequired
                              ? fieldLabels["business_unit"] + " *"
                              : fieldLabels["business_unit"]
                          }
                          value={currentBusinessUnit}
                          required={configBusinessUnitRequired}
                          onChange={(e) => {
                            setButtonsDisabled(false);
                            setCurrentBusinessUnit(e.target.value);
                            let selected = e.target.options.selectedIndex;
                            setNewBusinessUnitId(
                              e.target.options[selected].accessKey
                            );
                          }}
                          options={[
                            <option
                              value={null}
                              key={null}
                              accessKey={null}
                            ></option>,
                            businessUnits.map((businessUnit) => {
                              return (
                                <option
                                  className="businessUnitOptions"
                                  key={businessUnit.id}
                                  accessKey={businessUnit.id}
                                >
                                  {businessUnit.attributes.name}
                                </option>
                              );
                            }),
                          ]}
                        />
                      </Col>
                    ) : null}
                    {!configMsaDateHidden && (
                      <Col sm={6}>
                        <FormDateField
                          label="MSA Date"
                          value={msaDate}
                          onChange={(e) => {
                            setMsaDate(e.target.value);
                          }}
                        />
                      </Col>
                    )}
                    <ProjectVariables
                      arr={requiredProjectVariables}
                      account_slug={account_slug}
                      project_id={project_id}
                      setState={setRequiredProjectVariables}
                      setVariableChanged={null}
                    />
                    <ProjectVariables
                      arr={unrequiredProjectVariables}
                      account_slug={account_slug}
                      project_id={project_id}
                      setState={setUnrequiredProjectVariables}
                      setVariableChanged={null}
                    />
                  </Row>
                </Col>
                <Col xs={6}>
                  <AutoCompleteText
                    required={true}
                    id="clientName"
                    label={fieldLabels.client + " *"}
                    suggestionList={clientList}
                    value={clientName}
                    monitorValue={(value) => {
                      chosenClient = value;
                      filterClientList(value).then((res) => {
                        const data = res.data.data;
                        const clients = data.map((client) => ({
                          id: client.id,
                          client: client.attributes.name,
                          msa_date: client.attributes["msa-date"],
                          relationships: client.relationships,
                        }));
                        setClients(clients);
                        const clientNames = data.map(
                          (client) => client.attributes.name
                        );
                        setClientList(clientNames);

                        let clientExists = "";
                        for (let i = 0; i < data.length; i++) {
                          if (data[i].attributes.name === clientName)
                            clientExists = data[i].id;
                        }
                      });
                      for (let i = 0; i < clients.length; i++) {
                        if (clients[i].client === chosenClient) {
                          setMsaDate(clients[i].msa_date);
                          setNewClientId(clients[i].id);
                          const rateTableData =
                            clients[i].relationships["rate-table"].data;
                          if (rateTableData !== null) {
                            for (let j = 0; j < rateTables.length; j++) {
                              if (rateTables[j].id === rateTableData.id) {
                                setCurrentRateTable(
                                  rateTables[j].attributes.name
                                );
                                setNewRateTableId(rateTables[j].id);
                              }
                            }
                          }
                        }
                      }
                      setClientName(chosenClient);
                    }}
                    setTextChanged={(bool) => {
                      setUserTypedClient(bool);
                    }}
                    crmId={newCrmId}
                  />
                  {renderLocation()}
                  <FormTextField
                    id="tag-list"
                    value={tags}
                    onChange={(e) => {
                      setButtonsDisabled(false);
                      setTags(e.target.value);
                      setShowFailAlert(false);
                      setSubmitFail(false);
                    }}
                    label="Tags"
                  />
                  <Form.Text muted id="tag-list-info">
                    Comma-separated list of tags for the project
                  </Form.Text>
                </Col>
              </Row>
              <Row>
                {renderButtons()}
                <Col>
                  <BackButton url={`/projects`} />
                </Col>
              </Row>
            </Form>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default NewProject;
