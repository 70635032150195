import { Questionnaire, Question } from "../../../pages/ProjectSurveysV2/types";
import { Form, Col } from "react-bootstrap";
import buildInputElement from "./buildInputElement";

interface FormProps {
  questionnaire: Questionnaire;
  questions: Question[];
  setQuestions: (questions: Question[]) => void;
}

const QuestionnaireForm = ({
  questionnaire,
  questions,
  setQuestions,
}: FormProps) => {
  return (
    <>
      {questionnaire?.introduction && (
        <div className="aboutSurvey">
          <h5>About this survey</h5>
          <span>{questionnaire.introduction}</span>
        </div>
      )}
      <h6>Survey Details</h6>
      {questions?.length ? (
        <Form>
          <div className="questionsContainer">
            {(questions || [])?.map((q, i) => (
              <Col
                key={String(q?.["question-id"])}
                xs={6}
                className="surveyQuestion"
              >
                {buildInputElement(q, questions, setQuestions)}
              </Col>
            ))}
          </div>
        </Form>
      ) : (
        "There are no questions on this survey. Please configure in settings"
      )}
    </>
  );
};

export default QuestionnaireForm;
