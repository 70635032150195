import React, { ReactNode, useEffect, useState } from "react";
import Select, { Props as SelectProps } from "react-select";
import { Form } from "react-bootstrap";
import API from "../../../utils/API/API";
import useOauth from "@utils/customHooks/useOauth";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
const errorMessage = require("../../../images/error-message-white.png");

const DropdownApiJs = ({
  value,
  setValue,
  onChange,
  onInputChange,
  label,
  className,
  width = "100%",
  placeholder,
  required,
  defaultValue,
  isInvalid,
  onFocus,
  isDisabled = false,
  url,
  filter,
  blankOption,
  canCreate,
  initialPageSize = 500,
  setDataState,
}) => {
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState([]);
  const [authorizationCode] = useOauth();
  const [isLoading, setIsLoading] = useState(true);
  const [selectionMade, setSelectionMade] = useState(
    (defaultValue && defaultValue !== "") || (value && value !== "")
  );

  useEffect(() => {
    if (authorizationCode !== "") {
      const urlFilter =
        blankOption &&
        blankOption.label !== searchText &&
        searchText !== "" &&
        !options.find((option) => option.label == searchText)
          ? `?filter[${filter}]=${encodeURIComponent(
              searchText
            )}&page[size]=100`
          : `?filter[${filter}]=${encodeURIComponent(
              ""
            )}&page[size]=${initialPageSize}`;
      API.Get(url + urlFilter, authorizationCode).then((res) => {
        const data = res.data.data;
        setIsLoading(false);
        if (data) {
          if (setDataState) {
            setDataState(data);
          }
          const optionsToRender =
            searchText !== "" && canCreate
              ? [
                  { label: `${searchText} (ENTER to Add)`, value: "" },
                  ...data.map((item) => {
                    return {
                      label: item.attributes[filter],
                      value: item.id,
                    };
                  }),
                ]
              : [
                  ...data.map((item) => {
                    return {
                      label: item.attributes[filter],
                      value: item.id,
                    };
                  }),
                ];
          const optionsToDisplay =
            blankOption !== null
              ? [blankOption, ...optionsToRender]
              : optionsToRender;
          setOptions(optionsToDisplay);
        }
      });
    }
  }, [searchText, authorizationCode]);

  const onInputChangeFunction = (e) => {
    setSelectionMade(false);
    if (e?.length > 3) {
      setSearchText(e);
    }
    if (
      e !== "" &&
      !options.find(
        (option) =>
          option.label == e &&
          e.label !== "" &&
          e.value &&
          e.value !== blankOption.value
      ) &&
      canCreate
    ) {
      setOptions([{ label: `${e} (ENTER to Add)`, value: "" }, ...options]);
    }
    if (onInputChange) {
      onInputChange(e, {
        action: "set-value",
        prevInputValue: "",
      });
    }
  };

  if (isLoading) {
    return <SmallSpinner />;
  }

  const handleChange = (e) => {
    setSelectionMade(true);
    if (e?.label?.includes("(ENTER to Add)")) {
      const newLabel = e.label.split("(")[0].trim();
      e = { label: newLabel, value: "" };
    }
    onChange(e);
  };

  const handleOnBlur = (e) => {
    if (canCreate && !selectionMade) {
      setSearchText("");
      handleChange("");
    }
  };

  return (
    <Form.Group
      className="formGroup"
      style={isInvalid ? { marginBottom: "0px" } : {}}
    >
      <Form.Label>{label}</Form.Label>
      <Select
        className={className}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        onInputChange={(e) => onInputChangeFunction(e)}
        options={options}
        isMulti={false}
        required={required}
        onFocus={onFocus}
        isDisabled={isDisabled}
        onKeyDown={(e) => {
          if (e.key === "Tab") {
            handleOnBlur(e);
          }
        }}
        onBlur={handleOnBlur}
      />
      {required && isInvalid ? (
        <div>
          <img
            src={errorMessage}
            alt="Please fill out this field error message"
          />
        </div>
      ) : null}
    </Form.Group>
  );
};

export default DropdownApiJs;
