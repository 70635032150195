import React from "react";
import ProjectOverview from "../ProjectOverview/ProjectOverview";

function EditProject({
  account_id,
  account_slug,
  salesExecutiveCreatePermission,
  projectOverviewPrivilege,
}) {
  const location = window.location.href;
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  return (
    <ProjectOverview
      project_id={id}
      account_id={account_id}
      account_slug={account_slug}
      salesExecutiveCreatePermission={salesExecutiveCreatePermission}
      projectOverviewPrivilege={projectOverviewPrivilege}
    />
  );
}

export default EditProject;
