import { Routes, Route, Outlet } from "react-router-dom";
import Show from "./Show";
import New from "./New";

function ProjectSurveysMspa(props) {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route index element={<Show {...props} />} />
      </Route>
      <Route path="/new" element={<Outlet />}>
        <Route index element={<New {...props} />} />
      </Route>
    </Routes>
  );
}

export default ProjectSurveysMspa;
