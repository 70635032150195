import { useGetSurveyByIdQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { Survey } from "../types";

const useGetSurvey = ({ id }: { id: number }) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  const { data, isLoading, isFetching, refetch } = useGetSurveyByIdQuery({
    slug: accountSlug,
    id,
    include: ["survey-responses", "survey-recommendations"],
  });

  let survey: Survey | null = null;

  if (data?.data) {
    survey = {
      id: String(id),
      name: data.data?.attributes.name || "",
      responses: data.data?.attributes?.responses || [],
      recommendations: data.data?.attributes?.recommendations || [],
      calculations: data.data?.attributes?.calculations || [],
    };
  }
  return {
    survey,
    surveyLoading: isLoading,
    surveyFetching: isFetching,
    surveyRefetch: refetch,
  };
};

export default useGetSurvey;
