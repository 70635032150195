import { useRef, useEffect, ChangeEvent } from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";

interface DateFieldProps {
  label: string | null;
  placeholder?: string;
  value?: string | null;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  required?: boolean;
  updateRef?: boolean;
  id?: string;
  onBlur?: (e?: any) => void;
  disabled?: boolean;
}

const DateField: React.FC<DateFieldProps> = ({
  label,
  placeholder,
  value,
  onChange,
  defaultValue,
  required,
  updateRef,
  id = `dateFieldInput-${Math.random().toString(36).substr(2, 9)}`,
  onBlur,
  disabled
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const isControlled = value !== null && value !== undefined;

  useEffect(() => {
    if (updateRef && inputRef.current) {
      inputRef.current.value = defaultValue || "";
    }
  }, [defaultValue, updateRef]);

  return (
    <FormGroup className="formGroup">
      {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
      <FormControl
        ref={inputRef}
        id={id}
        required={required}
        defaultValue={!isControlled ? defaultValue : undefined}
        value={isControlled ? value : undefined}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        type="date"
        disabled={disabled}
      />
    </FormGroup>
  );
};

export default DateField;
