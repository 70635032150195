import { Survey } from "../../../pages/ProjectSurveysV2/types";
import { Form } from "react-bootstrap";
import AccordianDrop from "@components/AccordianDrop/AccordianDrop";
import { getOrganizedPhasesFromListOfServices } from "@utils/helpers";
import { Service } from "../../../pages/Projects/ServicesV2/types";
import { ServicesTable } from "../../../pages/Services/ServicesTable/ServicesTable";
import { ColumnDef } from "@tanstack/react-table";
import { snakeToText } from "@utils/helperFunctions";
import DataTable from "@components/DataTable/DataTable";

interface SurveyRecommendationsProps {
  data: {
    recommendations: Survey["recommendations"];
    calculations: Survey["calculations"];
    responses: Survey["responses"];
  };
  columns: ColumnDef<Service>[];
}

const SurveyRecommendations = ({
  data,
  columns,
}: SurveyRecommendationsProps) => {
  const phases = getOrganizedPhasesFromListOfServices(
    data.recommendations as Service[]
  );

  const buildRecommendationsTable = () => {
    return phases.map((p, i) => (
      <div key={i} className="phaseContainer">
        <div className="phaseHeader">{p?.name || ""}</div>
        <Form>
          <ServicesTable
            servicesPassed={
              data?.recommendations?.filter((s) => s?.phase?.id === p?.id) || [
                { id: "0", name: "No services found" },
              ]
            }
            onUpdateServices={(services) => null}
            serviceColumns={
              p?.name !== "Managed Services"
                ? columns
                : columns.filter((column) => column.id !== "resource")
            }
            subserviceColumns={
              p?.name !== "Managed Services"
                ? columns
                : columns.filter((column) => column.id !== "resource")
            }
            areSurveyRecommendations
          />
        </Form>
      </div>
    ));
  };
  return (
    <div>
      <AccordianDrop
        header={
          <div className="accordionHeaderContent" style={{ width: "80vw" }}>
            <div className="accordianTitle">Recommended Services</div>
            <div className="accordianSubtitle">
              Expand to view services that will be applied to project
            </div>
          </div>
        }
        body={buildRecommendationsTable()}
        className=""
        closeable={true}
        setAccordianIsOpen={() => null}
      />
      <AccordianDrop
        header={
          <div className="accordionHeaderContent" style={{ width: "80vw" }}>
            <div className="accordianTitle">Calculations</div>
            <div className="accordianSubtitle">
              Expand to view calculations that resulted in the recommendations
            </div>
          </div>
        }
        body={
          <DataTable
            data={
              data?.calculations?.length
                ? data.calculations.map((c, i) => {
                    return { ...c, id: i + 1 };
                  })
                : [
                    {
                      id: 0,
                      calculation_id: "No calculations",
                      value: "",
                    },
                  ]
            }
            columns={[
              {
                header: "Calculation Name",
                cell(props) {
                  const id = props.row.original.id;
                  const name = props.row.original?.calculation_id;
                  return id === 0
                    ? name
                    : snakeToText(props.row.original?.calculation_id || "");
                },
              },
              {
                header: "Calculated Value",
                cell(props) {
                  return props.row.original?.value || "";
                },
              },
            ]}
            striped
          />
        }
        className=""
        setAccordianIsOpen={() => null}
      />
      <AccordianDrop
        header={
          <div className="accordionHeaderContent" style={{ width: "80vw" }}>
            <div className="accordianTitle">Responses</div>
            <div className="accordianSubtitle">
              Expand to view responses that were submitted for the survey
            </div>
          </div>
        }
        body={
          <DataTable
            data={
              data?.responses?.length
                ? data.responses.map((q, i) => {
                    return { ...q, id: i + 1 };
                  })
                : [{ id: 0, question: "No responses", answer: "" }]
            }
            columns={[
              {
                header: "Question",
                cell(props) {
                  return props.row.original?.question || "";
                },
              },
              {
                header: "Response",
                cell(props) {
                  return props.row.original?.answer || "";
                },
              },
            ]}
            striped
          />
        }
        className=""
        setAccordianIsOpen={() => null}
      />
    </div>
  );
};

export default SurveyRecommendations;
