import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { useListProjectResourcesForAccountQuery } from "@generated";
import { ProjectResource } from "../types/projectResource";

interface ResourceQueryProps {
  useResourceRates?: boolean;
  project_id: string;
}

const useListProjectResourcesQuery = ({
  useResourceRates,
  project_id,
}: ResourceQueryProps) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null;

  const { data, error, isLoading, refetch, isFetching } =
    useListProjectResourcesForAccountQuery({
      slug: accountSlug,
      filter: {
        active: true,
        project: project_id,
      },
      include: [
        "resource",
        "project",
        "line-of-business",
        //@ts-ignore
        "project.resource-rates",
      ],
    });

  let apiProjectResources: ProjectResource[] = [];

  const lobs = data?.included?.filter(
    (item) => item.type === "line-of-businesses"
  );
  const resourceRates = data?.included?.filter(
    (item) => item.type === "resource-rates"
  );
  const resources = data?.included?.filter((item) => item.type === "resources");

  if (data?.data) {
    try {
      apiProjectResources = data?.data
        ?.map((projectResource) => {
          const attr = projectResource?.attributes;
          if (!projectResource.id) {
            throw new Error("Missing team.id");
          }
          if (!attr?.["name"]) {
            throw new Error("Missing team.attributes.name");
          }
          const foundLob = lobs?.find(
            (item) =>
              item.id ==
              projectResource.relationships?.["line-of-business"]?.data?.id
          );

          const foundResourceId = resources?.find(
            (res) =>
              res.id === projectResource?.relationships?.resource?.data?.id
          )?.id;

          return {
            id: projectResource?.id?.toString(),
            name: attr?.["name"] || "",
            externalName: attr?.["external-name"] || "",
            description: attr?.description || "",
            hourlyRate: attr?.["hourly-rate"] || 0,
            hourlyCost: attr?.["hourly-cost"] || 0,
            lob: {
              label: foundLob?.attributes?.["name"] || "",
              value: foundLob?.id || 0,
            },
            travelAndExpenseRate: attr?.["expense-rate"] || 0,
            resourceId: foundResourceId?.toString() || "0",
          };
        })
        .sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          if (a["external-name"] > b["external-name"]) return 1;
          if (a["external-name"] < b["external-name"]) return -1;
          return 0;
        });
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    apiProjectResources,
    resources,
    resourceRates,
    isLoading,
    error: error ?? customError,
    refetch,
    isFetching,
  };
};
export default useListProjectResourcesQuery;
