import React from "react";
import { Form } from "react-bootstrap";
import FormFieldLabel from "./FormFieldLabel/FormFieldLabel";

function DropDownField({
  label,
  value,
  options,
  onChange,
  required,
  disabled = false,
}) {
  return (
    <Form.Group className="formGroup" controlId="formGridState">
      {label && <FormFieldLabel label={label} />}
      <Form.Control
        required={required}
        as="select"
        aria-label="Default select example"
        onChange={onChange}
        disabled={disabled}
      >
        <option value={value}>{value === null ? "" : value}</option>
        {options.filter((option) => value !== option.props.children)}
      </Form.Control>
    </Form.Group>
  );
}

export default DropDownField;
